import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid2'

import { ProductSegmentCard } from '../cards/ProductSegmentCard';
import { AreaChart } from '../charts/AreaChart';
import {
  transformTallyData,
  transformProductSegmentData,
  removeCardType,
} from '../dash-util';
import { ProductCard } from '../cards/ProductCard';

const cardColors = ['#4caf50', '#ed6c02', '#1976d2']

export function OperationsView({ cardsData, productData, periodFilter, setPeriodFilter }) {

  // console.log('OperationsView: ', productData)
  const orderedCards = removeCardType(cardsData, 'Total OPEX')
  ?.sort((a, b) => a.value - b.value);

  useEffect(() => {

  }, [cardsData, productData, periodFilter]);

  return (
    <Grid container spacing={3}>
    <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Total OPEX'>
      <ProductCard
        sx={{ height: '100%' }}
        data={transformTallyData(cardsData, 'Total OPEX', '#7b1fa2')}
      />
    </Grid>

      {orderedCards?.map((d, index) => (
          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key={d.type}>
            <ProductCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, d?.type, cardColors[index])}
            />
          </Grid>
      ))}

    <Grid size={{ xs: 8 }} key="stakked-product-segment">
      <AreaChart
        periodFilter={periodFilter}
        setPeriodFilter={setPeriodFilter}
      />
    </Grid>

    <Grid size={{ xs: 4 }} key="product segment">
      <ProductSegmentCard
        products={transformProductSegmentData(productData)}
        periodFilter={periodFilter}
        setPeriodFilter={setPeriodFilter}
        sx={{ height: '100%' }}
      />
    </Grid>
  </Grid>
  )
}

OperationsView.propTypes = {
  cardData: PropTypes.arrayOf(PropTypes.object).isRequired,
}
