import * as React from 'react';
import { useEffect } from 'react';

import { 
  Card,
  CardContent,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material"
import ReactApexChart from 'react-apexcharts';
import CustomTextField from '../../../components/custom/CustomTextField';
import { PRODUCT_SEGMENTATION_FILTERS } from '../../../utils/constants';

export function AreaChart({ title, data, periodFilter, setPeriodFilter, sx }) {

  const series = [{
    name: 'Revenue',
    data: data || [
      {
        x: 'Dec 29 2017',
        y: 55
      },
      {
        x: 'Dec 30 2017',
        y: 51
      },
      {
        x: 'Dec 31 2017',
        y: 67
      },
      {
        x: 'Jan 01 2018',
        y: 22
      },
      {
        x: 'Jan 02 2018',
        y: 34
      },
      {
        x: 'Jan 03 2018',
        y: 25
      },
      {
        x: 'Jan 04 2018',
        y: 8
      },
      {
        x: 'Jan 10 2018',
        y: 34
      },
      {
        x: 'Jan 11 2018',
        y: 21
      },
      {
        x: 'Jan 12 2018',
        y: 10
      },
      {
        x: 'Jan 13 2018',
        y: 13
      },
      {
        x: 'Jan 14 2018',
        y: 20
      }
    ],
  }];
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false
      },
      zoom: {
        enabled: false
      },
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 5,
      hover: {
        size: 9
      }
    },
    title: {
      text: '',
    },
    // tooltip: {
    //   intersect: true,
    //   shared: false
    // },
    theme: {
      palette: 'palette1',
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      title: {
        text: ''
      }
    }
  };

  useEffect(() => {

  }, [data, periodFilter]);

  return (
    <Card sx={{ height: '100%', ...sx }}>
    
    <Stack
          direction="row"
          sx={{
            alignItems: "flex-start",
            justifyContent: "space-between",
            m: 2
          }}
          spacing={1}
        >
          <Typography variant="h5">
            {title || "Rolling Annual Revenue (Loss/Gain)"}
          </Typography>

          <CustomTextField
            variant="standard"
            sx={{ minWidth: 120 }}
            select
            labelId="period-select-label"
            value={periodFilter}
            onChange={(e) => { setPeriodFilter(e.target.value) }}
          >
            <MenuItem value="" selected>
              <em>Select Period</em>
            </MenuItem>
            {PRODUCT_SEGMENTATION_FILTERS.map((p) => (
              <MenuItem key={p.key} value={p.key}>
                {p.value}
              </MenuItem>
            ))}
          </CustomTextField>
        </Stack>

      <CardContent>
        <ReactApexChart options={options} series={series} type="area" height={300} />
      </CardContent>
    </Card>
  );

}