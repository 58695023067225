import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  MenuItem,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import API from '../../../data/api';
import { CustomSelect } from '../../custom/CustomSelect';
import CustomModal from '../../custom/CustomModal';
import CustomTextField from '../../custom/CustomTextField';
import CustomSnackBar from '../../custom/CustomSnackBar';

import {
  extractFormValues,
  initialiseForm,
  isNullValue,
  validateForm
} from '../../../utils/formUtil';

const initFormState = {
  companyName: { value: '', required: true, helper: 'company name' },
  streetAddress: { value: '', required: true, helper: 'street address' },
  suburb: { value: '', required: false, },
  city: { value: '', required: false, },
  postalCode: { value: '', required: true, helper: 'postal code' },
  country: { value: '', required: true, },
};

const countries = [
  "Botswana",
  "Namibia",
  "Mozambique",
  "South Africa",
  "Zimbabwe",
];

const TransportCompany = ({
  openModal,
  handleCloseModal,
  handleSubmitCallback
}) => {

  const initialFormData = initialiseForm(initFormState);

  const [formData, setFormData] = useState(initialFormData);
  const [submitted, setSubmitted] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: "",
    type: "",
  });

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);

    if (!validateForm(setFormData, formData)) {
      console.log("oops, failed validation", formData)
      setSubmitted(false);
      return;
    }

    try {
      // Send the POST request with JSON body
      const { companyName, streetAddress, suburb, city, postalCode, country } = extractFormValues(formData);
/*
      let endpoint = `inventory/backoffice/new_company?company_name=${companyName}`
      .concat(`&street_address=${streetAddress}`)
      .concat(`&country=${country}`)
      .concat(`&postal_code=${postalCode}`)
      .concat(`&is_logistics=true`)
      .concat(`${city ? '&city=' + city : ''}`)
      .concat(`${suburb ? '&suburb=' + suburb : ''}`);

      const response = await API.post(endpoint, '');
*/

      const response = await API.post(`inventory/backoffice/new_company`, {
        company_name: companyName,
        street_address: streetAddress,
        suburb: suburb || null,
        city: city || null,
        postal_code: postalCode,
        country: country,
        is_logistics: true,
      });

      if (response.status === 200 || response.status === 201) {
        // Reset the form if response is successful
        setFormData(initialFormData);
        setSnackbarMessage({
          message: `Successfully added ${companyName} as new logistics company`,
          type: "success",
        });
        handleSubmitCallback();
      } else {
        setSnackbarMessage({ message: response.message, type: "error" });
      }
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage({
        message: "An unexpected error occurred. Please try again later.",
        type: "error",
      });
    }
    setSubmitted(false);
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    handleCloseModal();
  }

  const handleFormDataChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        value: e.target.value,
        error: formData[e.target.name]?.required && isNullValue(e.target.value),
      },
    });
  };

  return (
    <>
      <CustomModal
        openModal={openModal}
        handleCloseModal={handleCancel}
        modalTitle={`Add Transport Company`}
        modalIcon='transport'
      >
        <form onSubmit={handleSubmit} noValidate>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <CustomTextField
              name="companyName"
              label="Company Name"
              value={formData.companyName.value}
              onChange={handleFormDataChange}
              required={formData.companyName.required}
              error={formData.companyName.error}
              helperText={formData.companyName.error && formData.companyName.errorMessage}
            />

            <CustomTextField
              name="streetAddress"
              label="Street Address"
              value={formData.streetAddress.value}
              onChange={handleFormDataChange}
              required={formData.streetAddress.required}
              error={formData.streetAddress.error}
              helperText={formData.streetAddress.error && formData.streetAddress.errorMessage}
            />

            <CustomTextField
              name="suburb"
              label="Suburb"
              value={formData.suburb.value}
              onChange={handleFormDataChange}
              required={formData.suburb.required}
              error={formData.suburb.error}
              helperText={formData.suburb.error && formData.suburb.errorMessage}
            />

            <CustomTextField
              name="city"
              label="city"
              value={formData.city.value}
              onChange={handleFormDataChange}
              required={formData.city.required}
              error={formData.city.error}
              helperText={formData.city.error && formData.city.errorMessage}
            />

            <CustomTextField
              name="postalCode"
              label="Postal Code"
              value={formData.postalCode.value}
              onChange={handleFormDataChange}
              required={formData.postalCode.required}
              error={formData.postalCode.error}
              helperText={formData.postalCode.error && formData.postalCode.errorMessage}
            />

            <CustomSelect
              name="country"
              label="Country"
              value={formData.country.value}
              onChange={handleFormDataChange}
              required={formData.country.required}
              error={formData.country.error}
              helperText={formData.country.error && formData.country.errorMessage}
            >
              <MenuItem value=" " disabled>
                <em>Select Country</em>
              </MenuItem>
              {countries?.map(c => (
                <MenuItem value={c}>{c}</MenuItem>
              ))}
            </CustomSelect>

            <Box sx={{ alignItems: 'center', mt: 3, }}>
              <Button color="inherit"
                variant='contained'
                sx={{ width: '47%' }}
                onClick={() => { handleCancel() }}
                disabled={submitted}
              >
                Cancel
              </Button>
              <LoadingButton
                variant='contained'
                sx={{ ml: 2, width: '47%' }}
                type="submit"
                loading={submitted}
              >
                Submit
              </LoadingButton>
            </Box>
          </Stack>
        </form>
      </CustomModal>
      <CustomSnackBar
        snackbarMessage={snackbarMessage}
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={() => setSnackbarOpen(false)}
      />
    </>
  );
}

TransportCompany.propTypes = {

  /** Flag to toggling openning of modal */
  openModal: PropTypes.bool.isRequired,

  /** Callback function when closing modal */
  handleCloseModal: PropTypes.func.isRequired,

  /** Callback function after successful submission */
  handleSubmitCallback: PropTypes.func.isRequired,

}

export default TransportCompany
