// src/nav/config.js

import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'Profile',
  //   path: '/dashboard/user',
  //   icon: icon('ic_profile'),
  // },
  {
    title: 'Logistics',
    path: '/dashboard/logistics',
    icon: icon('ic_shopping'),
  },
  {
    title: 'Finance',
    path: '/dashboard/finances',
    icon: icon('ic_wallet'),
  },
  // {
  //   type: 'separator',
  // },
  {
    title: 'Reports',
    path: '/dashboard/reports',
    icon: icon('ic_article'),
  },
  {
    title: 'Settings',
    path: '/dashboard/settings',
    icon: icon('ic_settings_alt'),
  },
  {
    title: 'Cards',
    path: '/dashboard/cards',
    icon: icon('ic_analytics'),
  },
];

export default navConfig;


