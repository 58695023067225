/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { LoadingButton } from '@mui/lab'
import { Box, Button, FormControl, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'


import API from '../../../data/api';
import FileDragzone from '../../ui/FileDragzone'
import CustomModal from '../../custom/CustomModal'
import CustomTextField from '../../custom/CustomTextField'
import { filePreviewStyle, MAX_FILE_SIZE_MB } from '../../../utils/constants';
import { handlePreviewIcon } from '../../../utils/fileUtils';
import CustomPdfViewer from 'src/components/custom/CustomPdfViewer'

const onlyPdfType = {
  'application/pdf': ['.pdf'],
}

const imagePdfTypes = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png',],
  ...onlyPdfType,
}
const FinanceDocumentUpload = ({ handleCloseModal, processedOrder = {}, }) => {
  const { openModal, isTaxInvoice, orderItem } = processedOrder;
  const acceptedTypes = isTaxInvoice ? { ...onlyPdfType } : { ...imagePdfTypes };

  const [submitted, setSubmitted] = useState(false);
  const [modalTitle, setModalTitle] = useState("Upload Tax Invoice");
  const [modalStyle, setModalStyle] = useState({});
  const [isServerFileRead, setIsServerFileRead] = useState(false);
  const [fileObjects, setFileObjects] = useState([]);
  const [isFilePreview, setIsFilePreview] = useState(false);
  const [filePreviewUrl, setFilePreviewUrl] = useState(null);
  const [isPdfFile, setisPdfFile] = useState(false);
  const [documentDetails, setDocumentDetails] = useState(null);
  const [updateStats, setUpdateStats] = useState(false);
  const [payload, setPayload] = useState(null);

  useEffect(() => {
    if (isServerFileRead) {
      setModalTitle("Confirm Invoice Details");
      updatePayload();
    } else {
      setModalTitle(isTaxInvoice ? "Upload Tax Invoice" : "Upload Delivery Note");
    }

  }, [modalStyle, isServerFileRead, updateStats, processedOrder]);

  const handleDocumentPreview = async () => {

    if (fileObjects.length === 0 || fileObjects[0] === null) {
      setSubmitted(false);
      return;
    }
    setModalStyle(filePreviewStyle);
    setIsFilePreview(true);
  };

  const handleDocumentRead = async () => {

    if (fileObjects.length === 0 || fileObjects[0] === null) {
      setSubmitted(false);
      return;
    }

    setSubmitted(true);
    const formData = new FormData();
    formData.append('file', fileObjects[0]);
    try {
      const res = await API.post(`accounting/read_document`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (res.status === 200 || res.status === 201) {
        setDocumentDetails(res.data);
        setIsServerFileRead(true);
      } else {
        console.log("Error:", res.data);
      }

    } catch (error) {
      console.error('Error uploading file:', error);
    }
    setSubmitted(false);
  };

  const handleDocumentSubmit = async () => {

    console.log(fileObjects, orderItem);
    if (fileObjects.length === 0 || fileObjects[0] === null || !orderItem?.company_id) {
      setSubmitted(false);
      return;
    }

    let endPoint = '/accounting/upload_documents?service_provider=minio'
      .concat(`&suborder_id=${orderItem?.order_item_id}`)
      .concat(`&tax_invoice=${isTaxInvoice}`);
    if (isTaxInvoice) {
      endPoint = endPoint.concat(`&client_id=${orderItem?.company_id}`)
        .concat(`&product_id=${payload?.product_id}`)
        .concat(`&document_number=${payload?.document_number}`)
        .concat(`&volume_litres=${payload?.volume_litres}`)
        .concat(`&price_per_litre=${payload?.price_per_litre}`)
        .concat(`&total_price=${payload?.total_price}`)
        .concat(`&created_at=${payload?.created_at}`);
    }

    console.log(endPoint);


    const formData = new FormData();
    formData.append('file', fileObjects[0]);

    setSubmitted(true);
    try {
      const res = await API.post(`${endPoint}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (res.status === 200 || res.status === 201) {
        setUpdateStats(true);
        triggerModalClose(true);
      } else {
        console.log("Error:", res.data);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const updatePayload = () => {
    const data = {
      client_id: orderItem?.company_id,
      product_id: documentDetails?.product_id,
      document_number: documentDetails?.document_number,
      volume_litres: documentDetails?.volume_litres,
      price_per_litre: documentDetails?.price_per_litre,
      total_price: documentDetails?.total_price,
      created_at: documentDetails?.created_at,
    }
    setPayload(data);
  }

  const triggerModalClose = (newStates = updateStats) => {
    fileObjects[0] = null;
    setFilePreviewUrl(null);
    setFileObjects([]);
    setIsFilePreview(false);
    setModalStyle({ });
    setSubmitted(false);
    setDocumentDetails(null);
    setIsServerFileRead(false);
    setPayload(null);
    setisPdfFile(false);
    handleCloseModal({ isOpen: false, updateStats: newStates });
  }

  const handleFileObjects = (files) => {
    const file = (files.length > 0) ? files[0] : null;
    console.log(file)
    setFileObjects(files);
    setFilePreviewUrl(file ? URL.createObjectURL(file) : null);
    setisPdfFile(file?.type === 'application/pdf')
  };

  const handleValueChange = (event) => {

  };

  return (
    <CustomModal
      openModal={openModal}
      handleCloseModal={triggerModalClose}
      modalTitle={modalTitle}
      modalStyle={modalStyle}
      modalIcon='document'
    >
      {(isServerFileRead && isTaxInvoice) && (
        <>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid size={{ xs: 3 }} key='customer'>
              <Typography variant="inline" sx={{ fontWeight: 'bold' }}>
                Customer Details
              </Typography>
            </Grid>
            <Grid size={{ xs: 9 }} key='customer-details'>
              <Stack direction={'column'} spacing={2}>
                <FormControl fullWidth>
                  <CustomTextField
                    id="customer-name"
                    name="customerName"
                    label="Customer Name"
                    value={orderItem?.company_name}
                    onChange={(e) => { handleValueChange(e) }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <CustomTextField
                    id="Depot"
                    name="depot"
                    label="Depot"
                    onChange={(e) => { handleValueChange(e) }}
                    disabled
                  />
                </FormControl>

                <FormControl fullWidth>
                  <CustomTextField
                    id="address"
                    name="address"
                    label="Address"
                    onChange={(e) => { handleValueChange(e) }}
                    disabled
                  />
                </FormControl>
              </Stack>
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid size={{ xs: 3 }} key='product'>
              <Typography variant="inline" sx={{ fontWeight: 'bold' }}>
                Product Description
              </Typography>
            </Grid>
            <Grid size={{ xs: 9 }} key='product-description'>
              <Stack direction={'column'} spacing={2}>
                <FormControl fullWidth>
                  <CustomTextField
                    id="product"
                    name="product"
                    label="Product"
                    value={payload?.product_id}
                    onChange={(e) => { handleValueChange(e) }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <CustomTextField
                    id="volume"
                    name="volume"
                    label="Volume (Litres)"
                    value={payload?.volume_litres}
                    onChange={(e) => { handleValueChange(e) }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <CustomTextField
                    id="price"
                    name="price"
                    label="Price per Litre (ZAR)"
                    value={payload?.price_per_litre}
                    onChange={(e) => { handleValueChange(e) }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <CustomTextField
                    id="total"
                    name="total"
                    label="Total Price (ZAR)"
                    value={payload?.total_price}
                    onChange={(e) => { handleValueChange(e) }}
                  />
                </FormControl>
              </Stack>
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid size={{ xs: 3 }} key='product' sx={{ my: 'auto' }}>
              <Typography variant="inline" sx={{ fontWeight: 'bold' }}>
                Document
              </Typography>
            </Grid>
            <Grid size={{ xs: 9 }} key='uploaded-file'>
              <FormControl fullWidth>
                {(fileObjects && fileObjects.length > 0) && (
                  <Box sx={{ justifyContent: 'flex-start', border: 'blue' }}>
                    {handlePreviewIcon(fileObjects[0])} {fileObjects[0]?.name}
                  </Box>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
            <Button
              variant='contained'
              color="inherit"
              onClick={triggerModalClose}
            >
              Cancel
            </Button>
            <LoadingButton
              sx={{ ml: 2, }}
              variant="contained"
              loading={submitted}
              onClick={() => handleDocumentSubmit()}
            >
              Finish
            </LoadingButton>
          </Box>
        </>
      )}
      {(!isServerFileRead && !isFilePreview) && (
        <>
          <Box sx={{ mt: 2 }}>
            <FileDragzone
              acceptedTypes={{ ...acceptedTypes }}
              fileObjects={fileObjects}
              handleFileObjects={handleFileObjects}
              filesLimit={1}
              maxFileSizeMB={MAX_FILE_SIZE_MB}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
            <Button
              variant='contained'
              color="inherit"
              onClick={triggerModalClose}
            >
              Cancel
            </Button>
            <LoadingButton
              sx={{ ml: 2, }}
              variant="contained"
              loading={submitted}
              onClick={isTaxInvoice ? handleDocumentRead : handleDocumentPreview}
            >
              Continue
            </LoadingButton>
          </Box>
        </>
      )}

      {(isFilePreview) && (
        <>
          <Box sx={{ mt: 2 }}>
            <Grid item size={{ xs: 12 }} sx={{ my: 'auto', display: 'flex', justifyContent: 'center' }}>
              {isPdfFile ? (
                <CustomPdfViewer
                  fileUrl={filePreviewUrl || null}
                />
              ) : (
                <img src={filePreviewUrl || null} alt=""/>
              )}

            </Grid>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
            <Button
              variant='contained'
              color="inherit"
              onClick={triggerModalClose}
            >
              Cancel
            </Button>
            <LoadingButton
              sx={{ ml: 2, }}
              variant="contained"
              loading={submitted}
              onClick={() => handleDocumentSubmit()}
            >
              Finish
            </LoadingButton>
          </Box>
        </>

      )}
    </CustomModal>
  )
}

FinanceDocumentUpload.propTypes = {}

export default FinanceDocumentUpload