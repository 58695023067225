import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Link, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import LoginForm from '../sections/auth/login/LoginForm';
import ForgotPassword from '../sections/auth/login/ForgotPassword';
import { LogoImage, StyledContent, StyledRoot } from '../components/ui/styled-views';

// ----------------------------------------------------------------------

export default function LoginPage() {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleClickForgetPassword = () => {
    setOpen(!open);
  };

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>

      <ForgotPassword open={open} handleClose={handleClickForgetPassword} />
      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <LogoImage
              // src="https://firebasestorage.googleapis.com/v0/b/los-diesel.appspot.com/o/assets%2FLD%20Horizontal%20logo%20BLue.svg?alt=media&token=05cda179-e6bf-46fd-b78c-968183558b8e"
              alt="Honye Badger"
              src="/assets/images/hb-logo-only-dark.png"
            />
            <Typography
              component="h1"
              variant="h4"
              sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)', textAlign: 'center', mx: 2, mb: 2 }}
            >
              Log in to your account
            </Typography>

            <LoginForm  />
            <Stack direction="row" justifyContent="space-between" >
              {/* <FormControlLabel
                name='rememberMe' 
                control={<Checkbox />} 
                label="Remember me for 30 days" 
                sx={{mt: -1 }} 
              /> */}
              <Typography sx={{ textAlign: 'center' }}>
                Don't have an account?{' '}
                <span>
                  <Link
                    sx={{ alignSelf: 'center', cursor: 'default' }}
                    variant="body2"
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    Register
                  </Link>
                </span>
              </Typography>
              <span>
                <Link
                  sx={{ alignSelf: 'center', cursor: 'default' }}
                  variant="body2"
                  onClick={handleClickForgetPassword}
                >
                  Forgot Password
                </Link>
              </span>
            </Stack>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
