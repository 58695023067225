import React, { useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';

import PropTypes from 'prop-types';

// @mui
import { Container, Typography } from '@mui/material';

// components
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box';

import AllOrders from '../components/logistics/allOrders';
import SellModal from '../components/logistics/modals/sellModal';
import BuyModal from '../components/logistics/modals/buyModal';
import ApprovedOrders from '../components/logistics/approvedOrders';
import DeliveredOrders from '../components/logistics/delivered';
import DeclinedOrders from '../components/logistics/declinedOrders';
import AggregatedStatsComponent from '../components/logistics/aggregatedStatesComponent';
import AllocatedOrders from '../components/logistics/allocatedOrders';
import { useAlert } from '../contexts/LogisticsContext';
import GlobalFilter from '../components/ui/GlobalFilter';
import { isAuthorizedRole } from '../utils/authUtil';
import InvoiceList from '../components/ui/InvoiceList';
import { TabContext, TabList } from '@mui/lab';


// ----------------------------------------------------------------------
function CustomTabPanel(props) {
  const { children, value, selected, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== selected}
      id={`simple-tabpanel-${value}`}
      aria-labelledby={`simple-tab-${value}`}
      {...other}
    >
      {value === selected && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const findDefaultTab = () => {
  let _tab = 0;
  if (isAuthorizedRole(["FINANCE-C2"])) {
    _tab = 6;
  }
  // else if (isAuthorizedRole(["FINANCE-L1"])){
  //   _tab = 5;
  // }

  return _tab;
};

export default function LogisticsPage() {

  // Check last selected tab or default to the first order tab
  const defaultTab = findDefaultTab();
  const selectedTab = Number(localStorage.getItem('logistics-tab') || defaultTab);
  const [value, setValue] = React.useState(selectedTab);
  const [filterString, setFilterString] = React.useState('');
  const [updateAllOders, setUpdateAllOders] =  React.useState(false)
  const [orderLimits, setOrderLimits] = useState({
    volume: {
      min: 1,
      max: 9999999999,
    }
  })

  const { updateStats, setUpdateStats } = useAlert();
  const THIRTY_THOUSAND = 30000;

   useEffect(() => {

    setOrderLimits({
      ...orderLimits,
      volume: {
        min: isAuthorizedRole(["EXECUTIVE-C1", "OPERATIONS-C2"])? (THIRTY_THOUSAND + 1): 1,
        max: !isAuthorizedRole(["EXECUTIVE-C1", "OPERATIONS-C2", "DEMO"])? THIRTY_THOUSAND: 9999999999,
      }
    });
  }, []);

  const handleChange = (event, newValue) => {
    setUpdateStats(true);
    setValue(newValue);
    localStorage.setItem('logistics-tab', newValue);
  };

  const handleFilterChange = (filter) => {
    setFilterString(filter);
    // localStorage.setItem('logistics-filters', filter)
  };

  return (
    <>
      <Helmet>
        <title> Logistics</title>
      </Helmet>

      <Container maxWidth="false">
        <Box
          sx={{ display: 'flex', borderRadius: 1, marginTop: 2, mb: 3 }}
        >
          <Box sx={{ width: '100%' }}>
            <Typography variant="h4">
              Logistics
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexShrink: 0, mr: 2 }}>
            {(value === 0 && isAuthorizedRole(["EXECUTIVE-C1", "OPERATIONS-C2", "DEMO"])) && (
              <BuyModal modalState
                setUpdateStats={setUpdateStats}
                setUpdateAllOders={setUpdateAllOders}
                orderLimits={orderLimits}
              />
            )}
            {(value === 0 && isAuthorizedRole(["EXECUTIVE-C1", "OPERATIONS-C2", "LOGISTICS-C2", "DEMO"])) && (
              <SellModal modalState
                setUpdateStats={setUpdateStats}
                setUpdateAllOders={setUpdateAllOders}
                orderLimits={orderLimits}
              />
            )}
            {/* {(isAuthorizedRole(["FINANCE-C2", "DEMO"])) && (
              <SellModal modalState setUpdateStats={setUpdateStats} setUpdateAllOders={setUpdateAllOders} />
            )} */}
          </Box>
        </Box>
        < >
          <AggregatedStatsComponent
            updateStats={updateStats}
            setUpdateStats={setUpdateStats}
            isInvoices={value === 5}
          />
        </>
        <GlobalFilter onFilterChange={handleFilterChange} />

        <Box sx={{ width: '100%' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="basic tabs example">
                {!isAuthorizedRole(["FINANCE-C2"]) && (
                  <Tab label="Orders" value={0} {...a11yProps(0)} />
                )}
                {!isAuthorizedRole(["FINANCE-C2", "CUSTOMS-L1"]) && (
                  <Tab label="Approved" value={1} {...a11yProps(1)} />
                )}
                {isAuthorizedRole(["OPERATIONS-C2", "OPERATIONS-L1", "LOGISTICS-C2", "DEMO"]) && (
                  <Tab label="Delivered" value={2} {...a11yProps(2)} />
                )}
                {isAuthorizedRole(["OPERATIONS-C2", "OPERATIONS-L1", "LOGISTICS-C2", "DEMO", "LOGISTICS-L2", "CUSTOMS-L1"]) && (
                  <Tab label="Allocated" value={3} {...a11yProps(3)} />
                )}
                {!isAuthorizedRole(["EXECUTIVE-C1", "FINANCE-C2", "CUSTOMS-L1"]) && (
                  <Tab label="Declined" value={4} {...a11yProps(4)} />
                )}
                {isAuthorizedRole(["FINANCE-C2"]) && (
                  <Tab label="Buy" value={6} {...a11yProps(6)} />
                )}
                {isAuthorizedRole(["FINANCE-C2"]) && (
                  <Tab label="Sell" value={7} {...a11yProps(7)} />
                )}
                {isAuthorizedRole(["EXECUTIVE-C1", "FINANCE-C2", "FINANCE-L1", "DEMO"]) && (
                  <Tab label="Tax Invoices" value={5} {...a11yProps(5)} />
                )}
              </TabList>
            </Box>
            <CustomTabPanel value={0} selected={value}>
              <AllOrders filterString={filterString || localStorage.getItem('logistics-filters')}
                orderCategory={"all"}
                updateAllOders={updateAllOders}
                setUpdateAllOders={setUpdateAllOders}
                setUpdateStats={setUpdateStats}
              />
            </CustomTabPanel>

            <CustomTabPanel value={1} selected={value}>
              <ApprovedOrders filterString={filterString || localStorage.getItem('logistics-filters')}
                setUpdateStats={setUpdateStats} />
            </CustomTabPanel>

            <CustomTabPanel value={2} selected={value}>
              <DeliveredOrders filterString={filterString || localStorage.getItem('logistics-filters')}
                setUpdateStats={setUpdateStats} />
            </CustomTabPanel>

            <CustomTabPanel value={3} selected={value}>
              <AllocatedOrders filterString={filterString || localStorage.getItem('logistics-filters')}
                setUpdateStats={setUpdateStats} />
            </CustomTabPanel>

            <CustomTabPanel value={4} selected={value}>
              <DeclinedOrders filterString={filterString || localStorage.getItem('logistics-filters')}
                setUpdateStats={setUpdateStats} />
            </CustomTabPanel>

            <CustomTabPanel value={5} selected={value}>
              <InvoiceList filterString={filterString || localStorage.getItem('logistics-filters')}
                setUpdateStats={setUpdateStats} />
            </CustomTabPanel>
            <CustomTabPanel value={6} selected={value}>
              <AllOrders filterString={filterString || localStorage.getItem('logistics-filters')}
                orderCategory={"buy"}
                updateAllOders={updateAllOders}
                setUpdateAllOders={setUpdateAllOders}
                setUpdateStats={setUpdateStats}
              />
            </CustomTabPanel>
            <CustomTabPanel value={7} selected={value}>
              <AllOrders filterString={filterString || localStorage.getItem('logistics-filters')}
                orderCategory={"sell"}
                updateAllOders={updateAllOders}
                setUpdateAllOders={setUpdateAllOders}
                setUpdateStats={setUpdateStats}
              />
            </CustomTabPanel>
          </TabContext>
        </Box>

      </Container>
    </>
  );
}
