import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';

import { Container, Typography } from '@mui/material';

import { LogoImage, StyledContent, StyledRoot } from '../components/ui/styled-views';
import API from '../data/api';
import { extractFormValues, initialiseForm, isNullValue, validateForm } from 'src/utils/formUtil';
import CustomPasswordInput from 'src/components/custom/CustomPasswordInput';
import { Stack } from '@mui/material';
import CustomSnackBar from 'src/components/custom/CustomSnackBar';
import ForgotPassword from 'src/sections/auth/login/ForgotPassword';

// Initial form data
const passwordError =
  "Password must be at least 8 characters long, have 1 number,1 uppercase, and 1 special character";

const initialFormData = {
  // token: { value: '', required: false, error: false, errorMessage: 'Please select transport company' },
  password: { value: '', required: true, error: false, errorMessage: passwordError },
  confirmPassword: { value: '', required: true, error: false, errorMessage: 'Confirm password is required and must match' },
};

const passwordRegEx = new RegExp(
  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
);

const requestErrorMessage = 'Invalid token, ensure the ULR is correct';

function ResetPassword() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const initFormState = initialiseForm(initialFormData);
  const [formData, setFormData] = useState({ ...initFormState });

  const [requestError, setRequestError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: "",
    type: "",
  });

  const [openForgot, setOpenForgot] = useState(false);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    setSubmitted(true);

    if (!validateForm(setFormData, formData)) {
      console.error("Form submission failed",)
      setSubmitted(false);
      return;
    }

    if (isNullValue(token)) {
      handleRequestError(requestErrorMessage);
      setSubmitted(false);
      return;
    }

    const values = extractFormValues(formData);
    try {
      // Send the POST request with JSON body
      const response = await API.post('auth/reset-password', {
        token: token,
        password: values?.password
      });
      setSubmitted(false);
      if (response.status === 200) {
        // Reset the form if response is successful
        setSnackbarMessage({
          message: `Password rest was successful, proceed to login`,
          type: "success",
        });
        setSnackbarOpen(true);
        navigate("/login");
      } else {
        handleRequestError(requestErrorMessage);
      }
    } catch (error) {
      console.log(error);
      if (error.status === 403) {
        handleRequestError("Token has expired, request a new one");
        setOpenForgot(true);
      } else {
        handleRequestError(requestErrorMessage);
      }
    }
  };

  const handleRequestError = (message) => {
    setSubmitted(false);
    setRequestError(true);
    console.error(message);
    setSnackbarMessage({
      message: message,
      type: "error",
    });
    setSnackbarOpen(true);
  }

  const checkPasswordMatch = (e) => {
    let confError = formData.confirmPassword.error;
    if (e.target.name === 'password') {
      confError = formData.password.value !== formData.confirmPassword.value;
    }
    else if (e.target.name === 'confirmPassword') {
      confError = !passwordRegEx.test(e.target.value) || (formData.password.value !== e.target.value);
    }

    setConfirmPasswordError(confError);
  }

  const handleFormDataChange = (e) => {
    console.log(e.target.name + ' ' + e.target.value + ' ' + passwordRegEx.test(e.target.value));
    checkPasswordMatch(e);

    setFormData({
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        value: e.target.type === "checkbox" ? e.target.checked : e.target.value,
        error: !(e.target.type === "password" ? passwordRegEx.test(e.target.value) : e.target.validity.valid),
      },
    });

  };


  const handleClickForgetPassword = () => {
    setOpenForgot(!openForgot);
  };


  return (
    <>
      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <LogoImage
              alt="Honye Badger"
              src="/assets/images/hb-logo-only-dark.png"
            />
            <Typography
              component="h1"
              variant="h4"
              sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)', textAlign: 'center', mb: 2 }}
            >
              {/* Reset Password */}
            </Typography>
            {/* <Typography sx={{ textAlign: 'center', m: 2 }}>
              Recall old password?{' '}
              <span>
                <Link
                  sx={{ alignSelf: 'center', cursor: 'default' }}
                  variant="body2"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Log in
                </Link>
              </span>
            </Typography> */}

            <form onSubmit={handleSubmit} noValidate>
              <Stack spacing={3}>
                <CustomPasswordInput
                  name="password"
                  label="Password"
                  value={formData.password.value}
                  onChange={handleFormDataChange}
                  required={formData.password.required}
                  error={formData.password.error}
                  helperText={formData.password.error && formData.password.errorMessage}
                />

                <CustomPasswordInput
                  name="confirmPassword"
                  label="Confirm Password"
                  value={formData.confirmPassword.value}
                  onChange={handleFormDataChange}
                  required={formData.confirmPassword.required}
                  error={confirmPasswordError}
                  helperText={confirmPasswordError && formData.confirmPassword.errorMessage}
                />

                {/* {requestError &&
                  <p style={{ color: 'red' }}> {requestErrorMessage} </p>
                } */}
              </Stack>

              <LoadingButton
                style={{ backgroundColor: '#3B3297' }}
                sx={{ my: 2 }}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={submitted}
              >
                Reset Password
              </LoadingButton>
            </form>

            {/* <Stack direction="row" justifyContent="space-between" >
              <Typography sx={{ textAlign: 'center', }}>
                Continue to{' '}
                <span>
                  <Link
                    sx={{ alignSelf: 'center', cursor: 'default' }}
                    variant="body2"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Log in
                  </Link>
                </span>
              </Typography>
              <span>
                <Link
                  sx={{ alignSelf: 'center', cursor: 'default' }}
                  variant="body2"
                  onClick={handleClickForgetPassword}
                >
                  Request New Token
                </Link>
              </span>
            </Stack> */}
          </StyledContent>
        </Container>
      </StyledRoot>

      <ForgotPassword
        open={openForgot}
        handleClose={handleClickForgetPassword}
        title={"Request New Token"}
      />

      <CustomSnackBar
        snackbarMessage={snackbarMessage}
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={() => setSnackbarOpen(false)}
      />
    </>
  );
}

ResetPassword.propTypes = {

};

export default ResetPassword;
