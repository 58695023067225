import { Card, CardContent, MenuItem, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import CustomTextField from 'src/components/custom/CustomTextField';
import { PRODUCT_SEGMENTATION_FILTERS } from 'src/utils/constants';

const GroupedStackedColumn = ({
  series, categories, height, title, periodFilter, setPeriodFilter, showLegend
}) => {

  const [configs, setConfigs] = useState({});

  useEffect(() => {
    const _configs = {
      title: title || '',
      height: height || 350,
      periodFilter: periodFilter || '',
      series: series || [],
      options: {
        chart: {
          type: 'bar',
          height: height || 350,
          stacked: true,
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        dataLabels: {
          formatter: (val) => {
            return val / 1000 + 'K'
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
          }
        },
        xaxis: {
          categories: categories || [""],
        },
        fill: {
          opacity: 1
        },
        // colors: [ '#80f1cb', '#70E396', '#2A8FFB', '#90c7fd'],
        yaxis: {
          labels: {
            formatter: (val) => {
              return val / 1000 + 'K'
            }
          }
        },
        legend: {
          show: showLegend || false,
          showForNullSeries: false,
          showForZeroSeries: false,
          clusterGroupedSeries: true,
          clusterGroupedSeriesOrientation: "horizontal",
          position: 'top',
          horizontalAlign: 'center',
        }
      },
    };
    // console.log(_configs)

    setConfigs(_configs);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [series, categories, height, title, periodFilter]);

    return (
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Stack
            direction="row"
            sx={{
              alignItems: "flex-start",
              justifyContent: "space-between",
              mb: 2
            }}
            spacing={1}
          >
            <Typography variant="h5" > {configs.title || "Reservoir Quantities (m\u00B3)"} </Typography>
            <CustomTextField
              variant="standard"
              sx={{ minWidth: 120 }}
              select
              labelId="period-select-label"
              value={periodFilter}
              onChange={(e) => { setPeriodFilter(e.target.value) }}
            >
              <MenuItem value="" selected disabled>
                <em>Select Period</em>
              </MenuItem>
              {PRODUCT_SEGMENTATION_FILTERS.map((p) => (
                <MenuItem key={p.key} value={p.key}>
                  {p.value}
                </MenuItem>
              ))}
            </CustomTextField>
          </Stack>
          {/* <Typography sx={{ mb: 1, mt: -2 }} color='textDisabled' > {subTitle || "Reservoir Quantities"} </Typography> */}

          {series?.length > 0 &&
            <div id="chart">
              <ReactApexChart
                options={configs.options}
                series={configs.series}
                type="bar"
                height={configs.height}
              />
            </div>
          }
        </CardContent>
      </Card>
    );

}
export default GroupedStackedColumn;
