import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {
  Button,
  FormControl,
  MenuItem,
  Typography,
} from '@mui/material';

import API from '../../data/api';
import CustomModal from '../custom/CustomModal';
import { extractFormValues, isNullValue, validateForm } from '../../utils/formUtil';
import { FormItemlGrid } from '../ui/FormItemlGrid';
import { fNumber } from 'src/utils/formatNumber';
import { CustomSelect } from '../custom/CustomSelect';
import { LoadingButton } from '@mui/lab';

const initNomination = {
  transporter: { value: '', required: true, error: false, errorMessage: 'Please select transport company' },
  driver: { value: '', required: true, error: false, errorMessage: 'Please allocate driver' },
  vehicle: { value: '', required: true, error: false, errorMessage: 'Please specify a vehicle/truck' },
  trailer1: { value: '', required: true, error: false, errorMessage: 'Please specify a trailer' },
  trailer2: { value: '', required: false, error: false, errorMessage: 'Please specify a trailer' },
  origin: { value: '', required: true, error: false, errorMessage: 'Please specify origin of supply' },
};

const productOrigins= [
  { key: 'direct', value: 'Local'},
  { key: 'in-bond', value: 'International'},
]

class OrderNomination extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      nomination: {
        ...initNomination,
        transporter: {
          ...initNomination.transporter,
          value: this.props?.subOrder?.transport_id,
        },
      },
      vehicles: [],
      drivers: [],
      trailer1s: [],
      trailer2s: [],
      showTrailer2: false,
      submitted: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.subOrder !== this.props.subOrder) {
      this.setState({
        nomination: {
          ...initNomination,
          transporter: {
            ...initNomination.transporter,
            value: this.props?.subOrder?.transport_id,
          },
        },
      })
      // Fetch transport details
      const transport_id = this.props.subOrder?.transport_id;
      this.getLogisticsData(transport_id);
    }
  }

  getVehicles = async (transport_id) => {
    if (!transport_id) {
      return;
    }

    try {
      const response = await API.get(`inventory/logistics/vehicles?company_id=${transport_id}`);
      if (response.status === 200) {
        this.setState({ vehicles: response.data });
      } else {
        console.error('Error fetching vehicles: ', response.status);
      }
    } catch (error) {
      console.error('Error fetching vehicles: ', error);
    }
  };

  getDrivers = async (transport_id) => {
    if (!transport_id) {
      return;
    }

    try {
      const response = await API.get(`inventory/logistics/drivers?company_id=${transport_id}`);
      if (response.status === 200) {
        this.setState({ drivers: response.data });
      } else {
        console.error('Error fetching drivers: ', response.status);
      }
    } catch (error) {
      console.error('Error fetching drivers: ', error);
    }
  };


  getTrailer1s = async (transport_id) => {
    if (!transport_id) {
      return;
    }

    try {
      const response = await API.get(`inventory/logistics/trailers?company_id=${transport_id}&min_volume=5001`);
      if (response.status === 200) {
        this.setState({ trailer1s: response.data });
      } else {
        console.error('Error fetching trailer1s: ', response.status);
      }
    } catch (error) {
      console.error('Error fetching trailer1s: ', error);
    }
  };

  getTrailer2s = async (transport_id) => {
    if (!transport_id) {
      return;
    }

    try {
      const response = await API.get(`inventory/logistics/trailers?company_id=${transport_id}&&max_volume=5000`);
      if (response.status === 200) {
        this.setState({ trailer2s: response.data });
      } else {
        console.error('Error fetching trailer1s: ', response.status);
      }
    } catch (error) {
      console.error('Error fetching trailer1s: ', error);
    }
  };

  handleSubmitNomination = async () => {
    const { suborder_id, supplier_id } = this.props?.subOrder;

    // If any of the inputs or required parameters is empty, return
    if (!validateForm(this.setNomination, this.state.nomination) || isNullValue(supplier_id)) {
      return;
    }

    const { driver, transporter, vehicle, trailer1, trailer2, origin } = extractFormValues(this.state.nomination);
    let endpoint = `inventory/logistics/create_nomination?suborder_id=${suborder_id}`
      .concat(`&supplier_id=${supplier_id}`)
      .concat(`&driver_id=${driver}`)
      .concat(`&transporter_id=${transporter}`)
      .concat(`&vehicle_id=${vehicle}`)
      .concat(`&trailer1_id=${trailer1}`)
      .concat(`&customs_type=${origin}`)

    if(trailer2){
      endpoint = endpoint.concat(`&trailer2_id=${trailer2}`)
    }

    this.setState({ submitted: true, });
    try {
      const response = await API.post(endpoint, '');

      if (response.status === 200 || response.status === 201) {
        this.setNomination(initNomination);
        this.props?.handleSumitCallback();
      } else {
        console.error('Error creating suborder nomination: ', response.status);
      }
    } catch (error) {
      console.error('Error creating suborder nomination: ', error);
    }
    this.setState({ submitted: false, });
  };

  getLogisticsData = async (transport_id) => {
    this.getDrivers(transport_id);
    this.getVehicles(transport_id);
    this.getTrailer1s(transport_id);
    this.getTrailer2s(transport_id);
  }

  setNomination = (_nomination) =>{
    this.setState({
      nomination: { ..._nomination },
    });
  }

  handleInputChange = (field, value, error = false) => {

    let currentState = { ...this.state.nomination };

    // Change of transporter
    if (field === 'transporter') {
      currentState = { ...initNomination };
      this.getLogisticsData(value);
    }

    let _showTrailer2 = this.state.showTrailer2;
    if (field === 'trailer1') {
      const trailer1 = this.state.trailer1s?.find(t => t.id === value);
      _showTrailer2 = (trailer1 && trailer1?.capacity < this.state.subOrder?.volume);
    }

    this.setState(() => ({
      nomination: {
        ...currentState,
        [field]: {
          ...currentState[field],
          value: value,
          error: isNullValue(value) || error,
        },
      },
      showTrailer2: _showTrailer2,
    }));
  };

  render() {
    const { subOrder, openModal } = this.props;

    return (
      <>
        <CustomModal
          openModal={openModal}
          handleCloseModal={() => this.props?.handleCloseModal()}
          modalTitle={`Suborder ${this.state.subOrder?.order_item_id || ''} Nomination`}
          modalIcon='transport'
          clearSign={!this.state.submitted}
        >
          <>
            <Typography color='textDisabled' sx={{ mb: 2, mt: 0 }}>
              <em> {`Volume: ${fNumber(subOrder?.volume)} Litres`} </em>
            </Typography>
            <FormItemlGrid
              label={"Transporter"}
              required={this.state.nomination.transporter.required}
            >
              <FormControl fullWidth sx={{ backgroundColor: 'whitesmoke' }}>
                <CustomSelect
                  // label={"Transporter"}
                  labelId="transporter-select-label"
                  value={this.state.nomination.transporter.value}
                  onChange={(e) => this.handleInputChange('transporter', e.target.value)}
                  required={this.state.nomination.transporter.required}
                  error={this.state.nomination.transporter.error}
                  helperText={this.state.nomination.transporter.error && this.state.nomination.transporter.errorMessage}
                  disabled
                >
                  <MenuItem value="" disabled>
                    <em>Select Transport Company</em>
                  </MenuItem>
                  {this.props?.transportCompanies?.map((trans) => (
                    <MenuItem key={'transport ' + trans.company_id} value={trans.company_id}>
                      {trans.company_name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </FormItemlGrid>

            <FormItemlGrid
              label={"Driver"}
              required={this.state.nomination.driver.required}
            >
              <FormControl fullWidth >
                <CustomSelect
                  // label={"Driver"}
                  labelId="driver-select-label"
                  value={this.state.nomination.driver.value}
                  onChange={(e) => this.handleInputChange('driver', e.target.value)}
                  required={this.state.nomination.driver.required}
                  error={this.state.nomination.driver.error}
                  helperText={this.state.nomination.driver.error && this.state.nomination.driver.errorMessage}
                >
                  <MenuItem value="">
                    <em>Select Driver</em>
                  </MenuItem>
                  {this.state.drivers?.map((driver) => (
                    <MenuItem key={`driver-${driver.id}`} value={driver.id}>
                      {driver.first_name} {driver.last_name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </FormItemlGrid>

            <FormItemlGrid
              label={"Vehicle"}
              required={this.state.nomination.vehicle.required}
            >
              <FormControl fullWidth sx={{ mt: 2 }}>
                <CustomSelect
                  labelId="vehicle-select-label"
                  // label="Select Supplier"
                  value={this.state.nomination.vehicle.value}
                  onChange={(e) => this.handleInputChange('vehicle', e.target.value)}
                  required={this.state.nomination.vehicle.required}
                  error={this.state.nomination.vehicle.error}
                  helperText={this.state.nomination.vehicle.error && this.state.nomination.vehicle.errorMessage}
                >
                  <MenuItem value="" disabled>
                    <em>Select Vehicle</em>
                  </MenuItem>
                  {this.state.vehicles?.map((vehicle) => (
                    <MenuItem key={`vehicle-${vehicle.id}`} value={vehicle.id}>
                      {vehicle.registration}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </FormItemlGrid>

            <FormItemlGrid
              label={"Trailer1"}
              required={this.state.nomination.trailer1.required}
            >
              <FormControl fullWidth sx={{ mt: 2 }}>
                <CustomSelect
                  labelId="trailer1-select-label"
                  value={this.state.nomination.trailer1.value}
                  onChange={(e) => this.handleInputChange('trailer1', e.target.value)}
                  required={this.state.nomination.trailer1.required}
                  error={this.state.nomination.trailer1.error}
                  helperText={this.state.nomination.trailer1.error && this.state.nomination.trailer1.errorMessage}
                >
                  <MenuItem value="" disabled>
                    <em>Select Trailer1</em>
                  </MenuItem>
                  {this.state.trailer1s?.map((trailer) => (
                    <MenuItem key={`trailer1-${trailer.id}`} value={trailer.id}>
                      {trailer.registration} {`(${fNumber(trailer.capacity)} Litres)`}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </FormItemlGrid>

            <FormItemlGrid
              label={"Trailer 2"}
              required={this.state.nomination.trailer2.required}
            >
              <FormControl fullWidth sx={{ mt: 2, backgroundColor: `${this.state.showTrailer2 ? '' : 'whitesmoke'} ` }} >
                <CustomSelect
                  labelId="trailer2-select-label"
                  value={this.state.nomination.trailer2.value}
                  onChange={(e) => this.handleInputChange('trailer2', e.target.value)}
                  required={this.state.nomination.trailer2.required}
                  error={this.state.nomination.trailer2.error}
                  helperText={this.state.nomination.trailer2.error && this.state.nomination.trailer2.errorMessage}
                  disabled={!this.state.showTrailer2}
                >
                  <MenuItem value="" disabled>
                    <em>Select Trailer2</em>
                  </MenuItem>
                  {this.state.trailer2s?.map((trailer) => (
                    <MenuItem key={`trailer2-${trailer.id}`} value={trailer.id}>
                      {trailer.registration} {`(${fNumber(trailer.capacity)} Litres)`}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </FormItemlGrid>

            <FormItemlGrid
              label={"Product Origin"}
              required={this.state.nomination.origin.required}
            >
              <FormControl fullWidth sx={{ mt: 2 }}>
                <CustomSelect
                  labelId="origin-select-label"
                  value={this.state.nomination.origin.value}
                  onChange={(e) => this.handleInputChange('origin', e.target.value)}
                  required={this.state.nomination.origin.required}
                  error={this.state.nomination.origin.error}
                  helperText={this.state.nomination.origin.error && this.state.nomination.origin.errorMessage}
                >
                  <MenuItem value="" disabled>
                    <em>Select Origin</em>
                  </MenuItem>
                  {productOrigins?.map((origin) => (
                    <MenuItem key={`origin-${origin.key}`} value={origin.key}>
                      {origin.value}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </FormItemlGrid>

            <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
              <Button
                variant="contained"
                color="inherit"
                disabled={this.state.submitted}
                onClick={() => this.props?.handleCloseModal()}
              >
                Cancel
              </Button>

              <LoadingButton
                variant='contained'
                sx={{ ml: 2, }}
                type="submit"
                loading={this.state.submitted}
                onClick={this.handleSubmitNomination}
              >
                Submit
              </LoadingButton>

            </Box>
          </>
        </CustomModal>
      </>
    );
  }
}

OrderNomination.propTypes = {

  /** Selected subOrder details */
  subOrder: PropTypes.object.isRequired,

  /** List of suitable transport companies */
  transportCompanies: PropTypes.array.isRequired,

  /** Flag to toggling openning of order nomination modal */
  openModal: PropTypes.bool.isRequired,

  /** Callback function when closing  order nomination  modal */
  handleCloseModal: PropTypes.func.isRequired,

  /** Callback function upon successful submission of  order nomination */
  handleSubmitCallback: PropTypes.func.isRequired,

};

export default OrderNomination;
