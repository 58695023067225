import { Helmet } from 'react-helmet-async';
import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { Container, Typography, Button, Box, FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Iconify from '../components/iconify';
import API from '../data/api'; // Import the API module
import CustomTextField from 'src/components/custom/CustomTextField';
import CustomModal from 'src/components/custom/CustomModal';
import CustomPdfViewer from 'src/components/custom/CustomPdfViewer';
import { filePreviewStyle, MAX_FILE_SIZE_MB } from 'src/utils/constants';
import FileDragzone from 'src/components/ui/FileDragzone';
import { LoadingButton } from '@mui/lab';
import { downloadFile } from 'src/utils/fileUtils';
import CustomTablePagination from 'src/components/custom/CustomTablePagination';

// eslint-disable-next-line no-unused-vars
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ReportsPage() {
  const [documents, setDocuments] = useState([]);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [fileObjects, setFileObjects] = useState([]);
  const [filePreviewUrl, setFilePreviewUrl] = useState(null);
  const [isFilePreview, setIsFilePreview] = useState(false);
  const [uploadModalStyle, setUploadModalStyle] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const fetchDocuments = useCallback(async () => {
    try {
      const response = await API.get(`sharedfiles/view_all?page=${page}&records=${rowsPerPage}${searchTerm ? `&search_term=${searchTerm}` : ''}`);
      setDocuments(response.data.files);
      setTotalPages(Math.ceil(response.data.total_files / rowsPerPage));
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  }, [page, rowsPerPage, searchTerm]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const handleView = (document) => {
    setSelectedDocument(document);
    setOpenViewModal(true);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
    setSelectedDocument(null);
  };

  const handleFileObjects = (files) => {
    const file = (files.length > 0) ? files[0] : null;
    setSelectedFile(file);
    setFileObjects(files);
    setFilePreviewUrl(file ? URL.createObjectURL(file) : null);
  };

  const handleUploadDocumentPreview = () => {

    if (fileObjects.length === 0 || fileObjects[0] === null) {
      return;
    }

    console.log('uploadDocumentPreview ', fileObjects[0]);
    setUploadModalStyle({ ...filePreviewStyle });
    setIsFilePreview(true);
  }

  const handleUpload = () => {
    setOpenUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
    setSelectedFile(null);
    setUploadModalStyle({ });
    setIsFilePreview(false);
    setFileObjects([]);
    setFilePreviewUrl(null);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;
  
    const formData = new FormData();
    formData.append('file', selectedFile);
    setSubmitted(true);

    try {
      // Adjust the API call based on your backend URL
      await API.put('sharedfiles/upload?service_provider=aws', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      handleCloseUploadModal();
      fetchDocuments(); // Refresh the document list after upload
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleDelete = async (fileName) => {
    try {
      await API.delete(`sharedfiles/delete_single_file/aws/${fileName}`);
      fetchDocuments(); // Refresh the document list after deletion
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (direction) => {
    let currentPage = page;
    if (direction === 'next') {
      currentPage = Math.min(currentPage + 1, totalPages);
    } else {
      currentPage = Math.max(currentPage - 1, 1);
    }
    setPage(currentPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const records = parseInt(event.target.value, 10);
    setRowsPerPage(records);
    setPage(1);
  }

  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>

      <Container maxWidth="false">
        <Box
          sx={{ display: 'flex', borderRadius: 1, }}
        >
          <Box sx={{ width: '100%' }}>
            <Typography variant="h4">
              Reports
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexShrink: 0, mr: 2 }}>
            <Button  variant='contained' sx={{ mb: 2 }} onClick={handleUpload}>
              Upload Document
            </Button>
          </Box>
        </Box>
        <FormControl fullWidth >
          <CustomTextField
            id="search"
            label="Search Documents"
            variant="outlined"
            onChange={handleSearchChange}
          />
        </FormControl>

        <TableContainer sx={{ mt: 2, width: '100%', overflowX: { xs: 'scroll', sm: 'auto' } }} >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documents.map((document) => (
                <TableRow key={document.name}>
                  <TableCell component="th" scope="row">
                    <Iconify icon={'ion:documents-sharp'} sx={{ fontSize: '36px', verticalAlign: 'middle', mr: 2 }} />
                    {document.name}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton color="primary" onClick={() => handleView(document)}>
                      <Iconify icon={'mdi:eye'} />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => downloadFile(document?.url, document?.name)}>
                      <Iconify icon={'mdi:download'} />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleDelete(document.name)}>
                      <Iconify icon={'mdi:delete'} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <CustomTablePagination
          rowsPerPage={rowsPerPage}
          recordsList={[5, 10, 15, 20]}
          handleChangeRowsPerPage={(e) =>handleChangeRowsPerPage(e)}
          handleNextPage={handlePageChange}
          currentPage={page}
          totalPages={totalPages}
        />

        <CustomModal
          openModal={openViewModal}
          handleCloseModal={handleCloseViewModal}
          modalTitle={`View Document`}
          modalIcon='document'
          modalStyle={{ ...filePreviewStyle }}
        >
          <>
            <Box sx={{ mt: 2 }}>
              <Grid item size={{ xs: 12 }} sx={{ my: 'auto', display: 'flex', justifyContent: 'center' }}>
                <CustomPdfViewer
                  fileUrl={selectedDocument?.url || null}
                />
              </Grid>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
              <Button
                variant='contained'
                color="inherit"
                onClick={handleCloseViewModal}
              >
                Cancel
              </Button>
            </Box>
          </>
        </CustomModal>

        <CustomModal
          openModal={openUploadModal}
          handleCloseModal={handleCloseUploadModal}
          modalTitle={`Upload ${selectedFile?.name || 'Document'}`}
          modalIcon='document'
          modalStyle={uploadModalStyle}
          clearSign={!submitted}
        >
          {(!isFilePreview) && (
            <>
              <Box sx={{ my: 2 }}>
                <FileDragzone
                  acceptedTypes={{
                    'application/pdf': ['.pdf'],
                  }}
                  fileObjects={fileObjects}
                  handleFileObjects={handleFileObjects}
                  filesLimit={1}
                  maxFileSizeMB={MAX_FILE_SIZE_MB}
                />
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
                <Button
                  variant='contained'
                  color="inherit"
                  onClick={handleCloseUploadModal}
                >
                  Cancel
                </Button>
                <LoadingButton
                  sx={{ ml: 2, }}
                  variant="contained"
                  onClick={(handleUploadDocumentPreview)}
                >
                  Continue
                </LoadingButton>
              </Box>
            </>
          )}

          {isFilePreview && (
            <>
              <Box sx={{ mt: 2 }}>
                <Grid item size={{ xs: 12 }} sx={{ my: 'auto', display: 'flex', justifyContent: 'center' }}>
                  <CustomPdfViewer
                    fileUrl={filePreviewUrl}
                  />
                </Grid>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
                <Button
                  variant='contained'
                  color="inherit"
                  disabled={submitted}
                  onClick={handleCloseUploadModal}
                >
                  Cancel
                </Button>
                <LoadingButton
                  sx={{ ml: 2, }}
                  variant="contained"
                  loading={submitted}
                  onClick={handleFileUpload}
                >
                  Finish
                </LoadingButton>
              </Box>
            </>
          )}
        </CustomModal>
      </Container>
    </>
  );
}
