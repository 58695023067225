import * as React from "react"
import {
  Box,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"

import LabeledProgressBar from "./LabeledProgressBar"
import { colorCodes } from "../dash-util";

export function LabeledProgressCard({ data, sx={}, isOpenTooltip=false }) {

  const {leftBar, rightBar, type, value} = data;

  const barTooltip = data?.tip || "There is no progress";
  return (
    <Card sx={{ height: '100%', ...sx }}>
      <CardContent>
        <Box
          direction="column"
          sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
          spacing={3}
        >
          <Typography sx={{ mb: 1, }} variant="inherit">{type || ''}</Typography>
          <LabeledProgressBar value={value || 0} height={'35px'} rightBar={rightBar} leftBar={leftBar} />
          <Tooltip
            open={isOpenTooltip}
            arrow
            title={barTooltip}
            placement="bottom-start"
            PopperProps={{
              sx: {
                zIndex: 500,
                marginLeft: 10,
                maxWidth: 200,
                // '& .MuiTooltip-arrow': {
                //   left: "-50% !important"
                // }
              }
            }}
          >
            <Typography sx={{ marginTop: -2, }} variant="inherit" />
          </Tooltip>
        </Box>
        {(leftBar?.legend && rightBar?.legend) && (
          <Stack
            direction="row"
            sx={{
              alignItems: "flex-start",
              justifyContent: "space-between",
              mt: 8
            }}
            spacing={1}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ bgcolor: leftBar?.backgroundColor || colorCodes[4], height: '10px', width: '10px', borderRadius: '50%' }} variant="overline" />
              <Box sx={{ ml: 2, fontSize: 12 }}> {leftBar?.legend}</Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ bgcolor: rightBar?.backgroundColor || colorCodes[2], height: '10px', width: '10px', borderRadius: '50%' }} variant="overline" />
              <Box sx={{ ml: 2, fontSize: 12 }}> {rightBar?.legend}</Box>
            </Box>

          </Stack>
        )}
      </CardContent>
    </Card>
  )
}
