// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.neutral,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          marginTop: '20px',
          width: '100%',
          overflowX: { xs: 'scroll', sm: 'auto' },
          borderRadius: '20px 20px 0px 0px',
        }
      }
    },
  };
}
