import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid2'
import { Box, Button, Card, CardContent, Typography } from '@mui/material';

import palette from "../../../theme/palette"
import CustomModal from '../../custom/CustomModal';
import { fNumber, scaleNumber } from '../../../utils/formatNumber';

const InnerCard = ({ type, value, sx = {} }) => {
  return (
    <Card sx={{ height: '100%', border: 1, borderColor: palette.grey[500], ...sx }}>
      <CardContent>
        <Typography variant="inherit" sx={{ color: palette.grey[500] }}>{type}</Typography>
        <Typography variant="inherit" sx={{ fontWeight: "bold", }}>{value}</Typography>
      </CardContent>
    </Card>
  );
}

const gridSize = { lg: 4, sm: 4, xs: 12 };

export const OrderAllocationSummary = ({ orderData, openModal, handleCloseModal }) => {

  return (
    <>
      {orderData && (
        <CustomModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          modalTitle={"Order " + orderData?.suborder_id + " Summary"}
        >
          <>
            <Typography variant="inherit" sx={{ color: palette.grey[500] }}>{orderData?.transporter_name || ''}</Typography>
{/*
            <Grid container spacing={3} sx={{ mt: 2 }}>
              <Grid size={{ ...gridSize }} key='Supplier Name'>
                <InnerCard type={"Supplier"} value={orderData?.supplier_name} />
              </Grid>
              <Grid size={{ ...gridSize }} key='fuel-type'>
                <InnerCard type={"Fuel Type"} value={orderData?.fuel_type} />
              </Grid>

              <Grid size={{ ...gridSize }} key='delivery'>
                <InnerCard type={"Delivery"} value={orderData?.delivery_location} />
              </Grid>
            </Grid>
*/}

            <Grid container spacing={3} sx={{ mt: 2 }}>
              <Grid size={{ ...gridSize }} key='driver'>
                <InnerCard type={"Driver"} value={orderData?.driver_name} />
              </Grid>
              <Grid size={{ ...gridSize }} key='vehicle-truck'>
                <InnerCard type={"Vechile"} value={orderData?.vehicle_registration} />
              </Grid>

              <Grid size={{ ...gridSize }} key='trailer'>
                <InnerCard type={"Trailer"} value={orderData?.trailer1_registration} />
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid size={{ ...gridSize }} key='volume'>
                <InnerCard type={"volume"} value={fNumber(orderData?.volume)} />
              </Grid>
              <Grid size={{ ...gridSize }} key='Cost'>
                <InnerCard type={"Cost"} value={scaleNumber(orderData?.cost)} />
              </Grid>
              <Grid size={{ ...gridSize }} key='Documents'>
                <InnerCard type={"Documents"} value={`${orderData?.outstanding_documents || 0} outstanding documents `} />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
              <Button
                variant="outlined"
                sx={{ mt: 2, color: 'black', backgroundColor: palette.grey[200], border: palette.grey[200], }}
                onClick={handleCloseModal}
              >
                Close
              </Button>
            </Box>
          </>
        </CustomModal>
      )}
    </>
  )
}

OrderAllocationSummary.propTypes = {
  orderData: PropTypes.arrayOf(PropTypes.object).isRequired,
}
