import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '@mui/material/Table';
import { TableHead, TableBody, TableCell, TableRow, Paper, TableContainer } from '@mui/material';

import API from '../../data/api';
// import GlobalFilter from '../global-filter/GlobalFilter';
import CustomTablePagination from '../custom/CustomTablePagination';

export default class AllocatedOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allocatedOrders: [],
            page: 1,
            records: 15,
            totalRecords: 1,
            totalPages: null,
            recordsList: [5, 10, 15, 20],
        };
    }

    componentDidMount() {
        this.getAllocatedOrders(this.props.filterString);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.filterString !== this.props.filterString) {
          this.getAllocatedOrders(this.props.filterString);
        }
    }
    
    getAllocatedOrders = async (finalQuery = '') => {
        const { records, page } = this.state;
        let conTemp = `inventory/backoffice/allocated_suborders?records=${records}&page=${page}`;

        if (finalQuery) {
          conTemp += finalQuery;
        }

        try {
            const res = await API.get(conTemp);
            if (res.status === 200) {
                const suborders = res.data.suborders;
                this.setState({
                    allocatedOrders: suborders,
                    totalPages: Math.min(res.data.total_pages, Math.ceil(suborders?.length/this.state.records)),
                    totalRecords: res.data.total_suborders,
                });
            } else {
                console.error("Error fetching allocated orders");
            }
        } catch (error) {
            console.error("Error fetching allocated orders:", error);
        }
    }

    nextPage = (action) => {
        const { totalPages } = this.state;
        this.setState((prevState) => {
            let currentPage = prevState.page;

            if (action === 'next') {
                currentPage = Math.min(currentPage + 1, totalPages);
            } else {
                currentPage = Math.max(currentPage - 1, 1);
            }

            return { page: currentPage };
        }, this.getAllocatedOrders);
    }

    handleChangeRowsPerPage = (event) => {
        this.setState(() => {
            return { records: parseInt(event.target.value, 10), page: 1 };
        }, () => {
            this.getDeliveredOrders(this.props.filterString);
        });
    }

    render() {
        const { allocatedOrders } = this.state;
        return (
            <Paper>
            {/* <GlobalFilter sx={{ my: 1 }} onFilterChange={this.handleFilterChange} /> */}
    
            <TableContainer sx={{ mt: 2, width: '100%', overflowX: { xs: 'scroll', sm: 'auto' } }} >
            <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Order Item ID</TableCell>
                            <TableCell align="right">Client Name</TableCell>
                            <TableCell align="right">Product</TableCell>
                            <TableCell align="right">Volume</TableCell>
                            <TableCell align="right">Delivery Date</TableCell>
                            <TableCell align="right">Delivery Location</TableCell>
                            <TableCell align="right">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allocatedOrders.map((row) => (
                            <TableRow
                                key={row.suborder_id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: 'rgba(39,226,245,0.27)' } }}
                            >
                                <TableCell component="th" scope="row">{row.suborder_id}</TableCell>
                                <TableCell align="right">{row.company_name}</TableCell>
                                <TableCell align="right">{row.fuel_type}</TableCell>
                                <TableCell align="right">{row.volume}</TableCell>
                                <TableCell align="right">{moment(row.delivery_scheduled_datetime).format('YYYY/MM/DD - HH:mm:ss')}</TableCell>
                                <TableCell align="right">{row.delivery_location}</TableCell>
                                <TableCell>{row.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </TableContainer>
                <CustomTablePagination
                    rowsPerPage={this.state.records}
                    recordsList={this.state.recordsList}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                    handleNextPage={this.nextPage.bind(this)}
                    currentPage={this.state.page}
                    totalPages={this.state.totalPages}
                />
            </Paper>
        );
    }
}

AllocatedOrders.propTypes = {
    filterString: PropTypes.string
};
