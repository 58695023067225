/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, IconButton, Modal, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ClearIcon from '@mui/icons-material/Clear';

import { BusFrontFill, CheckAll, Coin, FileEarmarkCheckFill, TrashFill } from 'react-bootstrap-icons';

import CustomBox from './CustomBox';
import { HEX_OPACITY, MODAL_WIDTH } from '../../utils/constants';
import palette from '../../theme/palette';
import { QrCode } from '@mui/icons-material';

// color='#27ae60'
const decoratorStyles = {
  checkAll: {
    background: palette.success.main,
    icon: <CheckAll color={palette.success.dark} />,
  },
  delete: {
    background: palette.error.main,
    icon: <TrashFill color={palette.error.dark} />,
  },
  document: {
    background: palette.info.main,
    icon: <FileEarmarkCheckFill color={palette.info.dark} />,
  },
  currency: {
    background: palette.warning.main,
    icon: <Coin color={palette.warning.dark} />,
  },
  qrcode: {
    background: palette.info.main,
    icon: <QrCode color={palette.info.dark} />,
  },
  transport: {
    background: palette.info.main,
    icon: <BusFrontFill color={palette.info.dark} />,
  }
}

/**
 * Custom and decorated Modal Window
 */
const CustomModal = ({
  openModal,
  handleCloseModal,
  modalTitle,
  modalIcon,
  modalStyle = {},
  clearSign = true,
  children,
 }) => {

  const [modalDecorator, setModalDecorator] = useState(decoratorStyles.checkAll);

  useEffect(() => {
    if (modalIcon && Object.keys(decoratorStyles).includes(modalIcon)) {
      setModalDecorator(decoratorStyles[modalIcon]);
    }
  }, []);

  return (
    <Modal
      open={openModal}
      onClose={(e, r) => {
        if (r && (r === "backdropClick" | r === "escapeKeyDown")) return;
        else handleCloseModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <CustomBox
        sx={{
          width: {...MODAL_WIDTH.medium},
          ...modalStyle,
        }}
      >
        <Box
          sx={{
            backgroundColor: `#FFFFFF${HEX_OPACITY[80]}`,
            height: "60px",
            width: "60px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-60px",
            mx: "auto",
          }}
        >
          <Box
            sx={{
              backgroundColor: `${modalDecorator.background}${HEX_OPACITY[20]}`,
              height: "45px",
              width: "45px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mx: "auto",
            }}
          >
            {modalDecorator.icon}
          </Box>
        </Box>
        {modalTitle && (
          <Grid container alignItems={'center'} key={"modal_container"}>
            <Grid item
              key={"main_modal-title"}
              size={{ xs: 11 }}
              sx={{ my: 'auto', display: 'flex', justifyContent: 'left' }}
            >
              <Typography variant="h5">{modalTitle}</Typography>
            </Grid>
            {(clearSign) && (

            <Grid item size={{ xs: 1 }} key={"clear-sign"} >
              <Box sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center"
              }}>
                <IconButton
                  sx={{
                    backgroundColor: `#919EAB${HEX_OPACITY[10]}`,
                    borderRadius: "5px",
                  }}
                  onClick={() => handleCloseModal()}
                >
                  <ClearIcon color='disabled' />
                </IconButton>
              </Box>
            </Grid>
            )}
          </Grid>
        )}

        {children}
      </CustomBox>
    </Modal>
  )
}

CustomModal.propTypes = {
  /** A flag to toggle the open state of a modal */
  openModal: PropTypes.bool.isRequired,

  /** Callback function to be called when closing the modal window */
  handleCloseModal: PropTypes.func.isRequired,

  /** Title for the modal window */
  modalTitle: PropTypes.string,

  /** Icon decorator name for the modal window */
  modalIcon: PropTypes.string,

  /** Overiding style for the modal window */
  modalStyle: PropTypes.object,

  /** Inner components */
  children: PropTypes.node.isRequired,
}

export default CustomModal;
