/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper} from '@mui/material';
import moment from 'moment/moment';

import API from '../../data/api';
import CustomTablePagination from '../../components/custom/CustomTablePagination';
import { fNumber } from 'src/utils/formatNumber';

export default function InvoiceList({filterString}) {

  const [invoiceData, setInvoiceData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchInvoiceData({});
    setPage(1);
    setRowsPerPage(10);
  }, [filterString]);

  const fetchInvoiceData = async ({ rows = rowsPerPage, pageNo = page }) => {
    try {
      let endpoint = `accounting/tax_invoices?records=${rows}&page=${pageNo}`;
      if (filterString) {
        endpoint += filterString;
      }

      const res = await API.get(endpoint);
      setInvoiceData(res.data?.invoices || []);
      setTotalPages(Math.ceil((res.data?.total_invoices || 0) / rowsPerPage));
    } catch (error) {
      console.error('Error fetching card data:', error);
    }
  };

  const handlePageChange = (action) => {
    let currentPage = page;
    if (action === 'next') {
      currentPage = Math.min(currentPage + 1, totalPages);
    } else {
      currentPage = Math.max(currentPage - 1, 1);
    }
    setPage(currentPage);
    fetchInvoiceData({ pageNo: currentPage });
  };

  const handleChangeRowsPerPage = (event) => {
    const records = parseInt(event.target.value, 10);
    setRowsPerPage(records);
    setPage(1);
    fetchInvoiceData({ rows: records, pageNo: 1 });
  }

  return (
    <Paper sx={{ borderRadius: '10px' }}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Document No</TableCell>
              <TableCell>Suborder ID</TableCell>
              <TableCell>Tax Invoice Doc</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Volume</TableCell>
              <TableCell>Net Price</TableCell>
              <TableCell>Date Created</TableCell>
              {/* <TableCell>Status</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceData.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  // '&:hover': { backgroundColor: 'rgba(99,26,25,0.27)' },
                }}
              // onClick={() => this.openModalWithOrder(row)}
              >
                <TableCell> {row.document_number} </TableCell>
                <TableCell>{row.suborder_id}</TableCell>
                <TableCell>{row.document_number}</TableCell>
                <TableCell>{row.client_name}</TableCell>
                <TableCell>{row.product_name}</TableCell>
                <TableCell>{fNumber(row?.volume)}</TableCell>
                <TableCell>{fNumber(row.total_price)}</TableCell>
                <TableCell>{moment.utc(row?.created_at)?.format('YYYY/MM/DD - HH:mm')}</TableCell>
                {/* <TableCell>{(() => {
                  switch (row.status) {
                    case 'paid': return (
                      <Tooltip title="Paid">
                        <CheckCircleIcon color='success' />
                      </Tooltip>
                    )
                    case 'unpaid': return (
                      <Tooltip title="Unpaid">
                        <CancelIcon color='error' />
                      </Tooltip>
                    )
                    default: return (
                      <Tooltip title="Other">
                        <ErrorIcon color='warning' />
                      </Tooltip>
                    )
                  }
                })()}
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomTablePagination
        recordsList={[5, 10, 15, 20]}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleNextPage={handlePageChange}
        currentPage={page}
        totalPages={totalPages}
      />
    </Paper>
  );
}
