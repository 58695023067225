import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import {
  Button,
  FormControl,
  Typography,
  MenuItem,
  TableCell,
  TableRow,
  Paper,
  TableSortLabel,
  IconButton,
  Card,
  CardContent,
  Tooltip,
} from '@mui/material';
import { Visibility, Info, Home, LocalShipping, AccessTime, Beenhere, BusAlert, DirectionsBus, DepartureBoard } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { CloudArrowUpFill, Eye, EyeSlash, FileArrowUpFill, Pencil, PencilSquare, TrashFill } from 'react-bootstrap-icons';

import API from '../../data/api';
// import EditModal from './modals/EditModal';
// import GlobalFilter from '../global-filter/GlobalFilter';
import CustomTextField from '../custom/CustomTextField';
import CustomTablePagination from '../custom/CustomTablePagination';
import palette from '../../theme/palette';
import CustomModal from '../custom/CustomModal';
import { extractFormValues, isNotNullValue, isNullValue, validateForm } from '../../utils/formUtil';
import { MODAL_WIDTH } from '../../utils/constants';
import { isAuthorizedRole } from 'src/utils/authUtil';
import FinanceDocumentUpload from './modals/FinanceDocumentUpload';
import { fNumber } from '../../utils/formatNumber';
import { CustomSelect } from '../custom/CustomSelect';
import LogisticsAllocation from './modals/LogisticsAllocation';
import LogisticsApproval from './modals/LogisticsApproval';
import SupplierDocumentUpload from './modals/SupplierDocumentUpload';
import OrderNomination from './OrderNomination';

const initNewOrder = { 
  volume: { value: '', required: true, error: false, errorMessage: 'Please provide volume' },
  destination: { value: '', required: true, error: false, errorMessage: 'Please provide destination' },
  deliveryDate: { value: '', required: true, error: false, errorMessage: 'Please provide delivery date' },
};

const initDocument = {
  fileObjects: [],
  filePreviewUrl: null,
  isFilePreview: false,
  modalStyle: {},
  type: { value: '', required: true, error: false, errorMessage: 'Please specify a document type', },
  openModal: null,
}

class ApprovedOrders extends React.Component {

  static async fetchAllocatedSubOrders(orderItemId) {
    try {
      const response = await API.get(`inventory/backoffice/get_allocated_suborders/${orderItemId}`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'api-secret-key': 's3cr3t',
        },
      });

      if (response.status === 200) {
        const data = await response.data;
        return data;
      }
    } catch (error) {
      console.error('Error fetching allocated suborders:', error);
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      approvedOrdersList: [],
      subOrders: {},
      orderDocuments: [],
      expandedRow: null,
      page: 1,
      records: 15,
      totalRecords: 1,
      totalPages: null,
      recordsList: [5, 10, 15, 20],
      currentSubOrder: null,
      warningOrders: [],
      pdfModalOpen: false,
      pdfUrl: '',
      filterString: '',
      sortField: 'order_item_id',
      sortDirection: 'asc',
      createModalOpen: false,
      newOrder: { ...initNewOrder },
      selectedOrderItemId: null,
      addresses: [],
      selectedOrder: null,
      suppliers: [],
      // depots: [],
      transportCompanies: [],
      selectedLogisticsSubOrderId: null,
      isClientView: true,
      allocatedSubOrders: [],
      qrCodeModalOpen: false,
      qrCodeUrl: '', // Add this state to handle the QR code URL
      deleteSubOrder: false,
      submitted: false,
      refDeliveryDate: React.createRef(),
      processedOrder: {
        openModal: false,
        orderItem: null,
        isTaxInvoice: true,
      },
      openAllocateLogistics: false,
      openApproveLogistics: false,
      uploadDocument: { ...initDocument },
      openUploadDocument: false,
      openOrderNomination: false,
    };

    this.handleDownload = this.handleDownload.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
  }

  componentDidMount() {
    this.getApprovedOrders(this.props.filterString);
    this.getTransportCompanies();
    // this.getDepots();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filterString !== this.props.filterString || prevState.filterString !== this.state.filterString) {
      this.getApprovedOrders(this.props.filterString);
      this.getTransportCompanies();
      // this.getDepots();
    }
  }

  handleDownload = () => {
    const { qrCodeUrl } = this.state;
    const link = document.createElement('a');
    link.href = qrCodeUrl;
    link.download = 'QR_Code.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handlePrint = () => {
    const { qrCodeUrl } = this.state;
    const newWindow = window.open('', '_blank');
    newWindow.document.write(`<img src="${qrCodeUrl}" onload="window.print();window.close()" />`);
    newWindow.document.close();
  };
  
  getApprovedOrders = async (finalQuery = '') => {
    const { records, page, sortField, sortDirection } = this.state;
    const userInfo = localStorage.getItem('user_info'); // get localstorage user data

    console.log(`Records = ${records}`);
    let conTemp = `inventory/backoffice/approved_order_items?records=${records}&page=${page}&sortField=${sortField}&sortDirection=${sortDirection}`;

    if (finalQuery) {
      conTemp += finalQuery;
    }

    try {
      const res = await API.get(conTemp, {
        headers: {
          'user_info': userInfo,  // Custom header for user_info
        }
      });

      if (res.status === 200) {
        const { orders } = res.data;
        this.setState({
          approvedOrdersList: orders,
          totalPages: Math.min(res.data.total_pages, Math.ceil(orders?.length/this.state.records)),
          totalRecords: res.data.total_orders,
        });

        // const warningOrdersPromises = orders?.map(async (order) => {
        //   const subOrdersRes = await API.get(`inventory/backoffice/suborders/${order.order_item_id}`);
        //   if (subOrdersRes.status === 200) {
        //     const { suborders } = subOrdersRes.data;
        //     const needsAttention = suborders?.some((subRow) => !subRow.assigned || !subRow.approved || !subRow.transport_approved_at);
        //     return needsAttention ? order.order_item_id : null;
        //   }
        //   return null;
        // });

        // const warningOrdersResults = await Promise.all(warningOrdersPromises);
        // const warningOrders = warningOrdersResults.filter((orderItemId) => orderItemId !== null);

        // this.setState({ warningOrders: warningOrders });
      } else {
        console.error('Error fetching approved orders:', res.status);
      }
    } catch (error) {
      console.error('Error fetching approved orders:', error);
    }
  };

  getAddresses = async (companyId) => {
    try {
      if (!companyId) {
        console.error('Company ID is undefined');
        return;
      }

      const response = await API.get(`inventory/backoffice/addresses/${companyId}`);
      if (response.status === 200) {
        this.setState({ addresses: response.data });
      } else {
        console.error('Error fetching addresses:', response.status);
      }
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

  getSubOrders = async (orderItemId) => {
    try {
      const response = await API.get(`inventory/backoffice/suborders/${orderItemId}`);
      if (response.status === 200) {
        const { suborders } = response.data;
        const { documents } = response.data;

        this.setState((prevState) => ({
          subOrders: {
            ...prevState.subOrders,
            [orderItemId]: suborders,
          },
          orderDocuments: documents || [],
        }));
        console.log("suborders ", response)
      } else {
        console.error('Error fetching suborders:', response.status);
      }
    } catch (error) {
      console.error('Error fetching suborders:', error);
    }
  };

  getSuppliers = async (order = this.state.currentSubOrder) => {
    const productID = order?.product_id;
    const volume = order?.volume;
    if (isNullValue(order) || isNullValue(volume) || isNullValue(productID)) {
      return;
    }

    try {
      const response = await API.get(`inventory/backoffice/valid_suppliers?product_id=${productID}&current_volume=${volume}`);
      if (response.status === 200) {
        this.setState({ suppliers: response.data });
      } else {
        console.error('Error fetching suppliers: ', response.status);
      }
    } catch (error) {
      console.error('Error fetching suppliers: ', error);
    }
  };

  getTransportCompanies = async () => {
    try {
      const response = await API.get(`inventory/backoffice/logistics`);
      if (response.status === 200) {
        this.setState({ transportCompanies: response.data?.clients });
      } else {
        console.error('Error fetching transport companies: ', response.status);
      }
    } catch (error) {
      console.error('Error fetching transport companies: ', error);
    }
  };

  getDepots = async () => {
    try {
      const response = await API.get('inventory/backoffice/depots?depot_type=storage');
      if (response.status === 200) {
        this.setState({ depots: response.data.depots });
      } else {
        console.error('Error fetching depots: ', response.status);
      }
    } catch (error) {
      console.error('Error fetching depots: ', error);
    }
  };

  setSubOrders = async (orderItemId) => {
    if (this.state.expandedRow === orderItemId) {
      this.setState({ expandedRow: null });
    } else {
      this.setState({ expandedRow: orderItemId });
      this.getSubOrders(orderItemId);
    }
  };

  handleAllocateLogistics = (subOrder = null) => {

    if (isNotNullValue(subOrder)) {
      const action = (subOrder?.supplier_id && subOrder?.transport_id) ? 'Update ' : 'New';
      this.getSuppliers(subOrder);
      this.setState({
        allocationTitle: `${action || ''} Logistics Allocation - Order ${subOrder?.order_item_id || ''}`,
        openAllocateLogistics: true,
        currentSubOrder: subOrder,
      });
    } else {
      this.setState({
        openAllocateLogistics: false,
        currentSubOrder: null,
        allocationTitle: '',
      });
    }
  };

  handleAllocationCallback = () => {
    this.handleAllocateLogistics(null);
    this.getSubOrders(this.state.expandedRow);
    this.props.setUpdateStats(true);
  };

  handleApprovalCallback = (isApproved = false) => {
    this.handleAllocationCallback();
    this.getApprovedOrders();
  };

  handleOpenPdfModal = async (subOrderId) => {
    try {
      const response = await API.get(`sharedfiles/burs_document_modal_view?suborder_id=${subOrderId}`);

      if (response.status === 200) {
        const { file_url: pdfUrl } = response.data;
        this.setState({
          pdfModalOpen: true,
          pdfUrl,
        });
      } else {
        console.error('Error fetching PDF:', response.status);
      }
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  handleClosePdfModal = () => {
    this.setState({
      pdfModalOpen: false,
      pdfUrl: '',
    });
  };

  handleFilterChange = (filterString) => {
    this.setState({ filterString: filterString });
  };

  handleSort = (field) => {
    const { sortField, sortDirection } = this.state;
    let newDirection = 'asc';
    if (sortField === field && sortDirection === 'asc') {
      newDirection = 'desc';
    }
    this.setState(
      {
        sortField: field,
        sortDirection: newDirection,
      },
      () => {
        this.getApprovedOrders(this.props.filterString);
      }
    );
  };

  handleOpenCreateModal = async (orderItemId) => {
    try {
      const response = await API.get(`inventory/backoffice/get_remaining_volume/${orderItemId}`);
      if (response.status === 200) {
        const {
          order_item_id: orderItemId,
          company_id: companyId,
          company_name: companyName,
          total_ordered_volume: totalVolume,
          remaining_volume: remainingVolume
        } = response.data;

        this.setState({
          createModalOpen: true,
          selectedOrderItemId: orderItemId,
          selectedOrder: {
            orderItemId,
            companyId,
            companyName,
            totalVolume,
            remainingVolume,
          },
          newOrder: {
            ...initNewOrder,
            volume: {
              ...initNewOrder['volume'],
              errorMessage: `Please provide volume not more than ${remainingVolume}`
            }
          },
        }, () => {
          this.getAddresses(companyId);
        });
      } else {
        console.error('Error fetching order data:', response.status);
      }
    } catch (error) {
      console.error('Error fetching order data:', error);
    }
  };

  handleCloseCreateModal = () => {
    this.setState({
      createModalOpen: false,
      selectedOrderItemId: null,
      newOrder: { ...initNewOrder },
      addresses: [],
      selectedOrder: null,
    });
  };

  toggleInvoiceUploadModal = ({order=null, updateStats=false, isOpen=false, isTaxInvoice=true}) => {
    console.log(`toggle Invoice Upload Modal`, order);
    if(updateStats){
      this.getSubOrders(this.state.selectedOrderItemId)
    }

    this.setState({
      processedOrder: {
        openModal: isOpen,
        orderItem: order,
        isTaxInvoice: isTaxInvoice,
      },
      selectedOrderItemId: order?.order_item_id,
    });
  };

  setValidatedNewOrder = (newOrder) =>{
    this.setState({
      newOrder: { ...newOrder },
    });
  }

  handleCreateSubmit = async () => {
    // If any of the inputs is empty, return
    if (!validateForm(this.setValidatedNewOrder, this.state.newOrder)) {
      return;
    }

    const { volume, destination, deliveryDate } = extractFormValues(this.state.newOrder);
    const { selectedOrderItemId } = this.state;
    const endpoint = `inventory/public_facing/suborder/${selectedOrderItemId}/${volume}/${destination}/${moment(deliveryDate).format('YYYY-MM-DD HH:mm')}`;

    try {
      const response = await API.post(endpoint, '');

      if (response.status === 201) {
        this.handleCloseCreateModal();
        this.getApprovedOrders(this.props.filterString);
        this.getSubOrders(selectedOrderItemId);
        this.props.setUpdateStats(true);
      } else {
        console.error('Error creating suborder:', response.status);
      }
    } catch (error) {
      console.error('Error creating suborder:', error);
    }
  };

  handleInputChange = (field, value, error = false) => {
    this.setState((prevState) => ({
      newOrder: {
        ...prevState.newOrder,
        [field]: {
          ...prevState.newOrder[field],
          value: value,
          error: isNullValue(value) || error,
        } ,
      },
    }));
  };

  handleApproveLogistics = (subOrder = null) => {

    if (isNotNullValue(subOrder)) {
      this.getSuppliers(subOrder);
      this.setState({
        openApproveLogistics: true,
        currentSubOrder: subOrder,
      });
    } else {
      this.setState({
        openApproveLogistics: false,
        currentSubOrder: null,
      });
    }
  };

  handleLogisticsApprovalCallback = async () => {
    this.handleApproveLogistics(null);
    this.getApprovedOrders(this.props.filterString);
    this.getSubOrders(this.state.expandedRow);
  };

  // Step-1: On click delete subOrder
  handleDeleteSubOrder = async (subOrder) => {
    this.setState({
      currentSubOrder: subOrder,
      deleteSubOrder: true,
    });
  }

  // Step-2a: On click cancel subOrder delete
  cancelDeleteSubOrder = async () => {
    this.setState({
      currentSubOrder: null,
      deleteSubOrder: false,
    });
  }

  // Step-2b: On click confirm subOrder delete
  submitDeleteSubOrder = async () => {
    const subOrderId = this.state.currentSubOrder?.suborder_id;
    const endpoint = `inventory/backoffice/delete_suborder/${subOrderId}`;
    try {
      const response = await API.delete(endpoint);
      if (response.status === 200) {
        this.getSubOrders(this.state.expandedRow);
        this.props.setUpdateStats(true);
        this.cancelDeleteSubOrder(); // just to clear the state
      } else {
        console.error('Error deleting suborder:', response.status);
      }
    } catch (error) {
      console.error('Error deleting suborder:', error);
    }
  };

  handleViewClick = async (isClient, orderItemId) => {
    
    if (!isClient) {
      const allocatedSubOrders = await this.constructor.fetchAllocatedSubOrders(orderItemId);
      if (allocatedSubOrders) {
        console.log('Setting allocatedSubOrders:', allocatedSubOrders);
        this.setState({
          allocatedSubOrders,
          isClientView: false,
          expandedRow: orderItemId
        });
      }
    } else {
      console.log('Switching back to main view');
      this.setState({
        isClientView: true,
        expandedRow: null,
      });
    }
  };

  handleDetailsModal = async (orderItemId) => {
    try {
      const response = await API.get(`/inventory/backoffice/supplier_suborders_modal/${orderItemId}`);
      if (response.status === 200) {
        this.setState({
          selectedSubOrderDetail: response.data,
          viewModalOpen: true
        });
      } else {
        console.error('Error fetching suborder details:', response.status);
      }
    } catch (error) {
      console.error('Error fetching suborder details:', error);
    }
  };

  handleCloseViewModal = () => {
    this.setState({
      viewModalOpen: false,
      selectedSubOrderDetail: null
    });
  };

  handleCloseQrCode = () => {
    this.setState({
      qrCodeModalOpen: false,
      qrCodeUrl: ''
    })
  }

  handleUploadDocument = (subOrder, isOpen) => {

    this.setState({
      currentSubOrder: subOrder,
      uploadDocument: { ...initDocument },
      openUploadDocument: isOpen,
    });
  }

  handleOpenOrderNomination = (subOrder = null) => {
    this.setState({
      openOrderNomination: isNotNullValue(subOrder),
      currentSubOrder: subOrder,
    });

  };

  renderModalContent = () => {
    const { selectedSubOrderDetail } = this.state;
    if (!selectedSubOrderDetail) return null;

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Card sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
          <Info sx={{ mr: 2, color: 'primary.main' }} />
          <Typography variant="body2">ID: {selectedSubOrderDetail.id}</Typography>
        </Card>
        <Card sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
          <Info sx={{ mr: 2, color: 'primary.main' }} />
          <Typography variant="body2">Order Item ID: {selectedSubOrderDetail.order_item_id}</Typography>
        </Card>
        <Card sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
          <Home sx={{ mr: 2, color: 'primary.main' }} />
          <Typography variant="body2">Destination Address ID: {selectedSubOrderDetail.destination_address_id}</Typography>
        </Card>
        <Card sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
          <Home sx={{ mr: 2, color: 'secondary.main' }} />
          <Typography variant="body2">Destination: {selectedSubOrderDetail.destination}</Typography>
        </Card>
        <Card sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
          <LocalShipping sx={{ mr: 2, color: 'success.main' }} />
          <Typography variant="body2">Volume: {fNumber(selectedSubOrderDetail.volume)}</Typography>
        </Card>
        <Card sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
          <AccessTime sx={{ mr: 2, color: 'warning.main' }} />
          <Typography variant="body2">Created At: {moment(selectedSubOrderDetail.created_at).format('YYYY/MM/DD - HH:mm:ss')}</Typography>
        </Card>
        <Card sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
          <LocalShipping sx={{ mr: 2, color: selectedSubOrderDetail.transport_approved_at ? 'success.main' : 'error.main' }} />
          <Typography variant="body2">Transport Approved: {selectedSubOrderDetail.transport_approved_at ? 'Yes' : 'No'}</Typography>
        </Card>
      </Box>
    );
  };

  nextPage(action) {
    const limit = this.state.totalPages;
    this.setState(
      (prevState) => {
        let currentPage = prevState.page;

        if (action === 'next') {
          currentPage = Math.min(currentPage + 1, limit);
        } else {
          currentPage = Math.max(currentPage - 1, 1);
        }

        return { page: currentPage };
      },
      () => {
        this.getApprovedOrders(this.props.filterString);
      }
    );
  }

  handleChangeRowsPerPage = (event) => {
    this.setState(() => {
      return { records: parseInt(event.target.value, 10), page: 1 };
    }, () => {
      this.getApprovedOrders(this.props.filterString);
    });
  }

  render() {
    const { sortField, sortDirection, createModalOpen, newOrder, addresses, selectedOrder, allocatedSubOrders, viewModalOpen } = this.state;
    const isRemainingVolumeZero = selectedOrder && selectedOrder.remainingVolume <= 0;

    return (
      <Paper>
        {/* <GlobalFilter sx={{ my: 1 }} onFilterChange={this.handleFilterChange} /> */}
        <CustomModal
          openModal={this.state.deleteSubOrder}
          handleCloseModal={this.cancelDeleteSubOrder}
          modalTitle={`Delete SubOrder ${this.state.currentSubOrder?.suborder_id} (${this.state.currentSubOrder?.fuel_type})`}
          modalStyle={{
            width: { ...MODAL_WIDTH.small },
          }}
          modalIcon='delete'
        >
          <Typography color='error' sx={{ display: 'flex', justifyContent: 'center', my: 3 , }}>
            Deleting this suborder will permanentely remove it from the order list, as this action cannot be undone.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
            <Button
              sx={{ width: '47%' }}
              variant='contained'
              color="inherit"
              onClick={this.cancelDeleteSubOrder}
            >
              Cancel
            </Button>
            <LoadingButton
              sx={{ ml: 2, width: '47%' }}
              variant="contained"
              loading={this.state.submitted}
              onClick={this.submitDeleteSubOrder}
            >
              Delete
            </LoadingButton>
          </Box>
        </CustomModal>
        {/* <ConfirmDialog
          open={this.state.deleteSubOrder}
          title={"SubOrder Delete Confirmation"}
          onConfirm={this.submitDeleteSubOrder}
          onCancel={this.cancelDeleteSubOrder}
        >
          Are you sure you want to delete subOrder {this.state.currentSubOrder?.suborder_id}
          &nbsp;({this.state.currentSubOrder?.fuel_type})
        </ConfirmDialog> */}

          <TableContainer sx={{ mt: 2, width: '100%', overflowX: { xs: 'scroll', sm: 'auto' } }} >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {this.state.warningOrders.length > 0 && <span style={{ color: 'red' }}>ℹ️</span>}
                  </TableCell>
                  <TableCell sortDirection={sortField === 'order_item_id' ? sortDirection : false}>
                    <TableSortLabel
                      active={sortField === 'order_item_id'}
                      direction={sortField === 'order_item_id' ? sortDirection : 'asc'}
                      onClick={() => this.handleSort('order_item_id')}
                    >
                      Order Item ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={sortField === 'client_name' ? sortDirection : false}>
                    <TableSortLabel
                      active={sortField === 'client_name'}
                      direction={sortField === 'client_name' ? sortDirection : 'asc'}
                      onClick={() => this.handleSort('client_name')}
                    >
                      Client Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={sortField === 'product' ? sortDirection : false}>
                    <TableSortLabel
                      active={sortField === 'product'}
                      direction={sortField === 'product' ? sortDirection : 'asc'}
                      onClick={() => this.handleSort('product')}
                    >
                      Product
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={sortField === 'volume' ? sortDirection : false}>
                    <TableSortLabel
                      active={sortField === 'volume'}
                      direction={sortField === 'volume' ? sortDirection : 'asc'}
                      onClick={() => this.handleSort('volume')}
                    >
                      Volume
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={sortField === 'created_date' ? sortDirection : false}>
                    <TableSortLabel
                      active={sortField === 'created_date'}
                      direction={sortField === 'created_date' ? sortDirection : 'asc'}
                      onClick={() => this.handleSort('created_date')}
                    >
                      Created Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.approvedOrdersList?.map((row) => (
                  <React.Fragment key={row.order_item_id}>
                    <TableRow
                      sx={{
                        backgroundColor: row.is_client ? 'transparent' : 'lightyellow',
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:hover': { backgroundColor: 'rgba(39,226,245,0.27)' },
                      }}
                      selected={this.state.expandedRow === row.order_item_id}
                    >
                      <TableCell component="th" scope="row">
                        {this.state.warningOrders.includes(row.order_item_id) && <span style={{ color: 'red' }}>⚠️</span>}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.order_item_id}
                      </TableCell>
                      <TableCell>{row.company_name}</TableCell>
                      <TableCell>{row.fuel_type}</TableCell>
                      <TableCell>{fNumber(row.volume)}</TableCell>
                      <TableCell>
                        {moment(row.created_date).format('YYYY/MM/DD - HH:mm:ss')}
                      </TableCell>
                      <TableCell align="right">
                        {row.is_client? (
                          <>
                            {this.state.expandedRow === row.order_item_id ? (
                              <IconButton onClick={() => this.setSubOrders(row.order_item_id)}>
                                <EyeSlash color={palette.info.main} />
                              </IconButton>
                            ) : (
                              <IconButton onClick={() => this.setSubOrders(row.order_item_id)}>
                                <Eye color={palette.info.main} />
                              </IconButton>
                            )}
                            {!isAuthorizedRole(["FINANCE-L1"]) ? (
                              <>
                                <IconButton onClick={() => this.handleOpenCreateModal(row.order_item_id)}>
                                  <PencilSquare color={palette.info.main} />
                                </IconButton>
                              </>
                            ):(
                              <>
                                <Tooltip title="Tax Invoice">
                                  <IconButton onClick={() => this.toggleInvoiceUploadModal({ order: row, isOpen: true })}>
                                    <CloudArrowUpFill color={palette.info.main} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delivery Note">
                                  <IconButton onClick={() => this.toggleInvoiceUploadModal({ order: row, isOpen: true, isTaxInvoice: false })}>
                                    <FileArrowUpFill color={palette.success.main} />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Tooltip title="Upload Document">
                              <IconButton onClick={() => this.handleUploadDocument(row, true)}>
                                <FileArrowUpFill color={palette.success.main} />
                              </IconButton>
                            </Tooltip>
                            <Button
                              onClick={() => {
                                this.handleViewClick(false, row.order_item_id);
                              }}
                              disabled={row.allocated_volume === 0}
                            >
                              SubOrders
                            </Button>

                          </>
                        )}
                      </TableCell>
                    </TableRow>
                    {(this.state.expandedRow === row.order_item_id && isAuthorizedRole(["FINANCE-L1"]))&& (
                      <TableRow
                        selected={this.state.expandedRow === row.order_item_id}
                      >
                        <TableCell
                          colSpan={9}
                        >
                        <TableContainer sx={{ mt: 2, width: '100%', overflowX: { xs: 'scroll', sm: 'auto',} }} >
                            <Table size="small" aria-label="suborders">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Document Name</TableCell>
                                  <TableCell>Document Number</TableCell>
                                  <TableCell>Date Time</TableCell>
                                  <TableCell>Net Price</TableCell>
                                  <TableCell align="right">Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.orderDocuments?.map((docRow) => (
                                  <TableRow
                                    key={docRow?.document_id}
                                    sx={{ backgroundColor: '#FFFAFA'}}
                                    selected={this.state.currentSubOrder === docRow.suborder_id}
                                  >
                                    <TableCell>{docRow.file_name}</TableCell>
                                    <TableCell>{docRow.document_number}</TableCell>
                                    <TableCell>
                                      {moment(docRow?.created_datetime|| null).format('YYYY/MM/DD - HH:mm:ss')}
                                    </TableCell>
                                    <TableCell>{docRow?.net_price}</TableCell>
                                    <TableCell align='right'>
                                      <IconButton onClick={() => console.log('view', docRow)}>
                                        <EyeSlash color={palette.info.main} />
                                      </IconButton>
                                      <IconButton onClick={() => console.log('edit', docRow)}>
                                        <Pencil color={palette.info.main} />
                                      </IconButton>
                                      <IconButton onClick={() => console.log('delete', docRow)}>
                                        <TrashFill color={palette.error.main} />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </TableCell>
                      </TableRow>
                    )}
                    {(this.state.expandedRow === row.order_item_id && row.is_client && !isAuthorizedRole(["FINANCE-L1"])) && (
                      <TableRow
                        selected={this.state.expandedRow === row.order_item_id}
                      >
                        <TableCell colSpan={7}
                        >
                        <TableContainer sx={{ mt: 2, width: '100%', overflowX: { xs: 'scroll', sm: 'auto',} }} >
                            <Table size="small" aria-label="suborders">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Volume</TableCell>
                                  <TableCell>Destination</TableCell>
                                  <TableCell>Delivery Date</TableCell>
                                  {/* <TableCell>Burs</TableCell> */}
                                  <TableCell>Logistics Approved</TableCell>
                                  <TableCell>Transporter Assigned</TableCell>
                                  <TableCell>Tracking</TableCell>
                                  <TableCell align="right">Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.subOrders[row.order_item_id]?.map((subRow) => (
                                  <TableRow
                                    key={subRow.suborder_id}
                                    sx={{ backgroundColor: '#FFFAFA'}}
                                    selected={this.state.currentSubOrder === subRow.suborder_id}
                                  >
                                    <TableCell>{fNumber(subRow.volume)}</TableCell>
                                    <TableCell>{subRow.delivery_location}</TableCell>
                                    <TableCell>
                                      {moment(subRow.delivery_scheduled_datetime).format('YYYY/MM/DD - HH:mm:ss')}
                                    </TableCell>
                                    {/* <TableCell>
                                      <Button
                                        variant="contained"
                                        color={subRow.approved ? 'success' : 'error'}
                                        onClick={() => this.handleOpenPdfModal(subRow.suborder_id)}
                                        disabled={!subRow.approved}
                                        sx={{ color: 'white', padding: '3px 8px', borderRadius: '3px' }}
                                      >
                                        {subRow.approved ? 'received' : 'pending'}
                                      </Button>
                                    </TableCell> */}
                                    <TableCell>
                                      <span
                                        style={{
                                          backgroundColor: `${subRow.transport_approved_at ? palette.success.main : palette.error.main}`,
                                          color: 'white',
                                          padding: '5px 10px',
                                          borderRadius: '5px',
                                        }}
                                      >
                                        {subRow.transport_approved_at ? 'approved' : 'pending'}
                                      </span>
                                    </TableCell>
                                    <TableCell>
                                      <span
                                        style={{
                                          backgroundColor: `${subRow.assigned ?  palette.grey[500]: palette.warning.main}`,
                                          color: 'white',
                                          padding: '5px 10px',
                                          borderRadius: '5px',
                                        }}
                                      >
                                        {subRow.assigned ? 'assigned' : 'pending'}
                                      </span>
                                    </TableCell>
                                    <TableCell>
                                      <span
                                        style={{
                                          backgroundColor: 'lightblue',
                                          color: 'white',
                                          padding: '5px 10px',
                                          borderRadius: '5px',
                                        }}
                                      >
                                        {subRow.status}
                                      </span>
                                    </TableCell>
                                    <TableCell align="right" >

                                      {(isAuthorizedRole(["CUSTOMS-L1"]) && subRow.approved && !subRow?.is_nominated) && (
                                        <Tooltip title="Nominate">
                                          <IconButton
                                            onClick={() => this.handleOpenOrderNomination(subRow)}
                                          >
                                            <DepartureBoard color='success' />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                      {(!subRow.approved && !subRow.assigned && !subRow.supplier_id) && (
                                        <Tooltip title={"New Allocation"}>
                                          <IconButton
                                            onClick={() => this.handleAllocateLogistics(subRow)}
                                          >
                                            <DirectionsBus color={'inherit'} />
                                          </IconButton>
                                        </Tooltip>
                                      )}

                                      {(!subRow.approved && (subRow.assigned || subRow.supplier_id)) && (
                                        <Tooltip title={"Update Allocation"}>
                                          <IconButton
                                            onClick={() => this.handleAllocateLogistics(subRow)}
                                          >
                                            <BusAlert color={'info'} />
                                          </IconButton>
                                        </Tooltip>
                                      )}

                                      {(!subRow.approved && subRow.assigned) && (
                                        <Tooltip title={"Approve Allocation"}>
                                          <IconButton
                                            onClick={() => this.handleApproveLogistics(subRow)}
                                          >
                                            <Beenhere color={'action'} />
                                          </IconButton>
                                        </Tooltip>
                                      )}

                                      {(!subRow.approved && !(subRow.assigned || subRow.supplier_id)) && (
                                        <Tooltip title={"Delete SubOrder"}>
                                          <IconButton onClick={() => this.handleDeleteSubOrder(subRow)}>
                                            <TrashFill color={palette.error.main} />
                                          </IconButton>
                                        </Tooltip>
                                      )}

                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </TableCell>
                      </TableRow>
                    )}
                    {(this.state.expandedRow === row.order_item_id && !row.is_client && !isAuthorizedRole(["FINANCE-L1"])) && (
                      <TableRow
                        selected={this.state.expandedRow === row.order_item_id}
                      >
                        <TableCell colSpan={9}
                        >
                          <TableContainer sx={{ mt: 2, width: '100%', overflowX: { xs: 'scroll', sm: 'auto' } }} >
                            <Table aria-label="client table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Client Suborder ID</TableCell>
                                  <TableCell>Client</TableCell>
                                  <TableCell>Product</TableCell>
                                  <TableCell>Allocated Volume</TableCell>
                                  <TableCell>Allocated On</TableCell>
                                  <TableCell align="right">Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {allocatedSubOrders?.map((subOrder) => (
                                  <TableRow key={'supplier-suborder-' + subOrder.suborder_id}
                                    sx={{ backgroundColor: '#FFFAFA' }}
                                    selected={this.state.currentSubOrder === subOrder.suborder_id}
                                  >
                                    <TableCell component="th" scope="row">{subOrder.suborder_id}</TableCell>
                                    <TableCell>{subOrder.company_name}</TableCell>
                                    <TableCell>{subOrder.fuel_type}</TableCell>
                                    <TableCell>{fNumber(subOrder.volume)}</TableCell>
                                    <TableCell>{moment.utc(subOrder.created_at).format('YYYY/MM/DD - HH:mm:ss')}</TableCell>
                                    <TableCell align="right">
                                    {/* <IconButton onClick={() => this.handleViewClick(true)}>
                                        <ArrowBack />
                                      </IconButton> */}
                                      <Tooltip title={"View SubOrder"}>
                                        <IconButton onClick={() => this.handleDetailsModal(subOrder.suborder_id)}>
                                          <Visibility />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title={"Delete SubOrder"}>
                                        <IconButton onClick={() => this.handleDeleteSubOrder(subOrder)}>
                                          <TrashFill color={palette.error.main} />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        <CustomTablePagination
          rowsPerPage={this.state.records}
          recordsList={this.state.recordsList}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
          handleNextPage={this.nextPage.bind(this)}
          currentPage={this.state.page}
          totalPages={this.state.totalPages}
        />

        <LogisticsAllocation
          includeDepot={true}
          subOrder={this.state.currentSubOrder}
          suppliers={this.state.suppliers}
          transportCompanies={this.state.transportCompanies}
          depots={this.state.depots}
          openModal={this.state.openAllocateLogistics}
          handleCloseModal={() => this.handleAllocateLogistics(null)}
          handleAllocationCallback={this.handleAllocationCallback}
        />

        <CustomModal
          handleCloseModal={this.handleClosePdfModal}
          openModal={this.state.pdfModalOpen}
          modalTitle={'View Documentat'}
          modalIcon='document'
          modalStyle={{
            width: '80%',
            height: '80%',
          }}
        >
          <Box
            sx={{
              margin: 'auto',
              marginTop: '5%',
              backgroundColor: 'white',
              padding: '16px',
              borderRadius: '8px',
              overflow: 'hidden',
            }}
          >
            <iframe src={this.state.pdfUrl} width="100%" height="100%" title="PDF Document" />
          </Box>
        </CustomModal>

        <CustomModal
          openModal={createModalOpen}
          handleCloseModal={this.handleCloseCreateModal}
          modalTitle={'Create Suborder'}
        >
          <>
            {selectedOrder && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
                <Card sx={{ minWidth: 100, mx: 1, backgroundColor: 'orange', color: 'white' }}>
                  <CardContent>
                    <Typography variant="body2">
                      Total Ordered Volume
                    </Typography>
                    <Typography variant="h6">{selectedOrder.totalVolume}</Typography>
                  </CardContent>
                </Card>
                <Card sx={{ minWidth: 100, mx: 1, backgroundColor: 'lightblue', color: 'white' }}>
                  <CardContent>
                    <Typography variant="body2">
                      Order Item ID
                    </Typography>
                    <Typography variant="h6">{selectedOrder.orderItemId}</Typography>
                  </CardContent>
                </Card>
                <Card sx={{ minWidth: 100, mx: 1, backgroundColor: 'purple', color: 'white' }}>
                  <CardContent>
                    <Typography variant="body2">
                      Company Name
                    </Typography>
                    <Typography variant="h6">{selectedOrder.companyName}</Typography>
                  </CardContent>
                </Card>
                <Card sx={{ minWidth: 100, mx: 1, backgroundColor: 'black', color: 'white' }}>
                  <CardContent>
                    <Typography variant="body2" color={selectedOrder.remainingVolume > 0 ? 'white' : 'white'}>
                      Remaining Volume
                    </Typography>
                    <Typography variant="h6" color={selectedOrder.remainingVolume > 0 ? 'white' : 'white'}>
                      {selectedOrder.remainingVolume}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            )}
            <FormControl fullWidth sx={{ my: 2 }}>
              <CustomTextField
                label="Volume"
                variant="outlined"
                type="number"
                value={newOrder.volume.value}
                onChange={(e) => {
                  const value = e.target.value;
                  const error = (value < 1 || value > selectedOrder.remainingVolume);
                  this.handleInputChange('volume', value, error);
                }}
                required={newOrder.volume.required}
                error={newOrder.volume.error}
                helperText={newOrder.volume.error && newOrder.volume.errorMessage}
                disabled={isRemainingVolumeZero}
              />
            </FormControl>
            <FormControl fullWidth sx={{ my: 2 }}>
              {/* <InputLabel id="destination-select-label">Destination</InputLabel> */}
              <CustomSelect
                labelId="destination-select-label"
                id="destination-select"
                label="Destination"
                value={newOrder.destination.value}
                onChange={(e) => this.handleInputChange('destination', e.target.value)}
                required={newOrder.destination.required}
                error={newOrder.destination.error}
                helperText={newOrder.destination.error && newOrder.destination.errorMessage}
                disabled={isRemainingVolumeZero}
              >
                {addresses?.map((address) => (
                  <MenuItem key={address.id} value={address.id}>
                    {address.street_address ? `${address.street_address}, ${address.suburb}` : `${address.city}, ${address.country}`}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
            <FormControl fullWidth sx={{ my: 2 }}>
              <CustomTextField
                label="Delivery Date"
                type="datetime-local"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={this.state.refDeliveryDate}
                onClick={() => {
                  this.state.refDeliveryDate.current.showPicker()
                }}
                value={newOrder.deliveryDate.value}
                onChange={(e) => this.handleInputChange('deliveryDate', e.target.value)}
                required={newOrder.deliveryDate.required}
                error={newOrder.deliveryDate.error}
                helperText={newOrder.deliveryDate.error && newOrder.deliveryDate.errorMessage}
                disabled={isRemainingVolumeZero}
              />
            </FormControl>
            <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
              <Button
                variant='contained'
                color="inherit"
                onClick={this.handleCloseCreateModal}
              >
                Cancel
              </Button>
              <LoadingButton
                sx={{ ml: 2, }}
                variant="contained"
                loading={this.state.submitted}
                onClick={this.handleCreateSubmit} disabled={isRemainingVolumeZero}
              >
                Submit
              </LoadingButton>
            </Box>
          </>
        </CustomModal>

        <LogisticsApproval
          generateQRcode={true}
          subOrder={this.state.currentSubOrder}
          openModal={this.state.openApproveLogistics}
          suppliers={this.state.suppliers}
          transportCompanies={this.state.transportCompanies}
          handleCloseModal={() => this.handleApproveLogistics()}
          handleApprovalCallback={() => this.handleLogisticsApprovalCallback()}
        />

        <OrderNomination
          openModal={this.state.openOrderNomination}
          subOrder={this.state.currentSubOrder}
          transportCompanies={this.state.transportCompanies}
          handleCloseModal={() => this.handleOpenOrderNomination(null)}
          handleSumitCallback={() => {
            this.handleOpenOrderNomination(null);
            this.handleLogisticsApprovalCallback();
          }}
        />

{/*
        <CustomModal
          openModal={qrCodeModalOpen}
          handleCloseModal={this.handleCloseQrCode}
          modalTitle={'QR Code'}
          modalIcon='qrcode'
        >
          <>
            {qrCodeUrl && <img src={qrCodeUrl} alt="QR Code" style={{ margin: '16px 0' }} />}
            <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
              <Button
                color="disabled"
                variant='contained'
                onClick={() => { this.handleCloseQrCode() }}
              >
                Cancel
              </Button>
            </Box>
          </>
        </CustomModal>
*/}

        <CustomModal
          openModal={viewModalOpen}
          handleCloseModal={this.handleCloseViewModal}
          modalTitle={`Suborder ${this.state.selectedSubOrderDetail?.id || ''} Details`}
          modalIcon='document'
        >
          <Box sx={{ margin: 'auto', backgroundColor: 'white', padding: '16px', borderRadius: '8px', overflow: 'auto' }}>
            {this.renderModalContent()}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
              <Button
                variant='contained'
                color="inherit"
                onClick={this.handleCloseViewModal}
              >
                Cancel
              </Button>
            </Box>
        </CustomModal>

        <FinanceDocumentUpload
          handleCloseModal={this.toggleInvoiceUploadModal}
          processedOrder={this.state.processedOrder}
        />

        <SupplierDocumentUpload
          openModal={this.state.openUploadDocument}
          subOrder={this.state.currentSubOrder}
          handleCloseModal={()=> this.handleUploadDocument(null, false)}
          handleUploadCallback={()=> console.log()}
        />
      </Paper>
    );
  }
}

ApprovedOrders.propTypes = {
  filterString: PropTypes.string.isRequired,
  setUpdateStats: PropTypes.func.isRequired,
};

export default ApprovedOrders;
