import { Box, Select } from '@mui/material';

import { styled } from '@mui/material/styles';

export const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

export const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export const LogoImage = styled('img')(() => ({
  marginBottom: 5,
  width: 150,
  height: 100,
  alignItems: 'center',
  alignSelf: 'center',
}));

export const SupplierBox = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  minWidth: 150,
  marginRight: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.warning.main,
  color: theme.palette.warning.contrastText,
  borderRadius: theme.shape.borderRadius,
}));

export const AvailableBox = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  minWidth: 100,
  marginRight: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: '#ADD8E6',
  color: theme.palette.getContrastText('#ADD8E6'),
  borderRadius: theme.shape.borderRadius,
}));

export const AllocatedBox = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  minWidth: 100,
  marginRight: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: '#800080',
  color: theme.palette.getContrastText('#800080'),
  borderRadius: theme.shape.borderRadius,
}));

export const OrderBox = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  minWidth: 60,
  padding: theme.spacing(1),
  backgroundColor: '#FFD700',
  color: theme.palette.getContrastText('#FFD700'),
  borderRadius: theme.shape.borderRadius,
}));
