import * as React from "react"
import { useEffect } from "react"

import {
  Box,
  Card,
  CardContent,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material"

import LabeledProgressBar from "./LabeledProgressBar"
import { FUEL_PRODUCT_COLORS, PRODUCT_SEGMENTATION_FILTERS } from "../../../utils/constants"
import CustomTextField from "../../custom/CustomTextField"

export function ProductSegmentCard({ title, products, periodFilter, setPeriodFilter, sx={} }) {

  // const [period, setPeriod] = useState('');
  useEffect(() => {

  }, [title, products, periodFilter]);

  return (
    <Card sx={{ height: '100%', ...sx }}>
      <CardContent>

        <Stack
          direction="row"
          sx={{
            alignItems: "flex-start",
            justifyContent: "space-between",
            mb: 2
          }}
          spacing={1}
        >
          <Typography sx={{ mb: 1, }} variant="h5" >{title || "Product Segment Performance"}</Typography>

          <CustomTextField
            variant="standard"
            sx={{ minWidth: 120 }}
            select
            labelId="period-select-label"
            value={periodFilter}
            onChange={(e) => { setPeriodFilter(e.target.value) }}
          >
            <MenuItem value="" selected>
              <em>Select Period</em>
            </MenuItem>
            {PRODUCT_SEGMENTATION_FILTERS.map((p) => (
              <MenuItem key={p.key} value={p.key}>
                {p.value}
              </MenuItem>
            ))}
          </CustomTextField>
        </Stack>
        <Box
          direction="column"
          sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
          spacing={3}
        >
          <LabeledProgressBar
            sx={{ mt: 3 }}
            height={'55px'}
            value={products.ulp95?.value || 0}
            rightBar={products.ulp95.rightBar}
            leftBar={products.ulp95.leftBar}
          />
          <LabeledProgressBar
            sx={{ mt: 3 }}
            height={'55px'}
            value={products.ulp93?.value || 0}
            rightBar={products.ulp93.rightBar}
            leftBar={products.ulp93.leftBar}
          />
          <LabeledProgressBar
            sx={{ mt: 3 }}
            height={'55px'}
            value={products.ppm50?.value || 0}
            rightBar={products.ppm50.rightBar}
            leftBar={products.ppm50.leftBar}
          />
        </Box>
        <Stack
          direction="row"
          sx={{
            alignItems: "flex-start",
            justifyContent: "space-between",
            mt: 2
          }}
          spacing={1}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ bgcolor: FUEL_PRODUCT_COLORS.ppm50, height: '10px', width: '10px', borderRadius: '50%' }} variant="overline" />
            <Box sx={{ ml: 2, fontSize: 12 }}> 50 PPM </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ bgcolor: FUEL_PRODUCT_COLORS.upl93, height: '10px', width: '10px', borderRadius: '50%' }} variant="overline" />
            <Box sx={{ ml: 2, fontSize: 12 }}> ULP 93 </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ bgcolor: FUEL_PRODUCT_COLORS.upl95, height: '10px', width: '10px', borderRadius: '50%' }} variant="overline" />
            <Box sx={{ ml: 2, fontSize: 12 }}> ULP 95 </Box>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}
