
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { FilePdfFill } from 'react-bootstrap-icons';
import {
  AttachFile,
  Description,
} from "@mui/icons-material"

export const handlePreviewIcon = (fileObject) => {
  const { type } = fileObject
  const iconProps = {
  }

  switch (type) {
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return <Description {...iconProps} />
    case "application/pdf":
      return <FilePdfFill color='purple' />
    default:
      return <AttachFile {...iconProps} />
  }
}

/**
 * Helper function to download a file, without a progress indicator
 * 
 * @param {string} fileUrl - The url to download the file
 * @param {string} filename - The name of the file to download
 */
export const downloadFile = (fileUrl, filename) => {
  fetch(fileUrl).then((response) => {
    response.blob().then((blob) => {

      // Creating new object of PDF file
      const blobUrl = URL.createObjectURL(blob);

      // Setting various property values
      const alink = document.createElement("a");
      alink.href = blobUrl;
      alink.download = filename;
      alink.click();
      URL.revokeObjectURL(blobUrl);

    });
  });
};
