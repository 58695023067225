import * as React from "react"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid2"

import palette from "../../../theme/palette"
import { scaleNumber } from "../../../utils/formatNumber"

export function ComparativeCard({ data, sx = {} }) {

  return (
    <Card sx={{ height: '100%', ...sx }}>
      <CardContent>
        <Stack
          direction="row"
          sx={{ alignItems: "center", justifyContent: "space-between" }}
          spacing={3}
        >
          <Stack
            spacing={1}
            sx={{
              borderLeft: `5px solid #177181`,
              pl: 2,
              pt: 0,
              height: "50%",
              width: "100%",
            }}
          >
            <Typography variant="h6">
              {data.type}
            </Typography>
            <Grid
              container
              spacing={3}
              sx={{
                mt: 2,
                padding: 1,
                borderRadius: "5px"
              }}
            >
              {
                data?.metadata?.map(md => (
                  <Grid
                    size={{ xs: 12 / (data?.metadata?.length || 1) }}
                    sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                    // fontSize="12px"
                    // fontWeight="normal"
                  >
                    <>
                    <Typography sx={{ color: palette.navy[500] }} variant="inherit">
                      {md.type}
                    </Typography>
                    <Typography sx={{ color: 'black' }} variant="inherit">
                    {scaleNumber(md?.value)}
                    </Typography>
                    </>
                  </Grid>
                ))
              }
            </Grid>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
