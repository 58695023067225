import React from 'react';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TableContainer,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ClearIcon from '@mui/icons-material/Clear';
import CircleIcon from '@mui/icons-material/Circle';

import PropTypes from 'prop-types';
import API from '../../data/api';
import CustomTablePagination from '../custom/CustomTablePagination';
import CustomPdfViewer from '../custom/CustomPdfViewer';
import { isNotNullValue } from '../dashboard/dash-util';
import { isAuthorizedRole } from '../../utils/authUtil';
import { OrderCards } from './modals/OrderCards';
import { HEX_OPACITY } from 'src/utils/constants';
import { CheckAll } from 'react-bootstrap-icons';
import FileDragzone from '../ui/FileDragzone';
import CustomModal from '../custom/CustomModal';

const approvalTitle = 'Approve Order';
const viewDocumentTitle = 'View PFI/POP Document';
const uploadTitle = 'Upload a PFI/POP Document?';
const filePreviewStyle = {
  minWidth: {
    xs: '50%',
  },
  zIndex: 500,
  maxHeight: '75%',
  overflowX: 'hidden',
  overflowY: 'scroll'
}

const FormItemlGrid = ({label, value, disabled, children}) => {
  return (
    <Grid container spacing={3} sx={{ mt: 1 }}>
      <Grid size={{ xs: 3 }} key='status' sx={{ my: 'auto' }}>
        <Typography variant="inline">{label}</Typography>
      </Grid>
      <Grid size={{ xs: 9 }} key='title'>
        {children? children : <TextField value={value} variant="outlined" disabled={disabled} fullWidth/>}
      </Grid>
    </Grid>
  )
}

const RadioButton = ({ value, label, sx={} }) => {
  return (
    <FormControlLabel
      value={value}
      control={<Radio />}
      labelPlacement="start"
      label={label}
      sx={{
        border: 1,
        borderRadius: '10px',
        borderColor: 'grey.300',
        pl: 1,
        ml: 0,
        ...sx,
      }}
    />
  )
}

const initState = {
  openModal: false,
  orderID: '',
  reason: '',
  reasonError: false,
  orderPo: '',
  orderPoError: false,
  pricePerLiter: '',
  pricePerLiterError: false,
  productId: '',
  productName: '',
  orderItemID: '',
  selectedDepot: '',
  selectedDepotError: false,
  uploadedFiles: [],
  filePreviewUrl: null,
  isFileAttached: false,
  yOverflow: 'auto',
  modalStyle: {},
  isDisabledPrice: !isAuthorizedRole(["FINANCE-C2", "FINANCE-L1"]),
  selectedOrder: null,
  verdict: null,
  verdictError: false,
}
export default class AllOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      allOrders: [],
      depots: [],
      page: 1,
      records: 15,
      totalRecords: 1,
      totalPages: null,
      recordsList: [5, 10, 15, 20],
      isClient: false,
      proofOfPayment: null,
      isPoPUploaded: false,
      modalTitle: approvalTitle,
      ...initState 
    }
  }

  componentDidMount() {
    this.getAllOrders(this.props.filterString);
    this.fetchDepots();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.filterString !== this.props.filterString || this.props.updateAllOders
    ) {
      this.getAllOrders(this.props.filterString);
      this.props.setUpdateAllOders(false);
    }
  }

  forceRerender = () => {
    this.forceUpdate();
  }

  getOdersByCategory = () => {
    let category = "";
    if (this.props.orderCategory === 'buy') { // buy
      category = "&is_supplier=true";
    }
    else if (this.props.orderCategory === 'sell') { // sell
      category = "&is_client=true";
    }
    return category;
  }

  getAllOrders = async (finalQuery = '') => {
    const { records, page } = this.state;
    const category = this.getOdersByCategory();
    let endpoint = `inventory/backoffice/order_items?records=${records}&page=${page}${category}`;
  
    try {
      if (finalQuery) {
        endpoint += finalQuery;
      }
      // Perform the GET request
      try {
        const res = await API.get(endpoint);
        // console.log(`ORDERS -> status [${res.status}]`);

        if (res.status === 200) {
          // console.log(`ORDER ITEMS: ${res.status}`);
          const entries = res.data.orders;
          this.setState({
            allOrders: entries,
            totalPages: Math.min(res.data.total_pages, Math.ceil(entries?.length/this.state.records)),
            totalRecords: res.data.total_orders,
          });
          this.props.setUpdateAllOders(false);
        } else {
          console.log("Error fetching all orders:", res.status);
        }
      } catch (error) {
        console.log("Error fetching all orders:", error);
      }

    } catch (error) {
      console.error('Preflight request error:', error);
    }
  };
  

  fetchDepots = async () => {
    try {
      const response = await API.get('inventory/backoffice/depots');
      this.setState({ depots: response.data.depots });
    } catch (error) {
      console.error('Error fetching depots:', error);
    }
  };

  submitOrder = async () => {
    const { orderID, orderPo, reason, pricePerLiter, selectedDepot, orderItemID, productId, verdict, isClient } = this.state;

    console.log(`orderID = ${orderID}\norderPO = ${orderPo}\nproductID = ${productId}\nverdict = ${verdict}\npricePerLiter = ${pricePerLiter}`);

    let endpoint; 
    let conTemp;
  
    // Ensure that the fileds are provided
    if(this.isInvalidateApprovalForm()) {
      return
    }

    // 
    if (verdict === 'approve') {
        endpoint = 'inventory/backoffice/approve_order/';
        conTemp = `${endpoint}${orderID}?purchase_order=${orderPo}&order_id=${orderID}&is_client=${isClient}&reason=${reason || 'null'}&order_item_id=${orderItemID}`;
        
        if (pricePerLiter) {
            conTemp += `&price_per_liter=${pricePerLiter}`;
        }
        if (selectedDepot) {
            conTemp += `&depot_id=${selectedDepot}`;
        }
        if (productId) {
            conTemp += `&product_id=${productId}`;
        }
    } else if (verdict === 'decline') {
      if(this.isEmptyField('reason')) {
        return
      }
        endpoint = 'inventory/backoffice/decline_order/';
        conTemp = `${endpoint}${orderItemID}?purchase_order=${orderPo}&order_id=${orderID}&is_client=${isClient}&reason=${reason || 'null'}`;
    } else {
      this.setState({
        verdictError: true,
      })
      return
    }

    try {
      const res = await API.patch(conTemp);
      if (res.status === 206) {
        // this.setState({ openModal: false, selectedDepot: '', orderPo: '', reason: '', pricePerLiter: '' });
        this.handleCloseModal();
        this.props.setUpdateStats(true);
        this.props.setUpdateAllOders(true);
      } else {
        console.log("Error:", res.data);
      }
    } catch (error) {
      console.log("Error submitting order:", error);
    }
  };

  handleSubmitDocument = async () => {
    const { proofOfPayment, orderItemID, isFileAttached } = this.state;

    if (!proofOfPayment) return;
    if (proofOfPayment && !isFileAttached) {
      this.setState({
        modalStyle: filePreviewStyle,
        isFileAttached: true,
        modalTitle: viewDocumentTitle,
      });
      return;
    }

    const formData = new FormData();
    formData.append('order_confirmation_file', proofOfPayment);
    try {
      const res = await API.post(`inventory/backoffice/store_order_confirmation/${orderItemID}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (res.status === 200) {
        this.setState({
          proofOfPayment: null,
          uploadedFiles: [],
          modalStyle: {},
          isFileAttached: false,
          isPoPUploaded: true,
          modalTitle: approvalTitle,
        });
      } else {
        console.log("Error:", res.data);
      }

    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  handleProofOfPaymentChange = (files) => {
    const file = (files.length > 0)? files[0] : null;
    this.setState({ 
        proofOfPayment: file,
        uploadedFiles: files,
        filePreviewUrl: file? URL.createObjectURL(file) : null,
    });
  };

  nextPage = (action) => {
    const { totalPages } = this.state;
    this.setState((prevState) => {
      let currentPage = prevState.page;
      if (action === 'next') {
        currentPage = Math.min(currentPage + 1, totalPages);
      } else {
        currentPage = Math.max(currentPage - 1, 1);
      }
      return { page: currentPage };
    }, () => {
      this.getAllOrders(this.props.filterString);
    });
  };

  handleDepotSelect = (event) => {
    const selectedValue = event.target.value;
    this.setState({ selectedDepot: selectedValue })

    const currentDepot = this.state.depots?.find(d => d.depot_id === selectedValue)?.prices;
    if (isNotNullValue(currentDepot)){
      const product = currentDepot?.find(d => d.product_id === this.state.productId);
      const price = product.price_details?.sell_price || '';
      this.setState({ 
        pricePerLiter: price,
        pricePerLiterError: !isNotNullValue(price),
        selectedDepotError: false,
      })
      // console.log('selected depot: ', selectedValue, currentDepot, product)

    }

  }

  handleChangeRowsPerPage = (event) => {
    this.setState(() => {
      return { records: parseInt(event.target.value, 10), page: 1 };
    }, () => {
      this.getAllOrders(this.props.filterString);
    });
  }

  openModalWithOrder = (order) => {
    console.log(`Opening modal with order: `, order);
    this.setState({
      openModal: true,
      modalTitle: (order.is_client || this.state.isPoPUploaded) ? approvalTitle : uploadTitle,
      orderID: order.order_id,
      productId: order.product_id,
      productName: order.fuel_type,
      orderItemID: order.order_item_id,
      isClient: order.is_client,
      isPoPUploaded: false, // Reset PoP uploaded state when opening modal
      selectedOrder: order,
    });
  };

  handleCloseModal = () => {
    this.setState({ ...this.state, ...initState });
  };

  isEmptyField = (field) => {
    const empty = (this.state[field] === '' || this.state[field] === null || this.state[field] === undefined);
    if (empty) {
      const errorField = field + 'Error';
      this.setState({
        [errorField]: true,
      })
    }
    return empty;
  }

  isInvalidateApprovalForm = () => {
    let hasErrors = false;
    hasErrors |= this.isEmptyField('orderPo');
    hasErrors |= this.isEmptyField('verdict');
    hasErrors |= (this.state.verdict === 'decline' && this.isEmptyField('reason'));
    hasErrors |= (this.state.verdict === 'approve' && this.isEmptyField('selectedDepot'));
    hasErrors |=(this.state.verdict === 'approve' && this.isEmptyField('pricePerLiter'));

    return hasErrors;
  }

  render() {
    const { allOrders, openModal, depots, isClient, isPoPUploaded, isFileAttached } = this.state;
    return (
      <Paper sx={{ mt: 2, borderRadius: '10px' }}>
 
        <TableContainer >
          <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Order Number</TableCell>
              <TableCell align="right">Client Name</TableCell>
              <TableCell align="right">Product</TableCell>
              <TableCell align="right">Volume</TableCell>
              <TableCell align="right">Created Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allOrders.map((row) => (
              <TableRow
                key={row.order_item_id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '&:hover': { backgroundColor: 'rgba(39,226,245,0.27)' },
                }}
                onClick={() => this.openModalWithOrder(row)}
              >
                <TableCell component="th" scope="row">
                  {row.order_item_id}
                </TableCell>
                <TableCell align="right">
                  <Stack direction="row" alignItems="center" gap={1}>
                    {(!row.is_client && row?.logo?.color) && (
                      <Tooltip title="Supplier">
                        <CircleIcon fontSize="small" sx={{ color: row?.logo?.color }} />
                      </Tooltip>
                    )}
                    {row.company_name}
                  </Stack>
                </TableCell>
                <TableCell align="right">{row.fuel_type}</TableCell>
                <TableCell align="right">{row.volume}</TableCell>
                <TableCell align="right">{moment.utc(row.created_date).format('YYYY/MM/DD - HH:mm:ss')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomTablePagination
        rowsPerPage={this.state.records}
        recordsList={this.state.recordsList}
        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        handleNextPage={this.nextPage}
        currentPage={this.state.page}
        totalPages={this.state.totalPages}
      />
      {isAuthorizedRole(["FINANCE-C2"]) && (
        <OrderCards
          orderData={this.state.selectedOrder}
          handleCloseModal={this.handleCloseModal}
          openModal={openModal}
        />
      )}

      {isAuthorizedRole(["FINANCE-L1", "OPERATIONS-C2", "EXECUTIVE-C1", "DEMO"]) && (
        <CustomModal
          openModal={openModal}
          handleCloseModal={this.handleCloseModal}
          modalStyle={this.state.modalStyle}
          modalTitle={isClient? `Order ${this.state.selectedOrder?.order_id || ''}`: this.state.modalTitle}
        >
          <>
            {isClient && (
              <>
                <FormItemlGrid
                  label={"Created by"}
                  value={this.state.selectedOrder?.created_by || '-'}
                  disabled={true}
                />

                <FormItemlGrid
                  label={"Created on"}
                  value={this.state.selectedOrder?.created_date
                    ? moment(this.state.selectedOrder?.created_date).format('YYYY/MM/DD HH:mm:ss')
                    : ''
                  }
                  disabled={true}
                />
              </>
            )}
            {(isClient || isPoPUploaded) && (
              <>
              <FormItemlGrid
                label="PO Number"
              >
                <FormControl fullWidth>
                  <TextField
                    id="po"
                    variant="outlined"
                    value={this.state.orderPo}
                    onChange={(e) => this.setState({
                      orderPo: e.target.value,
                      orderPoError: false,
                    })}
                    error={this.state.orderPoError}
                    helperText={this.state.orderPoError && 'Please provide a PO number'}
                  />
                </FormControl>
              </FormItemlGrid>

              <FormItemlGrid
                label="Reason"
              >
                <FormControl fullWidth>
                  <TextField
                    id="reason"
                    required={true}
                    variant="outlined"
                    value={this.state.reason}
                    onChange={(e) => this.setState({
                      reason: e.target.value,
                      reasonError: false,
                    })}
                    error={this.state.reasonError}
                    helperText={this.state.reasonError && 'Please provide a reason'}
                  />
                </FormControl>
              </FormItemlGrid>
              </>
            )}
            {isClient && (
              <>
              <FormItemlGrid
                label={"Select Depot"}
              >
                <FormControl fullWidth >
                  <TextField
                    displayEmpty
                    select
                    required
                    labelId="depot-select-label"
                    value={this.state.selectedDepot}
                    onChange={(e) => this.handleDepotSelect(e)}
                    error={this.state.selectedDepotError}
                    helperText={this.state.selectedDepotError && 'Please select a depot'}
                  >
                    <MenuItem value="">
                      <em>Select Depot</em>
                    </MenuItem>
                    {depots.map((depot) => (
                      <MenuItem key={depot.depot_id} value={depot.depot_id}>
                        {depot.depot_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </FormItemlGrid>
              </>
            )}
            {(isClient || (!isClient && isPoPUploaded && isAuthorizedRole(["OPERATIONS-C2", "EXECUTIVE-C1", "DEMO"]))) && (
              <FormItemlGrid
                  // label={`${productName} Price per Litre`}
                  label={`Price per Litre`}
                >
                <FormControl fullWidth>
                  <TextField
                    id="price-per-liter"
                    variant="outlined"
                    value={this.state.pricePerLiter}
                    onChange={(e) => this.setState({
                      pricePerLiter: e.target.value,
                      pricePerLiterError: false,
                    })}
                    // disabled={this.state.isDisabledPrice}
                    error={this.state.pricePerLiterError}
                    helperText={this.state.pricePerLiterError && 'Please ensure a price is set'}
                  />
                </FormControl>
              </FormItemlGrid>
            )}

            <Grid container alignItems={'center'} sx={{ mt: 5, }}>
            {(isClient || isPoPUploaded)? (
              <>
              <Grid item size={{ xs: 8 }} sx={{ my: 'auto',  display: 'flex', justifyContent: 'left' }} >
                <FormControl error={this.state.verdictError}>
                  <RadioGroup
                    error
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => this.setState({
                      verdict: e.target.value,
                      verdictError: false,
                      reasonError: this.isEmptyField('reason') && e.target.value === 'decline',
                    })}
                  >
                    <RadioButton value="decline" label="Decline" />
                    <RadioButton value="approve" label="Approve" sx={{ml: 2}} />
                  </RadioGroup>
                  <FormHelperText>{this.state.verdictError && 'Please specify an action to perform'}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item size={{ xs: 2 }} sx={{ my: 'auto', display: 'flex', justifyContent: 'right'}}>
                <Button
                  variant='contained'
                  component="label"
                  color='inherit'
                  onClick={() => this.handleCloseModal()}
                >
                  Cancel
                </Button>
              </Grid>
              {/* <Grid item size={{ xs: 4 }}>
                <Button
                  color="error"
                  variant='contained'
                  sx={{ mt: 2 }}
                  onClick={() => { this.submitOrder('decline') }}
                  disabled={!isClient && !isPoPUploaded} // Enable only if isClient is false and PoP is uploaded
                >
                  Decline
                </Button>
              </Grid> */}
              <Grid item size={{ xs: 2 }} sx={{ my: 'auto', display: 'flex', justifyContent: 'right'}}>
                <Button
                  color="secondary"
                  variant='contained'
                  onClick={() => { this.submitOrder() }}
                  disabled={!isClient && !isPoPUploaded} // Enable only if isClient is false and PoP is uploaded
                >
                  Submit
                </Button>
              </Grid>

              </>
            ) :(!isClient && !isPoPUploaded && isAuthorizedRole(["OPERATIONS-C2", "EXECUTIVE-C1", "DEMO"])) && (
                <>
                  {!isFileAttached && (
                    <Grid size={{ xs: 12 }} >
                      <FileDragzone
                        acceptedTypes={{
                          'application/pdf': ['.pdf'],
                        }}
                        fileObjects={this.state.uploadedFiles}
                        handleFileObjects={this.handleProofOfPaymentChange}
                        filesLimit={1}
                        maxFileSizeMB={10}
                      />
                    </Grid>
                  )}
                  {isFileAttached && (
                      <Grid item size={{ xs: 12 }} sx={{ my: 'auto', display: 'flex', justifyContent: 'center' }}>
                        <CustomPdfViewer
                          fileUrl={this.state.proofOfPayment ? URL.createObjectURL(this.state.proofOfPayment) : null}
                        />
                      </Grid>
                  )}

                  <Grid item size={{ xs: 12 }} sx={{ mt: 2, display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
                    <Button
                      variant='contained'
                      sx={{ mr: 2 }}
                      component="label"
                      color='inherit'
                      onClick={() => this.handleCloseModal()}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      component="label"
                      onClick={() => this.handleSubmitDocument()}
                    >
                      Continue
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </>
        </CustomModal>
      )}
      </Paper>
    );
  }
}

AllOrders.propTypes = {
  filterString: PropTypes.string.isRequired,
  setUpdateStats: PropTypes.func.isRequired,
  updateAllOders: PropTypes.bool.isRequired,
  setUpdateAllOders: PropTypes.func.isRequired,
};
