import {
  Box,
  lighten,
  LinearProgress,
  Typography,
} from "@mui/material"
import { WHITE_SMOKE_COLOR } from "../../../utils/constants";

const ProgressLabel = ({ value, alignment, sx }) => {
  return (
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems={alignment}
      justifyContent={alignment}
    >
      <Typography variant="body2" sx={{ my: 'auto', fontWeight: 'bold', ...sx }} >{`${value}`}</Typography>
    </Box>
  )
}

export default function LabeledProgressBar({ value, height, leftBar, rightBar, sx={} }) {

  return (
    <Box position="relative" display="inline-flex" sx={{ width: "100%", ...sx }}>
      <Box sx={{ width: '100%' }}>
        <LinearProgress
          variant="determinate"
          value={value || 0}
          sx={{
            backgroundColor: lighten(rightBar?.backgroundColor || WHITE_SMOKE_COLOR, 0.1),
            height: `${height || "40px"}`,
            borderRadius: "5px",
            '& .MuiLinearProgress-bar': {
              borderRadius: "5px",
              backgroundColor: lighten(leftBar?.backgroundColor || "#0000ff", 0.1),
            }
          }}
        />
      </Box>
      <ProgressLabel value={leftBar?.label || ''} alignment={'left'} sx={{ ml: 2, color: `${leftBar?.textColor || '#000'}` }} />
      <ProgressLabel value={rightBar?.label || ''} alignment={'right'} sx={{ mr: 2, color: `${rightBar?.textColor || '#000'}` }} />
    </Box>
  );
}
