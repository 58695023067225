import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid2'

import { TotalCard } from '../dashboard/cards/TotalCard';
import {
  findCardTypeValue,
  transformExcusionTally,
  transformTallyData,
} from '../dashboard/dash-util';
import { isAuthorizedRole } from '../../utils/authUtil';
import { ProductCard } from '../dashboard/cards/ProductCard';
import { TotalProductCard } from '../dashboard/cards/TotalProductCard';

//                 Green,      red,       blue,      yellowish
const colorCodes = ['#27ae60', '#d32f2f', '#1976d2', '#ff9800'];
const cardColors = {
  'Buy Orders': colorCodes[0],
  'Sell Orders': colorCodes[1],
  'Paid Invoices': colorCodes[0],
  'Unpaid Invoices': colorCodes[0],
  'Total Invoices': colorCodes[2],
  'Total on Credit': colorCodes[3],
  'In-Bound Suborders': colorCodes[2],
  'Direct Suborders': colorCodes[0],
  'Outstanding Nominations': colorCodes[1],
  'Requested Releases': colorCodes[3],
}

export const SummaryCards = ({ cardsData, isInvoices }) => {

  useEffect(() => {

  }, [cardsData, isInvoices]);

  return (
    <>
      {/* Chief Executive Officer */ isAuthorizedRole(["EXECUTIVE-C1"]) && (
        <Grid container spacing={3}>
          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Total Orders'>
            <ProductCard
              isTally={true}
              sx={{ height: '100%' }}
              data={findCardTypeValue(cardsData, 'Total Orders')}
            />
          </Grid>
          {transformExcusionTally(cardsData, 'Total Orders')
            ?.sort((a, b) => a?.type.localeCompare(b?.type))
            ?.map((data, index) => (
              <Grid size={{ lg: 4.5, sm: 6, xs: 12 }} key={data.type + index}>
                <ProductCard
                  litres={true}
                  sx={{ height: '100%' }}
                  data={data}
                />
              </Grid>
            ))}
        </Grid>
      )}
      {/* Chief Finance Officer */ isAuthorizedRole(["FINANCE-C2", "DEMO"]) && (
        <Grid container spacing={3}>
          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Buy Orders'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Buy Orders', colorCodes[0])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Sells'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Sell Orders', colorCodes[1])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Paid Invoices'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Paid Invoices', colorCodes[0])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Unpaid Invoices'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Unpaid Invoices', colorCodes[1])}
            />
          </Grid>
        </Grid>
      )}
      {/* Finance Accountant */ (isAuthorizedRole(["FINANCE-L1"]) && isInvoices) && (
        <Grid container spacing={3}>
          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Total Invoices'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Total Invoices', colorCodes[2])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Paid Invoices'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Paid Invoices', colorCodes[0])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Unpaid Invoices'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Unpaid Invoices', colorCodes[1])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Total on Credit'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Total on Credit', colorCodes[3])}
            />
          </Grid>
        </Grid>
      )}
      {/* Finance Accountant */ (isAuthorizedRole(["FINANCE-L1"]) && !isInvoices) && (
        <Grid container spacing={3}>
          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Total Orders'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Total Orders', colorCodes[2])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Unapproved Orders'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Unapproved Orders', colorCodes[3])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Declined Orders'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Declined Orders', colorCodes[1])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Total Product Delivered'>
            <TotalProductCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Total Product Delivered', colorCodes[0])}
            />
          </Grid>
        </Grid>
      )}
      {/* Operations Department */ isAuthorizedRole(["OPERATIONS-C2", "OPERATIONS-L1"]) && (
        <Grid container spacing={3}>
          {cardsData?.sort((a, b) => a?.type.localeCompare(b?.type))
            ?.map((data, index) => (
              <Grid size={{ lg: 4.5, sm: 6, xs: 12 }} key={data.type + index}>
                <TotalCard
                  sx={{ height: '100%' }}
                  data={data}
                />
              </Grid>
            ))}
        </Grid>
      )}
      {/* Logistics Department */ isAuthorizedRole(["LOGISTICS-C2", "LOGISTICS-L1"]) && (
        <Grid container spacing={3}>
          {cardsData?.sort((a, b) => a?.value - b?.value)
            ?.map((data, index) => (
              <Grid size={{ lg: 4.5, sm: 6, xs: 12 }} key={data.type + index}>
                <ProductCard
                  sx={{ height: '100%' }}
                  data={data}
                />
              </Grid>
            ))}
        </Grid>
      )}
      {/* Logistics Department */ isAuthorizedRole(["CUSTOMS-L1"]) && (
        <Grid container spacing={3}>
        {cardsData?.sort((a, b) => a?.value - b?.value)
          ?.map((data, index) => (
            <Grid size={{ lg: 4.5, sm: 6, xs: 12 }} key={data.type + index}>
              <TotalCard
                sx={{ height: '100%' }}
                data={{ ...data, color: cardColors[data.type] }}
              />
            </Grid>
          ))}
      </Grid>
    )}
    </>
  )
}

SummaryCards.propTypes = {
  cardData: PropTypes.arrayOf(PropTypes.object).isRequired,
  isInvoices: PropTypes.bool.isRequired,
}
