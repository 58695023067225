import * as React from "react"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid2"
import { Box } from "@mui/material"
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';

import { DASHBOARD_ICON_OPACITY_10 } from "../../../utils/constants"
import palette from "../../../theme/palette"

export function TallyCard({ data, isExecutive, sx={} }) {

  return (
    <Card sx={{ height: '100%', ...sx }}>
      <CardContent>
        <Stack
          direction="row"
          sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
          spacing={3}
        >
          <Stack
            spacing={1}
            sx={{
              borderLeft: `5px solid ${data.color}`,
              pl: 2,
              pt: 0,
              height: "50%"
            }}
          >
            <Typography color={palette.grey[500]} variant="body1">
              {data.type}
            </Typography>
            {isExecutive ? (
              <Typography variant="subtitle1">{data?.value}</Typography>
            ) : null}
          </Stack>
          <Box
            sx={{
              backgroundColor: ` ${data.color}${DASHBOARD_ICON_OPACITY_10}`,
              height: "45px",
              width: "45px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <DepartureBoardIcon sx={{ color: `${data.color}` }} />
          </Box>
        </Stack>
        {isExecutive && data?.metadata ? (
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid size={{ xs: 4 }} fontSize="12px" fontWeight="normal">
              {data.metadata.ppm50}
              <Typography sx={{ color: palette.grey[500], fontWeight: "normal" }} variant="overline">
                &nbsp;50 PPM
              </Typography>
            </Grid>
            <Grid size={{ xs: 4 }} fontSize="12px" fontWeight="normal">
              {data.metadata.ulp93}
              <Typography sx={{ color: palette.grey[500], fontWeight: "normal" }} variant="overline">
                &nbsp;ULP 93
              </Typography>
            </Grid>
            <Grid size={{ xs: 4 }} fontSize="12px" fontWeight="normal">
              {data.metadata.ulp95}
              <Typography sx={{ color: palette.grey[500], fontWeight: "normal" }} variant="overline">
                &nbsp; ULP 95
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Typography sx={{ mt: 3, color: palette.grey[500] }} variant="subtitle1">
            {data?.value}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}
