import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid2'

import {
  transformTallyData,
  transformCustomsData,
  transformProductGroupedStack,
  transformProductSegmentData,
  transformBiProgressData,
} from '../dash-util';
import { TotalCard } from '../cards/TotalCard';
import { LabeledProgressCard } from '../cards/LabeledProgressCard';
import GroupedStackedColumn from '../charts/GroupedStackedColumn';
import { ProductSegmentCard } from '../cards/ProductSegmentCard';

export function CustomsView({ cardsData, regressionData,  productData, periodFilter, setPeriodFilter }) {

  const [groupedStacked, setGroupedStacked] = useState([]);
  const [showGroupedLegend, setShowGroupedLegend] = useState(false);

  useEffect(() => {
    const _gStacked = transformProductGroupedStack(regressionData);
    setGroupedStacked(_gStacked);

    const showLegend = !_gStacked.series.every((item) => item.data.length === 0);
    setShowGroupedLegend(showLegend);

  }, [cardsData, regressionData, productData, periodFilter]);

  return (
    <Grid container spacing={3}>
      <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='In-Bond Suborders'>
        <TotalCard
          sx={{ height: '100%' }}
          data={transformTallyData(cardsData, 'In-Bond Suborders')}
        />
      </Grid>

      <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Direct Suborders'>
        <TotalCard
          sx={{ height: '100%' }}
          data={transformTallyData(cardsData, 'Direct Suborders')}
        />
      </Grid>

      <Grid size={{ lg: 3, sm: 6, xs: 12 }} key={'Nominations'}>
        <LabeledProgressCard
          data={transformBiProgressData(cardsData, 'Nominations', 'Sent', 'Outstanding')}
          sx={{ height: '100%' }}
        />
      </Grid>

      <Grid size={{ lg: 3, sm: 6, xs: 12 }} key={'SARS Customs'}>
        <LabeledProgressCard
          data={transformBiProgressData(cardsData, 'SARS Customs', 'Sent', 'Outstanding', false)}
          sx={{ height: '100%' }}
        />
      </Grid>

      <Grid size={{ lg: 4, sm: 6, xs: 12 }} key={'Releases'}>
        <LabeledProgressCard
          data={transformBiProgressData(cardsData, 'Releases', 'Sent', 'Outstanding', false)}
          sx={{ height: '100%' }}
        />
      </Grid>

      <Grid size={{ lg: 4, sm: 6, xs: 12 }} key={'Commercial Invoices'}>
        <LabeledProgressCard
          data={transformBiProgressData(cardsData, 'Commercial Invoices', 'Sent', 'Outstanding', false)}
          sx={{ height: '100%' }}
        />
      </Grid>

      <Grid size={{ lg: 4, sm: 6, xs: 12 }} key={'Transit Clearance'}>
        <LabeledProgressCard
          data={transformBiProgressData(cardsData, 'Transit Clearance', 'Sent', 'Outstanding', false)}
          sx={{ height: '100%' }}
        />
      </Grid>

      <Grid size={{ xs: 8 }} key="grouped stacked">
      {/* <AreaChart /> */}
      <GroupedStackedColumn
        series={groupedStacked.series}
        categories={groupedStacked.categories}
        height={350}
        title={"Supplier Product ordered"}
        periodFilter={periodFilter}
        setPeriodFilter={setPeriodFilter}
        showLegend={showGroupedLegend}
      />
    </Grid>

    <Grid size={{ xs: 4 }} key="product segment">
      <ProductSegmentCard
        products={transformProductSegmentData(productData)}
        periodFilter={periodFilter}
        setPeriodFilter={setPeriodFilter}
        sx={{ height: '100%' }}
      />
    </Grid>
    </Grid>
  )
}

CustomsView.propTypes = {
  cardData: PropTypes.arrayOf(PropTypes.object).isRequired,
}
