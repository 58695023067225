import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  MenuItem,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import API from '../../../data/api';
import { CustomSelect } from '../../custom/CustomSelect';
import CustomModal from '../../custom/CustomModal';
import CustomTextField from '../../custom/CustomTextField';
import CustomSnackBar from '../../custom/CustomSnackBar';

import {
  extractFormValues,
  initialiseForm,
  isNullValue,
  validateForm
} from '../../../utils/formUtil';

const initFormState = {
  company: { value: '', required: true, helper: 'transport company' },
  name: { value: '', required: true },
  surname: { value: '', required: true },
  passport: { value: '', required: true },
  cellphone: { value: '', required: false },
};

const CompanyDriver = ({
  transportCompanies=[],
  openModal,
  handleCloseModal,
  handleSubmitCallback
}) => {

  const initialFormData = initialiseForm(initFormState);

  const [formData, setFormData] = useState(initialFormData);
  const [submitted, setSubmitted] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: "",
    type: "",
  });

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transportCompanies, openModal]);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);

    if (!validateForm(setFormData, formData)) {
      console.log("oops, failed validation", formData)
      setSubmitted(false);
      return;
    }

    try {
      const { company, name, surname, passport, cellphone } = extractFormValues(formData);
      const companyName = transportCompanies?.find(tc => tc?.id === company)?.company_name || '';

      const response = await API.post('inventory/logistics/create_driver', {
        company_id: company,
        first_name: name,
        last_name: surname,
        passport: passport,
        cellphone: cellphone || null,
      });

      if (response.status === 200 || response.status === 201) {
        // Reset the form if response is successful
        setFormData(initialFormData);
        setSnackbarMessage({
          message: `Successfully added ${name + ' ' + surname} as a driver for ${companyName? 'for' + companyName : ''}`,
          type: "success",
        });
        handleSubmitCallback();
      } else {
        setSnackbarMessage({ message: response.message, type: "error" });
      }
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage({
        message: "An unexpected error occurred. Please try again later.",
        type: "error",
      });
    }
    setSubmitted(false);
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    handleCloseModal();
  }

  const handleFormDataChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        value: e.target.value,
        error: formData[e.target.name]?.required && isNullValue(e.target.value),
      },
    });
  };

  return (
    <>
      <CustomModal
        openModal={openModal}
        handleCloseModal={handleCancel}
        modalTitle={`Add Driver`}
        modalIcon='transport'
      >
        <form onSubmit={handleSubmit} noValidate>
          <Stack spacing={3} sx={{ mt: 2 }}>

            <CustomSelect
              name="company"
              label="Transport Company"
              value={formData.company.value}
              onChange={handleFormDataChange}
              required={formData.company.required}
              error={formData.company.error}
              helperText={formData.company.error && formData.company.errorMessage}
            >
              <MenuItem value=" " disabled>
                <em>Select Company</em>
              </MenuItem>
              {transportCompanies?.map((trans) => (
                <MenuItem key={'transport-' + trans.company_id} value={trans.company_id}>
                  {trans.company_name}
                </MenuItem>
              ))}
            </CustomSelect>

            <CustomTextField
              name="name"
              label="First Name"
              value={formData.name.value}
              onChange={handleFormDataChange}
              required={formData.name.required}
              error={formData.name.error}
              helperText={formData.name.error && formData.name.errorMessage}
            />
            <CustomTextField
              name="surname"
              label="Last Name"
              value={formData.surname.value}
              onChange={handleFormDataChange}
              required={formData.surname.required}
              error={formData.surname.error}
              helperText={formData.surname.error && formData.surname.errorMessage}
            />
            <CustomTextField
              name="passport"
              label="Passport Number"
              value={formData.passport.value}
              onChange={handleFormDataChange}
              required={formData.passport.required}
              error={formData.passport.error}
              helperText={formData.passport.error && formData.passport.errorMessage}
            />

            <CustomTextField
              name="cellphone"
              label="Cellphone Number"
              value={formData.cellphone.value}
              onChange={handleFormDataChange}
              required={formData.cellphone.required}
              error={formData.cellphone.error}
              helperText={formData.cellphone.error && formData.cellphone.errorMessage}
            />

            <Box sx={{ alignItems: 'center', mt: 3, }}>
              <Button color="inherit"
                variant='contained'
                sx={{ width: '47%' }}
                onClick={() => { handleCancel() }}
                disabled={submitted}
              >
                Cancel
              </Button>
              <LoadingButton
                variant='contained'
                sx={{ ml: 2, width: '47%' }}
                type="submit"
                loading={submitted}
              >
                Submit
              </LoadingButton>
            </Box>
          </Stack>
        </form>
      </CustomModal>
      <CustomSnackBar
        snackbarMessage={snackbarMessage}
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={() => setSnackbarOpen(false)}
      />
    </>
  );
}

CompanyDriver.propTypes = {

  /** List of transport companies */
  transportCompanies: PropTypes.array.isRequired,

  /** Flag to toggling openning of modal */
  openModal: PropTypes.bool.isRequired,

  /** Callback function when closing modal */
  handleCloseModal: PropTypes.func.isRequired,

  /** Callback function after successful submission */
  handleSubmitCallback: PropTypes.func.isRequired,

}

export default CompanyDriver
