/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'

import { Box, Button, FormControl } from '@mui/material'

import API from '../../../data/api';
import CustomModal from '../../custom/CustomModal'
import CustomTextField from '../../custom/CustomTextField';
import { isNullValue } from '../../../utils/formUtil';
import { LoadingButton } from '@mui/lab';

function LogisticsApproval({
  generateQRcode = true,
  subOrder,
  suppliers,
  transportCompanies,
  openModal,
  handleCloseModal,
  handleApprovalCallback,
}) {

  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [openQrCodeModal, setOpenQrCodeModal] = useState(false);
  const [subOrderId, setSubOrderId] = useState(null);
  const [isApproved, setIsApproved] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);

  useEffect(() => {

    console.log(subOrder, openModal)
    setSubOrderId(subOrder?.order_id || subOrder?.suborder_id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subOrder, suppliers, transportCompanies, openModal]);

  const handleApproveLogistics = async () => {
    setIsApproved(true);
    try {
      const response = await API.patch(`inventory/logistics/approve_suborder/${subOrderId}`);
      if (response.status === 206) {
        if (generateQRcode) {
          handleQrCodeModal();
        }
        handleApprovalCallback(isApproved, null, true);
      } else {
        console.error('Error approving logistics:', response.status);
      }
    } catch (error) {
      console.error('Error approving logistics:', error);
    }
    setIsApproved(false);
  };

  const handleDeclineLogistics = async () => {
    setIsDeclined(true);
    try {
      const response = await API.patch(`inventory/logistics/decline_suborder/${subOrderId}`);
      if (response.status === 206 || response.status === 200) {
        handleApprovalCallback(isApproved, null, true);
      } else {
        console.error('Error declining logistics:', response.status);
      }
    } catch (error) {
      console.error('Error declining logistics:', error);
    }
    setIsDeclined(false);
  };

  const handleQrCodeModal = async () => {
    try {
      const qrCodeResponse = await API.get(`inventory/logistics/generate_qrcode/${subOrderId}`);
      if (qrCodeResponse.status === 200) {
        const { url } = qrCodeResponse.data;
        setQrCodeUrl(url);
        setOpenQrCodeModal(true);

        // handleGenerateReport();
      } else {
        console.error('Error generating QR code:', qrCodeResponse.status);
      }
    } catch (error) {
      console.error('Error reendering QR code:', error);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleGenerateReport = async () => {
    try {
      const generateSuborderReport = await API.get(`inventory/logistics/generate_suborder_report/${subOrderId}`);
      if (generateSuborderReport.status === 200) {
        console.log("Report successfully generated");
      } else {
        console.error('Error generating suborder report:', generateSuborderReport.status);
      }
    } catch (error) {
      console.error('Error reendering QR code:', error);
    }
  };

  const findOrderSupplier = () => {
    if (isNullValue(suppliers) || isNullValue(subOrder) || isNullValue(subOrder?.supplier_id)) {
      return null;
    }

    return suppliers?.find(t => t?.supplier_id === subOrder?.supplier_id)?.supplier_name;
  }


  const findTransportCompany = () => {
    if (isNullValue(transportCompanies) || isNullValue(subOrder) || isNullValue(subOrder?.transport_id)) {
      return null;
    }

    return transportCompanies?.find(t => t?.company_id === subOrder?.transport_id)?.company_name;
  }

  const handleCloseQrCode = () => {
    setQrCodeUrl("");
    setOpenQrCodeModal(false);
  }

  return (
    <>
      <CustomModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        modalTitle={`Logistics Approval for Order ${subOrderId} (${subOrder?.fuel_type})`}
        modalIcon='transport'
      >
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <img style={{ height: 100 }}
              alt="Transpoter"
              src="/assets/images/truck-tanker-icon.jpg"
            />
          </Box>

          <FormControl fullWidth sx={{ my: 2 }}>
            <CustomTextField
              label={"Supplier"}
              value={findOrderSupplier()}
              disabled
            />
          </FormControl>

          <FormControl fullWidth sx={{ my: 2 }}>
            <CustomTextField
              label={"Transporter"}
              value={findTransportCompany()}
              disabled
            />
          </FormControl>

          <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => handleCloseModal()}
              disabled={isApproved || isDeclined}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="error"
              onClick={handleDeclineLogistics}
              loading={isDeclined}
              disabled={isApproved}
              sx={{ ml: 2 }}
            >
              Decline
            </LoadingButton>
            <Button
              variant="contained"
              color="success"
              onClick={handleApproveLogistics}
              loading={isApproved}
              disabled={isDeclined}
              sx={{ ml: 2 }}
            >
              Approve
            </Button>
          </Box>
        </>
      </CustomModal>

{/*
      <CustomModal
        openModal={openQrCodeModal}
        handleCloseModal={handleCloseQrCode}
        modalTitle={'QR Code'}
        modalIcon='qrcode'
      >
        <>
          {qrCodeUrl && <img src={qrCodeUrl} alt="QR Code" style={{ margin: '16px 0' }} />}
          <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
            <Button
              color="inherit"
              variant='contained'
              onClick={() => { handleCloseQrCode() }}
            >
              Cancel
            </Button>
          </Box>
        </>
      </CustomModal>
 */}
    </>
  )
}

LogisticsApproval.propTypes = {

  /** Flag whether to generate a QR code after aproval */
  generateQRcode: PropTypes.bool,

  /** Selected subOrder details */
  subOrder: PropTypes.object.isRequired,

  /** List of suitable suppliers */
  suppliers: PropTypes.array.isRequired,

  /** List of suitable transport companies */
  transportCompanies: PropTypes.array.isRequired,

  /** Flag to toggling openning of logistics approval modal */
  openModal: PropTypes.bool.isRequired,

  /** Callback function when closing logistics approval modal */
  handleCloseModal: PropTypes.func.isRequired,

  /** Callback function when approving/declining a logistic */
  handleApprovalCallback: PropTypes.func.isRequired,

}

export default LogisticsApproval
