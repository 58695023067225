import * as React from "react"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { Box } from "@mui/material"
import {
  AirplaneEngines,
  FileEarmarkArrowDownFill,
  FileEarmarkArrowUpFill,
  FileEarmarkCheckFill,
  FileEarmarkMinusFill,
  FileEarmarkText,
  FileEarmarkXFill,
  TrainFront
} from "react-bootstrap-icons"

import { DASHBOARD_ICON_OPACITY_10 } from "../../../utils/constants"
import palette from "../../../theme/palette"
import { scaleNumber } from "../../../utils/formatNumber"

export function TotalCard({ data, sx={} }) {

  return (
    <Card sx={{ height: '100%', ...sx }}>
      <CardContent>
        <Stack
          direction="row"
          sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
          spacing={3}
        >
          <Stack
            spacing={1}
            sx={{
              borderLeft: `5px solid ${data.color}`,
              pl: 2,
              pt: 0,
              height: "50%"
            }}
          >
            <Typography color={palette.grey[500]} variant="body1">
              {data.type}
            </Typography>
            <Typography variant="subtitle1">{scaleNumber(data?.value || 0)}</Typography>
          </Stack>
          <Box
            sx={{
              backgroundColor: ` ${data.color}${DASHBOARD_ICON_OPACITY_10}`,
              height: "45px",
              width: "45px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {(() => {
              switch (data?.type) {
                case 'Buy Orders':
                  return <FileEarmarkArrowUpFill color={data?.color} />
                case 'Sell Orders':
                  return <FileEarmarkArrowDownFill color={data?.color} />
                case 'Paid Invoices':
                  return <FileEarmarkCheckFill color={data?.color} />
                case 'Declined Orders':
                case 'Unpaid Invoices':
                  return <FileEarmarkXFill color={data?.color} />
                case 'Total Invoices':
                case 'Total Orders':
                  return <FileEarmarkText color={data?.color} />
                case 'Unapproved Orders':
                case 'Total on Credit':
                  return <FileEarmarkMinusFill color={data?.color} />
                case 'In-Bond Suborders':
                  return <AirplaneEngines color={data?.color} />
                case 'Direct Suborders':
                  return <TrainFront color={data?.color} />
                case 'Outstanding Nominations':
                  return <FileEarmarkXFill color={data?.color} />
                case 'Requested Releases':
                  return <FileEarmarkMinusFill color={data?.color} />
                default:
                  return null
              }
            })()}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}
