import React from 'react'

// Import the main component
import { Viewer, Worker } from "@react-pdf-viewer/core";
// import { defaultLayoutPlugin,  } from '@react-pdf-viewer/default-layout';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export default function CustomPdfViewer({ fileUrl }) {

  return (
    <>
      {fileUrl && (
        <Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
        >
          <Viewer fileUrl={fileUrl}
            plugins={[
              // Register plugins
              // defaultLayoutPlugin(),
            ]}

          />
        </Worker>
      )}
    </>
  )
}
