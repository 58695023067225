import React from "react";
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Divider,
} from "@mui/material";

const ConfirmDialog = ({ open, title, onConfirm, onCancel, children, confirmText, cancelText }) => {

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      disableEscapeKeyDown={true}
    >
     <DialogTitle>{title}</DialogTitle>
     <Divider />
     <DialogContent>
       <Typography>{children}</Typography>
     </DialogContent>
     <DialogActions>
       <Button color="secondary" variant="contained" onClick={onCancel}>
         {cancelText || "Cancel"}
       </Button>

       <Button
         color="primary"
         variant="contained"
         onClick={onConfirm}
       >
         {confirmText || "Confirm"}
       </Button>
     </DialogActions>
   </Dialog>
 );
};

export default ConfirmDialog;

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  // setIsOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
}
