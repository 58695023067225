/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography} from '@mui/material';

import API from '../data/api';
import { isAuthorizedRole } from '../utils/authUtil';
import { FinanceView } from '../components/dashboard/views/FinanceView';
import { ExecutiveView } from '../components/dashboard/views/ExecutiveView';
import { OperationsView } from 'src/components/dashboard/views/OperationsView';
import InvoiceList from 'src/components/ui/InvoiceList';

// import dummyData from '../assets/dash-finance-cards.json';

export default function DashboardAppPage() {
  const hasDashTable =!isAuthorizedRole(["OPERATIONS-C1", "CUSTOMS-L1"]);

  const [pieChartData, setPieChartData] = useState(null);
  const [monthlyReportData, setMonthlyReportData] = useState(null);
  const [cardsData, setCardsData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [productSegmentation, setProductSegmentation] = useState([]);
  const [productDateFilter, setProductDateFilter] = useState("current_month");

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    // Retrieve the string from localStorage

    fetchChartData();
    fetchMonthlyReportData();
    fetchCardData();
    fetchInvoiceData({});
  }, []);

  useEffect(() => {
    fetchProductSegmentData();
  }, [productDateFilter]);

    // Fetch data for charts and other components
    const fetchChartData = async () => {
      try {
        const pieChartResponse = await API.get('analytics/product_revenue_pie_chart');
        setPieChartData(pieChartResponse.data);
      } catch (error) {
        console.error('Error fetching pie chart data:', error);
      }
    };
  
    const fetchMonthlyReportData = async () => {
      try {
        const monthlyReportResponse = await API.get('analytics/combined_rolling_annual');
        setMonthlyReportData(monthlyReportResponse.data);
      } catch (error) {
        console.error('Error fetching monthly report data:', error);
      }
    };
  
    const fetchCardData = async () => {
      try {
        const cardResponse = await API.get('analytics/cards');
        setCardsData(cardResponse.data.cards?.data);
      } catch (error) {
        console.error('Error fetching card data:', error);
        // setCardsData(dummyData?.cards?.data);
      }

    };

    const fetchProductSegmentData = async () => {
      try {
        const respose = await API.get(`analytics/product_segmentation?date_filter=${productDateFilter}`);
        setProductSegmentation(respose.data.product_segmentation);
      } catch (error) {
        console.error('Error fetching card data:', error);
        // setCardsData(dummyData?.cards?.data);
      }

    };
  const fetchInvoiceData = async ({rows=rowsPerPage, pageNo=page}) => {
    try {
      const res = await API.get(`accounting/tax_invoices?records=${rows}&page=${pageNo}`);
      setInvoiceData(res.data?.invoices || []);
      setTotalPages(Math.ceil((res.data?.total_invoices || 0) / rowsPerPage));
    } catch (error) {
      console.error('Error fetching card data:', error);
    }
  };

  const handlePageChange = (action) => {
    let currentPage = page;
    if (action === 'next') {
      currentPage = Math.min(currentPage + 1, totalPages);
    } else {
      currentPage = Math.max(currentPage - 1, 1);
    }
    setPage(currentPage);
    fetchInvoiceData({pageNo: currentPage});
  };

  const handleChangeRowsPerPage = (event) => {
    const records = parseInt(event.target.value, 10);
    setRowsPerPage(records);
    setPage(1);
    fetchInvoiceData({rows:records, pageNo: 1});
  }

  const formatNumber = (num) => (num !== null ? num.toFixed(2) : '0.00');

  const pieChartLabels = pieChartData ? pieChartData.data.labels : [];
  const pieChartValues = pieChartData ? pieChartData.data.datasets[0].data.map(formatNumber) : [];
  const pieChartColors = pieChartData ? pieChartData.data.datasets[0].backgroundColor : [];

  const currentDate = new Date();
  const monthlyReportLabels = monthlyReportData
    ? monthlyReportData.data.labels
    : Array.from({ length: 12 }, (_, i) => {
        const date = new Date();
        date.setMonth(currentDate.getMonth() - (11 - i));
        return date.toLocaleString('default', { month: 'short', year: 'numeric' });
      });

  const monthlyReportRevenue = monthlyReportData
    ? monthlyReportData.data.datasets[0].data.map(formatNumber)
    : [];
  const monthlyReportLossGain = monthlyReportData
    ? monthlyReportData.data.datasets[1].data.map(formatNumber)
    : [];

  const tooltipFormatter = (tooltipItem) => `${tooltipItem.value} BWP`;

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container maxWidth="false">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Financial Summary View
        </Typography>

          {/* Executive View */ isAuthorizedRole(["EXECUTIVE-C1"]) && (
            < ExecutiveView
              cardsData={cardsData}
              productData={productSegmentation}
              periodFilter={productDateFilter}
              setPeriodFilter={setProductDateFilter}
            />
          )}

          {/* Finance Department View */ isAuthorizedRole(["FINANCE-C2", "FINANCE-L1", "DEMO"]) && (
            < FinanceView
              cardsData={cardsData}
              productData={productSegmentation}
              periodFilter={productDateFilter}
              setPeriodFilter={setProductDateFilter}
            />
          )}

          {/* Operations Department View */ isAuthorizedRole(["OPERATIONS-C2", "OPERATIONS-L1"]) && (
            < OperationsView cardsData={cardsData} />
          )}

          {/* Logistics Department View */ isAuthorizedRole(["LOGISTICS-C2", "LOGISTICS-L1"]) && (
            <>
            </>
          )}

          {/* Customs Department View */ isAuthorizedRole(["CUSTOMS-L1"]) && (
            <>
            </>
          )}

          {/* <Grid item size={{ xs: 12, sm: 6, md: 3 }}>
            <AppWidgetSummary title="Approved Orders" total={cardData?.approved} icon={'ant-design:check-circle-filled'} />
          </Grid>

          <Grid item size={{ xs: 12, sm: 6, md: 3 }}>
            <AppWidgetSummary title="Declined Orders" total={cardData?.declined} color="error" icon={'ant-design:close-circle-filled'} />
          </Grid>

          <Grid item size={{ xs: 12, sm: 6, md: 3 }}>
            <AppWidgetSummary title="Delivered Orders" total={cardData?.delivered} color="warning" icon={'ant-design:car-filled'} />
          </Grid>

          <Grid item size={{ xs: 12, sm: 6, md: 3 }}>
            <AppWidgetSummary title="Revenue Expected" total={cardData?.pastMonthRevenue} color="info" icon={'ant-design:shopping-filled'} />
          </Grid>

          <Grid item size={{ xs: 12, md: 6, lg: 8 }}>
            <AppWebsiteVisits
              title="Rolling Annual Revenue/(Loss/Gain)"
              subheader="Monthly revenue tracking"
              chartLabels={monthlyReportLabels}
              chartData={[
                {
                  name: 'Revenue Generated',
                  type: 'area',
                  fill: 'solid',
                  data: monthlyReportRevenue,
                },
                {
                  name: 'Loss/Gain Revenue',
                  type: 'area',
                  fill: 'gradient',
                  data: monthlyReportLossGain,
                },
              ]}
              tooltipFormatter={tooltipFormatter}
            />
          </Grid>

          <Grid item size={{ xs: 12, md: 6, lg: 4 }}>
            <AppCurrentVisits
              title="Product Revenue"
              chartData={pieChartValues.map((value, index) => ({
                label: pieChartLabels[index],
                value: parseFloat(value), // Ensure the values are numbers
              }))}
              chartColors={pieChartColors}
            />
          </Grid> */}

        {(hasDashTable ) && (
          <>
            <Typography variant="h5" sx={{ my: 2 }}>
              Recent Invoices
            </Typography>

            <InvoiceList />
          </>
        )}

      </Container>
    </>
  );
}
