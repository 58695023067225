/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment';
import dayjs from 'dayjs';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const CustomDateTimePicker = ({ label, handleChange, field, clear, setClear, minDate }) => {

  const [open,setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = React.useState(null);

  const errorMessage = React.useMemo(() => {
    switch (error) {
      case 'maxDate':
      case 'minDate': {
        return `Please ensure date is after ${minDate}`;
      }
      case 'invalidDate': {
        return 'Please enter a valid date';
      }
      default: {
        return '';
      }
    }
  }, [error]);

  useEffect(() => {
    if (clear) {
      setValue(null);
      setClear(false);
    }
  }, [clear]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker
          disableFuture
          label={label}
          value={value}
          minDate={dayjs(minDate || null)}
          format={'YYYY/MM/DD HH:mm A'}
          onChange={(e) => {
            handleChange(field, moment(e.$d).format('YYYY/MM/DD HH:mm:ss'));
            setValue(e);
          }}
          open={open}
          onClose={() => setOpen(false)}
          onError={(newError) => setError(newError)}
          slotProps={{
            textField: {
              onClick: () => setOpen(true),
              size: 'small',
              minWidth: '180px',
              helperText: errorMessage,
            },
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

CustomDateTimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  clear: PropTypes.bool.isRequired,
  setClear: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
}

export default CustomDateTimePicker
