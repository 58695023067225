import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid2'

import { ProductSegmentCard } from '../cards/ProductSegmentCard';
import GroupedStackedColumn from '../charts/GroupedStackedColumn';
// import { AreaChart } from '../charts/AreaChart';
import {
  transformTallyData,
  transformProductSegmentData,
  removeCardType,
  transformProductGroupedStack,
} from '../dash-util';
import { TallyCard } from '../cards/TallyCard';
import { Card } from '@mui/material';

const cardColors = ['#4caf50', '#ed6c02', '#1976d2']

export function ExecutiveView({ cardsData, regressionData, productData, periodFilter, setPeriodFilter  }) {

  const orderedCards = removeCardType(cardsData, 'Trucks Enroute')
  ?.sort((a, b) => a.value - b.value);

    const [excoGroupedStacked, setExcoGroupedStacked] = useState([]);
    const [showGroupedLegend, setShowGroupedLegend] = useState(false);

    useEffect(() => {
      const groupedStacked = transformProductGroupedStack(regressionData);
      setExcoGroupedStacked(groupedStacked);

      const showLegend = !groupedStacked.series.every((item) => item.data.length === 0);
      setShowGroupedLegend(showLegend);

    }, [cardsData, regressionData, productData, periodFilter]);

  return (
    <Grid container spacing={3}>
    <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Trucks Enroute'>
      <TallyCard
        isExecutive
        sx={{ height: '100%' }}
        data={transformTallyData(cardsData, 'Trucks Enroute', '#7b1fa2')}
      />
    </Grid>

      {orderedCards?.length > 0
        ? orderedCards.map((d, index) => (
          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key={d.type}>
            <TallyCard
              isExecutive
              data={transformTallyData(cardsData, d?.type, cardColors[index])}
            />
          </Grid>
        )) :
        [...Array(3)].map((x, i) => (
          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key={`place-holder-${i}`}>
            <Card sx={{ height: '100%' }}>

            </Card>
          </Grid>
        ))
      }

    <Grid size={{ xs: 8 }} key="grouped stacked">
      {/* <AreaChart /> */}
      <GroupedStackedColumn
        series={excoGroupedStacked.series}
        categories={excoGroupedStacked.categories}
        height={350}
        title={"Reservoir Quantities (m\u00B3)"}
        periodFilter={periodFilter}
        setPeriodFilter={setPeriodFilter}
        showLegend={showGroupedLegend}
      />
    </Grid>

    <Grid size={{ xs: 4 }} key="product segment">
      <ProductSegmentCard
        products={transformProductSegmentData(productData)}
        periodFilter={periodFilter}
        setPeriodFilter={setPeriodFilter}
        sx={{ height: '100%' }}
      />
    </Grid>
  </Grid>
  )
}

ExecutiveView.propTypes = {
  cardData: PropTypes.arrayOf(PropTypes.object).isRequired,
}
