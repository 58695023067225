import * as React from "react"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid2"
import { Box } from "@mui/material"
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

import { DASHBOARD_ICON_OPACITY_10 } from "../../../utils/constants"
import palette from "../../../theme/palette"

export function ProductCard({ data, isTally, litres, sx={} }) {

  return (
    <Card sx={{ height: '100%', ...sx }}>
      <CardContent>
        {isTally ? (
          <Typography variant="inherit">{data?.type}</Typography>
        ) : (
          <Stack
            direction="row"
            sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
            spacing={3}
          >
            <Stack
              spacing={1}
              sx={{ borderLeft: `5px solid ${data?.color}`, pl: 2, pt: 0 }}
            >
              <Box
                sx={{
                  backgroundColor: ` ${data?.color}${DASHBOARD_ICON_OPACITY_10}`,
                  height: "45px",
                  width: "45px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CurrencyExchangeIcon sx={{ color: `${data?.color}` }} fontSize="var(--icon-fontSize-lg)" />
              </Box>
              <Typography variant="inherit">{data?.type}</Typography>
            </Stack>
          </Stack>
        )}
        {data?.metadata ? (
          <Grid
            container
            spacing={3}
            sx={{
              mt: 2,
              padding: 1,
              backgroundColor: (litres ? 'white' : palette.grey[200]),
              borderRadius: "5px"
            }}
          >
            <Grid size={{ xs: 4 }} fontSize="12px" fontWeight="normal">
              <Typography sx={{ color: palette.grey[500] }} variant="inherit">
                &nbsp;50 PPM
              </Typography>
              {data.metadata.ppm50}&nbsp;{litres ? <>Ltrs</>: <>cm<sup>3</sup></>}
            </Grid>
            <Grid size={{ xs: 4 }} fontSize="12px" fontWeight="normal">
              <Typography sx={{ color: palette.grey[500] }} variant="inherit">
                &nbsp;ULP 93
              </Typography>
              {data.metadata.ulp93}&nbsp;{litres ? <>Ltrs</>: <>cm<sup>3</sup></>}
            </Grid>
            <Grid size={{ xs: 4 }} fontSize="12px" fontWeight="normal">
              <Typography sx={{ color: palette.grey[500] }} variant="inherit">
                &nbsp;ULP 95
              </Typography>
              {data.metadata.ulp95}&nbsp;{litres ? <>Ltrs</>: <>cm<sup>3</sup></>}
            </Grid>
          </Grid>
        ) : (
          <Typography sx={{ mt: 3, color: palette.grey[500] }} variant="subtitle1">
            {data?.value}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}
