import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid2'
import { Box, Button, Card, CardContent, Typography } from '@mui/material';

import palette from "../../../theme/palette"
import CustomModal from '../../../components/custom/CustomModal';
import { fNumber } from '../../../utils/formatNumber';

const InnerCard = ({ type, value, sx = {} }) => {
  return (
    <Card sx={{ height: '100%', border: 1, borderColor: palette.grey[500], ...sx }}>
      <CardContent>
        <Typography variant="inherit" sx={{ color: palette.grey[500] }}>{type}</Typography>
        <Typography variant="overline" sx={{ fontWeight: "bold", }}>{value}</Typography>
      </CardContent>
    </Card>
  );
}

const gridSize = { lg: 4, sm: 4, xs: 12 };

export const OrderCards = ({ orderData, openModal, handleCloseModal }) => {

  // console.log(orderData, openModal)
  const prefix = orderData?.is_client? 'client' : 'supplier';

  return (

    <>
      {orderData && (
        <CustomModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          modalTitle={"Order " + orderData?.order_id + " Summary"}
        >
          <>
            <Typography variant="inherit" sx={{ color: palette.grey[500] }}>{"Volume & Quantity in Litres"}</Typography>
            <Grid container spacing={3} sx={{ mt: 2 }}>
              <Grid size={{ ...gridSize }} key='Supplier Name'>
                <InnerCard type={"Supplier Name"} value={orderData?.company_name} />
              </Grid>
              <Grid size={{ ...gridSize }} key='Volume'>
                <InnerCard type={"Volume"} value={fNumber(orderData?.volume)} />
              </Grid>
              <Grid size={{ ...gridSize }} key='Unit Price'>
                <InnerCard type={"Unit/Price"} value={orderData?.unit_price} />
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ mt: 2 }}>
              <Grid size={{ ...gridSize }} key='Paid Qty'>
                <InnerCard type={"Paid Qty"} value={fNumber(orderData[prefix + "_litres_paid"])} />
              </Grid>
              <Grid size={{ ...gridSize }} key='Unpaid Qty'>
                <InnerCard type={"Unpaid Qty"} value={fNumber(orderData[prefix + "_litres_unpaid"])} />
              </Grid>
              <Grid size={{ ...gridSize }} key='Moved Qty'>
                <InnerCard type={"Moved Qty"} value={fNumber(orderData[prefix + "_litres_moved"])} />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
              <Button
                variant="outlined"
                sx={{ mt: 2, color: 'black', backgroundColor: palette.grey[200], border: palette.grey[200], }}
                onClick={handleCloseModal}
              >
                Close
              </Button>
            </Box>
          </>
        </CustomModal>
      )}
    </>
  )
}

OrderCards.propTypes = {
  orderData: PropTypes.arrayOf(PropTypes.object).isRequired,
}
