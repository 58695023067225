import React, { Component } from 'react';
import moment from 'moment';
import { Table, TableHead, TableBody, TableCell, TableRow, Paper, TableContainer } from '@mui/material';

import API from '../../data/api';
// import GlobalFilter from '../global-filter/GlobalFilter';
import CustomTablePagination from '../custom/CustomTablePagination';

export default class DeclinedOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      declinedOrdersList: [],
      page: 1,
      records: 15,
      totalRecords: 1,
      totalPages: null,
      recordsList: [5, 10, 15, 20],
    };
  }

  componentDidMount() {
    this.getDeclinedOrders(this.props.filterString);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.filterString !== this.props.filterString) {
      this.getDeclinedOrders(this.props.filterString);
    }
  }

  getDeclinedOrders = async (finalQuery = '') => {
    const { records, page } = this.state;
    let conTemp = `inventory/backoffice/declined_order_items?records=${records}&page=${page}`;

    if (finalQuery) {
      conTemp += finalQuery;
    }

    try {
      const res = await API.get(conTemp);
      if (res.status === 200) {
        const orders = res.data.orders;
        this.setState({
          declinedOrdersList: orders,
          totalPages: Math.min(res.data.total_pages, Math.ceil(orders?.length/this.state.records)),
          totalRecords: res.data.total_orders,
        });
      } else {
        console.error("Error fetching declined orders:", res.status);
      }
    } catch (error) {
      console.error("Error fetching declined orders:", error);
    }
  }

  nextPage(action) {
    const limit = this.state.totalPages;
    this.setState((prevState) => {
      let currentPage = prevState.page;

      if (action === 'next') {
        currentPage = Math.min(currentPage + 1, limit);
      } else {
        currentPage = Math.max(currentPage - 1, 1);
      }

      return { page: currentPage };
    }, () => {
      this.getDeclinedOrders(this.props.filterString);
    });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState(() => {
      return { records: parseInt(event.target.value, 10), page: 1 };
    }, () => {
      this.getDeclinedOrders(this.props.filterString);
    });
  }

  render() {
    return (
      <Paper>
      {/* <GlobalFilter sx={{ my: 1 }} onFilterChange={this.handleFilterChange} /> */}

      <TableContainer sx={{ mt: 2, width: '100%', overflowX: { xs: 'scroll', sm: 'auto' } }} >
      <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Order Item ID</TableCell>
              <TableCell align="right">Client Name</TableCell>
              <TableCell align="right">Product</TableCell>
              <TableCell align="right">Volume</TableCell>
              <TableCell align="right">Decline at</TableCell>
              <TableCell align="right">Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.declinedOrdersList.map((row) => (
              <TableRow
                key={row.order_item_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: 'rgba(39,226,245,0.27)' } }}>
                <TableCell component="th" scope="row">{row.order_item_id}</TableCell>
                <TableCell align="right">{row.company_name}</TableCell>
                <TableCell align="right">{row.fuel_type}</TableCell>
                <TableCell align="right">{row.volume}</TableCell>
                <TableCell align="right">{moment(row.declined_at).format('YYYY/MM/DD - HH:mm:ss')}</TableCell>
                <TableCell align="right">{row.reason}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>

        <CustomTablePagination
          rowsPerPage={this.state.records}
          recordsList={this.state.recordsList}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
          handleNextPage={this.nextPage.bind(this)}
          currentPage={this.state.page}
          totalPages={this.state.totalPages}
        />
      </Paper>
    );
  }
}
