import numeral from 'numeral';

// ----------------------------------------------------------------------

export function isValidNumber(value) {
  try {
    if (typeof value === 'number') {
      return true;
    }
  }
  catch (error) {
  }

  return false;
}
export function fNumber(number) {
  return isValidNumber(number)? numeral(number).format(): number;
}

export function fCurrency(number) {
  const format = isValidNumber(number) ? numeral(number).format('$0,0.00') : number;

  return result(format, '.00');
}

export function fPercent(number) {
  const format = isValidNumber(number) ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = isValidNumber(number) ? numeral(number).format('0.00a') : number;

  return result(format, '.00');
}

export function fData(number) {
  const format = isValidNumber(number) ? numeral(number).format('0.0 b') : number;

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}


export function scaleNumber (number) {
  if (!isValidNumber(number)){
    return number;
  }

  if (number < 1e3) return number;
  if (number >= 1e3 && number < 1e6) return +(number / 1e3).toFixed(1) + "K";
  if (number >= 1e6 && number < 1e9) return +(number / 1e6).toFixed(1) + "M";
  if (number >= 1e9 && number < 1e12) return +(number / 1e9).toFixed(1) + "B";
  if (number >= 1e12) return +(number / 1e12).toFixed(1) + "T";
};