import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card, } from '@mui/material';
import Grid from '@mui/material/Grid2'

import { TotalCard } from '../dashboard/cards/TotalCard';
import {
  colorCodes,
  cardColors,
  findCardTypeValue,
  transformExcusionTally,
  transformTallyData,
} from '../dashboard/dash-util';
import { isAuthorizedRole } from '../../utils/authUtil';
import { ProductCard } from '../dashboard/cards/ProductCard';
import { TotalProductCard } from '../dashboard/cards/TotalProductCard';
import { TallyCard } from '../dashboard/cards/TallyCard';
import { ComparativeCard } from '../dashboard/cards/ComparativeCard';

export const SummaryCards = ({ cardsData, isInvoices }) => {

  useEffect(() => {

  }, [cardsData, isInvoices]);

  return (
    <>
      {/* Chief Executive Officer */ isAuthorizedRole(["EXECUTIVE-C1"]) && (
        (cardsData && cardsData?.length !== 0) ? (
          <Grid container spacing={3}>
            {/* <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Total Orders'>
              <ProductCard
                isTally={true}
                sx={{ height: '100%' }}
                data={findCardTypeValue(cardsData, 'Total Orders')}
              />
            </Grid> */}
            {cardsData
              ?.sort((a, b) => a?.type.localeCompare(b?.type))
              ?.map((data, index) => (
                <Grid size={{ lg: 3, sm: 6, xs: 12 }} key={data.type + index}>
                  <TallyCard
                    isExecutive
                    data={transformTallyData(cardsData, data?.type, cardColors[index])}
                    showIcon={false}
                  />
                </Grid>
              ))}
          </Grid>
        ) :
          (<Grid container spacing={3}>
            {[...Array(4)].map((x, i) => (
              <Grid size={{ lg: 3, sm: 6, xs: 12 }} key={`summary-holder-${i}`}>
                <Card sx={{ height: '100px' }}>

                </Card>
              </Grid>
            ))}
          </Grid>)
      )}

      {/* Chief Finance Officer */ isAuthorizedRole(["FINANCE-C2", "DEMO"]) && (
        <Grid container spacing={3}>
          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Buy Orders'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Buy Orders', colorCodes[0])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Sells'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Sell Orders', colorCodes[1])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Paid Invoices'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Paid Invoices', colorCodes[0])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Unpaid Invoices'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Unpaid Invoices', colorCodes[1])}
            />
          </Grid>
        </Grid>
      )}
      {/* Finance Accountant */ (isAuthorizedRole(["FINANCE-L1"]) && isInvoices) && (
        <Grid container spacing={3}>
          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Total Invoices'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Total Invoices', colorCodes[2])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Paid Invoices'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Paid Invoices', colorCodes[0])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Unpaid Invoices'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Unpaid Invoices', colorCodes[1])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Total on Credit'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Total on Credit', colorCodes[3])}
            />
          </Grid>
        </Grid>
      )}
      {/* Finance Accountant */ (isAuthorizedRole(["FINANCE-L1"]) && !isInvoices) && (
        <Grid container spacing={3}>
          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Total Orders'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Total Orders', colorCodes[2])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Unapproved Orders'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Unapproved Orders', colorCodes[3])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Declined Orders'>
            <TotalCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Declined Orders', colorCodes[1])}
            />
          </Grid>

          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Total Product Delivered'>
            <TotalProductCard
              sx={{ height: '100%' }}
              data={transformTallyData(cardsData, 'Total Product Delivered', colorCodes[0])}
            />
          </Grid>
        </Grid>
      )}
      {/* Operations Department */ isAuthorizedRole(["OPERATIONS-C2", "OPERATIONS-L1"]) && (
        <Grid container spacing={3}>
          {cardsData?.sort((a, b) => a?.type.localeCompare(b?.type))
            ?.map((data, index) => (
              <Grid size={{ lg: 4.5, sm: 6, xs: 12 }} key={data.type + index}>
                <TotalCard
                  sx={{ height: '100%' }}
                  data={data}
                />
              </Grid>
            ))}
        </Grid>
      )}
      {/* Logistics Department */ isAuthorizedRole(["LOGISTICS-C2", "LOGISTICS-L1"]) && (
        <Grid container spacing={3}>
          {cardsData?.sort((a, b) => a?.value - b?.value)
            ?.map((data, index) => (
              <Grid size={{ lg: 4.5, sm: 6, xs: 12 }} key={data.type + index}>
                <ProductCard
                  sx={{ height: '100%' }}
                  data={data}
                />
              </Grid>
            ))}
        </Grid>
      )}
      {/* Logistics Department */ isAuthorizedRole(["CUSTOMS-L1"]) && (
        <Grid container spacing={3}>
        {cardsData?.sort((a, b) => a?.value - b?.value)
          ?.map((data, index) => (
            <Grid size={{ lg: 3, sm: 6, xs: 12 }} key={data.type + index}>
              <ComparativeCard
                sx={{ height: '100%' }}
                data={{ ...data, color: cardColors[data.type] }}
              />
            </Grid>
          ))}
      </Grid>
    )}
    </>
  )
}

SummaryCards.propTypes = {
  cardData: PropTypes.arrayOf(PropTypes.object).isRequired,
  isInvoices: PropTypes.bool.isRequired,
}
