import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {
  Button,
  FormControl,
  MenuItem,
  Paper,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import API from '../../../data/api';
import CustomModal from '../../custom/CustomModal';
import { CustomSelect } from '../../custom/CustomSelect';
import { extractFormValues, isNotNullValue, isNullValue, validateForm } from '../../../utils/formUtil';
import { AllocatedBox, AvailableBox, OrderBox, SupplierBox } from '../../ui/styled-views';

const initAllocation = {
  transporter: { value: '', required: true, error: false, errorMessage: 'Please allocate transport company' },
  supplier: { value: '', required: true, error: false, errorMessage: 'Please allocate suplier' },
};
const LogisticsAllocation = ({
  subOrder,
  suppliers,
  transportCompanies,
  openModal,
  handleCloseModal,
  handleAllocationCallback,
}) => {

  const [submitted, setSubmitted] = useState(false);
  const [allocation, setAllocation] = useState({ ...initAllocation });
  const [subOrderId, setSubOrderId] = useState(null);
  const [allocationTitle, setAllocationTitle] = useState('');

  useEffect(() => {

    setSubOrderId(subOrder?.order_id || subOrder?.suborder_id)
    setAllocation({
        ...initAllocation,
        supplier: {
          ...initAllocation.supplier,
          value: subOrder?.supplier_id || '',
        },
        transporter: {
          ...initAllocation.transporter,
          value: subOrder?.transport_id || '',
        },
    })

    const action = (subOrder?.supplier_id && subOrder?.transport_id) ? 'Update ' : 'New';
    const _allocTitle = `${action || ''} Logistics Allocation for Order ${subOrder?.order_id || subOrder?.suborder_id || ''} (${subOrder?.fuel_type})`;
    setAllocationTitle(_allocTitle);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subOrder, suppliers, transportCompanies, openModal]);

  // eslint-disable-next-line no-unused-vars
  const fetchInitialData = async () => {
    if (!subOrder) return;

    try {
      // eslint-disable-next-line no-unused-vars
      const [transportersResponse, suppliersResponse, depotsResponse] = await Promise.all([
        API.get('inventory/backoffice/logistics'),
        API.get(`inventory/backoffice/valid_suppliers?product_id=${subOrder.product_id}&current_volume=${subOrder.volume}`),
        API.get('inventory/backoffice/depots?depot_type=storage')
      ]);

    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };

  const handleSubmitAllocation = async () => {

    // If any of the inputs or required parameters is empty, return
    if (!validateForm(setAllocation, allocation) || isNullValue(subOrderId)) {
      return;
    }

    const { transporter, supplier } = extractFormValues(allocation);
    const selectedSupplier = suppliers?.find(s => s.supplier_id === supplier);
    let endpoint = `inventory/backoffice/allocate/${subOrderId}/${transporter}/${supplier}`
      .concat(`?supplier_order_id=${selectedSupplier?.order_id}`)
      .concat(`&supplier_order_item_id=${selectedSupplier?.order_item_id}`)

    setSubmitted(true);
    try {
      const response = await API.patch(endpoint, '');

      if (response.status === 200 || response.status === 206) {
        handleAllocationCallback();
      } else {
        console.error('Error creating suborder allocation: ', response.status);
      }
    } catch (error) {
      console.error('Error creating suborder allocation: ', error);
    }
    setSubmitted(false);
  };

  // eslint-disable-next-line no-unused-vars
  const disableSupplierUpdate = () => {
    if (isNotNullValue(subOrder?.supplier_id)) {
      return suppliers?.filter(s => s.supplier_id === subOrder?.supplier_id)?.length > 0
    }
    return false;
  }

  const handleInputChange = (field, value, error = false) => {
    const _allocation = {
      ...allocation,
      [field]: {
        ...allocation[field],
        value: value,
        error: isNullValue(value) || error,
      },
    }
    setAllocation(_allocation);
  };

  return (
    <Paper>
      <CustomModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        modalTitle={`${allocationTitle}`}
        modalIcon='transport'
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <img style={{ height: 100 }}
            alt="Logistics"
            src="/assets/images/truck-tanker-icon.jpg"
          />
        </Box>

        <FormControl fullWidth sx={{ my: 2 }}>
          <CustomSelect
            labelId="supplier-select-label"
            label={"Supplier"}
            value={allocation.supplier.value}
            onChange={(e) => handleInputChange('supplier', e.target.value)}
            required={allocation.supplier.required}
            error={allocation.supplier.error}
            helperText={allocation.supplier.error && allocation.supplier.errorMessage}
            disabled={isNotNullValue(subOrder?.supplier_id)}
          >
            <MenuItem value="" disabled>
              <em>Select Supplier</em>
            </MenuItem>
            {suppliers?.map((supplier) => (
              <MenuItem key={"supplier-order-" + supplier.supplier_id}
                value={supplier.supplier_id}
              >
                <SupplierBox title="Supplier Name">{supplier.supplier_name}</SupplierBox>
                <AvailableBox title="Available Volume">{supplier.available_volume}</AvailableBox>
                <AllocatedBox title="Allocated Volume">{supplier.allocated_volume}</AllocatedBox>
                <OrderBox title="Order Item ID">{supplier.order_item_id}</OrderBox>
              </MenuItem>
            ))}
          </CustomSelect>
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <CustomSelect
            labelId="transporter-select-label"
            label={"Transporter"}
            value={allocation.transporter.value}
            onChange={(e) => handleInputChange('transporter', e.target.value)}
            required={allocation.transporter.required}
            error={allocation.transporter.error}
            helperText={allocation.transporter.error && allocation.transporter.errorMessage}
          >
            <MenuItem value="" disabled>
              <em>Select Transport Company</em>
            </MenuItem>
            {transportCompanies?.map((trans) => (
              <MenuItem key={'transport-' + trans.company_id} value={trans.company_id}>
                {trans.company_name}
              </MenuItem>
            ))}
          </CustomSelect>
        </FormControl>

        <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
          <Button
            variant="contained"
            color="inherit"
            onClick={handleCloseModal}
          >
            Cancel
          </Button>

          <LoadingButton
            variant='contained'
            sx={{ ml: 2, }}
            type="submit"
            loading={submitted}
            onClick={handleSubmitAllocation}
          >
            Submit
          </LoadingButton>

        </Box>
      </CustomModal>
    </Paper>
  );
}

LogisticsAllocation.propTypes = {

  /** Selected subOrder details */
  subOrder: PropTypes.object.isRequired,

  /** List of suitable suppliers */
  suppliers: PropTypes.array.isRequired,

  /** List of suitable transport companies */
  transportCompanies: PropTypes.array.isRequired,

  /** Flag to toggling openning of logistics approval modal */
  openModal: PropTypes.bool.isRequired,

  /** Callback function when closing logistics approval modal */
  handleCloseModal: PropTypes.func.isRequired,

  /** Callback function when allocating logistics for an order */
  handleAllocationCallback: PropTypes.func.isRequired,

}

export default LogisticsAllocation
