import { TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import React from 'react'

export const FormItemlGrid = ({ label, value, disabled, required, children }) => {
  return (
    <Grid container spacing={3} sx={{ mt: 1 }}>
      <Grid size={{ xs: 3 }} key='status' sx={{ my: 'auto' }}>
        <Typography variant="inline">{`${label}${required ? '*' : ''}`}</Typography>
      </Grid>
      <Grid size={{ xs: 9 }} key='title'>
        {children ? children : <TextField value={value} variant="outlined" disabled={disabled} fullWidth />}
      </Grid>
    </Grid>
  )
}
