import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid2'

import { ProgressCard } from '../cards/ProgressCard';
import { LabeledProgressCard } from '../cards/LabeledProgressCard';
import { ProductSegmentCard } from '../cards/ProductSegmentCard';
import { AreaChart } from '../charts/AreaChart';
import {
  transformProgressData,
  transformBulkOrderBreakdownData,
  transformProductSegmentData,
} from '../dash-util';

export const FinanceView = ({ cardsData }) => {
  return (
    <Grid container spacing={3}>
      <Grid size={{ lg: 2.4, sm: 6, xs: 12 }} key='Revenue Expected'>
        <ProgressCard
          sx={{ height: '100%' }}
          data={transformProgressData(cardsData, 'Revenue Expected')}
        />
      </Grid>

      <Grid size={{ lg: 2.4, sm: 6, xs: 12 }} key='Revenue Loss'>
        <ProgressCard
          sx={{ height: '100%' }}
          data={transformProgressData(cardsData, 'Revenue Loss')}
        />
      </Grid>

      <Grid size={{ lg: 2.4, sm: 6, xs: 12 }} key='Revenue Generated'>
        <ProgressCard
          sx={{ height: '100%' }}
          data={transformProgressData(cardsData, 'Revenue Generated')}
        />
      </Grid>

      <Grid size={{ lg: 2.4, sm: 6, xs: 12 }} key='Performance Score'>
        <ProgressCard
          sx={{ height: '100%' }}
          data={transformProgressData(cardsData, 'Performance Score')}
        />
      </Grid>

      <Grid size={{ lg: 2.4, sm: 6, xs: 12 }} key={"Bulk-Breakdown"}>
        <LabeledProgressCard
          isOpenTooltip
          data={transformBulkOrderBreakdownData(cardsData)}
          sx={{ height: '100%' }}
        />
      </Grid>

      <Grid size={{ xs: 8 }} key="stakked-product-segment">
        <AreaChart />
      </Grid>

      <Grid size={{ xs: 4 }} key="product segment">
        <ProductSegmentCard
          products={transformProductSegmentData(cardsData)}
          sx={{ height: '100%' }}
        />
      </Grid>
    </Grid>
  )
}

FinanceView.propTypes = {
  cardData: PropTypes.arrayOf(PropTypes.object).isRequired,
}
