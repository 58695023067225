import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import {
  TableCell,
  TableRow,
  Paper,
  TableSortLabel,
  IconButton,
  Tooltip,
} from '@mui/material';

import API from '../../data/api';
import CustomTablePagination from '../custom/CustomTablePagination';
// import palette from '../../theme/palette';
import { isNotNullValue, isNullValue } from '../../utils/formUtil';
import { Beenhere, BusAlert, DirectionsBus } from '@mui/icons-material';
import LogisticsApproval from './modals/LogisticsApproval';
import LogisticsAllocation from './modals/LogisticsAllocation';

class UnallocatedOrder extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      records: 15,
      totalRecords: 1,
      totalPages: null,
      recordsList: [5, 10, 15, 20],
      filterString: '',
      sortField: 'order_item_id',
      sortDirection: 'asc',
      subOrders: [],
      selectedOrder: null,
      suppliers: [],
      depots: [],
      submitted: false,
      openAllocateLogistics: false,
      openApproveLogistics: false,
    };

  }

  componentDidMount() {
    this.getSubOrders(this.props.filterString);
    this.getDepots();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filterString !== this.props.filterString || prevState.filterString !== this.state.filterString) {
      this.getSubOrders(this.props.filterString);
      this.getDepots();
    }
  }

  getSubOrders = async (finalQuery = '') => {
    try {
      const { records, page } = this.state;
      let endpoint = `inventory/backoffice/order_items?records=${records}&page=${page}`;
      if (finalQuery) {
        endpoint += finalQuery;
      }

      const response = await API.get(endpoint);
      if (response.status === 200) {
        const subOrders = response.data?.orders || [];
        this.setState({
          subOrders: subOrders,
          totalPages: Math.min(response.data.total_pages, Math.ceil(subOrders?.length / this.state.records)),
          totalRecords: response.data.total_suborders,
        });
      } else {
        console.error('Error fetching suborders:', response.status);
      }
    } catch (error) {
      console.error('Error fetching suborders:', error);
    }
  };

  getSuppliers = async (order = this.state.selectedOrder) => {
    const productID = order?.product_id;
    const volume = order?.volume;
    if (isNullValue(order) || isNullValue(volume) || isNullValue(productID)) {
      return;
    }

    try {
      const response = await API.get(`inventory/backoffice/valid_suppliers?product_id=${productID}&current_volume=${volume}`);
      if (response.status === 200) {
        this.setState({ suppliers: response.data });
      } else {
        console.error('Error fetching suppliers: ', response.status);
      }
    } catch (error) {
      console.error('Error fetching suppliers: ', error);
    }
  };

  getDepots = async () => {
    try {
      const response = await API.get('inventory/backoffice/depots?depot_type=storage');
      if (response.status === 200) {
        this.setState({ depots: response.data.depots });
      } else {
        console.error('Error fetching depots: ', response.status);
      }
    } catch (error) {
      console.error('Error fetching depots: ', error);
    }
  };

  formatSubOrderDetails = () => {
    const { selectedOrder } = this.state;

    return {
      id: selectedOrder?.order_id || '',
      supplier_id: selectedOrder?.supplier_id || '',
      transport_id: selectedOrder?.transport_id || '',
      product_id: selectedOrder?.product_id || '',
      volume: selectedOrder?.volume,
    }
  }

  handleAllocateLogistics = (subOrder=null) => {

    if (isNotNullValue(subOrder)) {
      const action = (subOrder?.supplier_id && subOrder?.transport_id) ? 'Update ' : 'New';
      this.getSuppliers(subOrder);
      this.setState({
        allocationTitle: `${action || ''} Logistics Allocation - Order ${subOrder?.order_item_id || ''}`,
        openAllocateLogistics: true,
        selectedOrder: subOrder,
      });
    } else {
      this.setState({
        openAllocateLogistics: false,
        selectedOrder: null,
        submitted: false,
        allocationTitle: '',
      });
    }
  };

  handleAllocationCallback = () => {
    this.handleAllocateLogistics(null);
    this.props.setUpdateStats(true);
    this.getSubOrders(this.props.filterString);
  }

  handleApproveLogistics = (subOrder = null, updated = false) => {
    this.setState({
      openApproveLogistics: isNotNullValue(subOrder),
      selectedOrder: subOrder,
    });

    if (isNotNullValue(subOrder)){
      this.getSuppliers(subOrder);
    } else if (updated) {
      this.getSubOrders(this.props.filterString);
    }

  };

  handleSort = (field) => {
    const { sortField, sortDirection } = this.state;
    let newDirection = 'asc';
    if (sortField === field && sortDirection === 'asc') {
      newDirection = 'desc';
    }
    this.setState(
      {
        sortField: field,
        sortDirection: newDirection,
      },
      () => {
        this.getSubOrders(this.props.filterString);
      }
    );
  };

  handleNextPage(action) {
    const limit = this.state.totalPages;
    this.setState(
      (prevState) => {
        let currentPage = prevState.page;

        if (action === 'next') {
          currentPage = Math.min(currentPage + 1, limit);
        } else {
          currentPage = Math.max(currentPage - 1, 1);
        }

        return { page: currentPage };
      },
      () => {
        this.getSubOrders(this.props.filterString);
      }
    );
  }

  handleChangeRowsPerPage = (event) => {
    this.setState(() => {
      return { records: parseInt(event.target.value, 10), page: 1 };
    }, () => {
      this.getSubOrders(this.props.filterString);
    });
  }

  render() {
    const { sortField, sortDirection, selectedOrder, openAllocateLogistics, openApproveLogistics } = this.state;

    return (
      <Paper>
        <TableContainer sx={{ mt: 2, width: '100%', overflowX: { xs: 'scroll', sm: 'auto' } }} >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell>
                  {this.state.warningOrders?.length > 0 && <span style={{ color: 'red' }}>ℹ️</span>}
                </TableCell> */}
                <TableCell sortDirection={sortField === 'order_item_id' ? sortDirection : false}>
                  <TableSortLabel
                    active={sortField === 'order_item_id'}
                    direction={sortField === 'order_item_id' ? sortDirection : 'asc'}
                    onClick={() => this.handleSort('order_item_id')}
                  >
                    Order Number
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortField === 'company_name' ? sortDirection : false}>
                  <TableSortLabel
                    active={sortField === 'company_name'}
                    direction={sortField === 'company_name' ? sortDirection : 'asc'}
                    onClick={() => this.handleSort('company_name')}
                  >
                    Client Name
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortField === 'fuel_type' ? sortDirection : false}>
                  <TableSortLabel
                    active={sortField === 'fuel_type'}
                    direction={sortField === 'fuel_type' ? sortDirection : 'asc'}
                    onClick={() => this.handleSort('fuel_type')}
                  >
                    Product
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortField === 'volume' ? sortDirection : false}>
                  <TableSortLabel
                    active={sortField === 'volume'}
                    direction={sortField === 'volume' ? sortDirection : 'asc'}
                    onClick={() => this.handleSort('volume')}
                  >
                    Volume
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortField === 'created_date' ? sortDirection : false}>
                  <TableSortLabel
                    active={sortField === 'created_date'}
                    direction={sortField === 'created_date' ? sortDirection : 'asc'}
                    onClick={() => this.handleSort('created_date')}
                  >
                    Created Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.subOrders?.map((row) => (
                <React.Fragment key={row.order_item_id}>
                  <TableRow
                    sx={{
                      backgroundColor: row.is_client ? 'transparent' : 'lightyellow',
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:hover': { backgroundColor: 'rgba(39,226,245,0.27)' },
                    }}
                    selected={selectedOrder?.order_item_id === row.order_item_id}
                    // onClick={() => this.handleAllocateLogistics(row)}
                  >
                    {/* <TableCell component="th" scope="row">
                      {this.state.warningOrders?.includes(row.order_item_id) && (<span style={{ color: 'red' }}>⚠️</span>)}
                    </TableCell> */}
                    <TableCell component="th" scope="row">
                      {row.order_item_id}
                    </TableCell>
                    <TableCell>{row.company_name}</TableCell>
                    <TableCell>{row.fuel_type}</TableCell>
                    <TableCell>{row.volume}</TableCell>
                    <TableCell>
                      {moment(row.created_date).format('YYYY/MM/DD - HH:mm:ss')}
                    </TableCell>
                    <TableCell align="right">
                      {(row.supplier_id && row.transport_id) ? (
                        <>
                        <Tooltip title={"Update Allocation"}>
                          <IconButton
                            onClick={() => this.handleAllocateLogistics(row)}
                          >
                            <BusAlert color={'info'} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Approve Allocation"}>
                          <IconButton
                            onClick={() => this.handleApproveLogistics(row)}
                          >
                            <Beenhere color={'action'} />
                          </IconButton>
                        </Tooltip>
                        </>
                      ) : (
                        <Tooltip title={"New Allocate"}>
                          <IconButton
                            onClick={() => this.handleAllocateLogistics(row)}
                          >
                            <DirectionsBus color={'inherit'} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomTablePagination
          rowsPerPage={this.state.records}
          recordsList={this.state.recordsList}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
          handleNextPage={this.handleNextPage.bind(this)}
          currentPage={this.state.page}
          totalPages={this.state.totalPages}
        />

        <LogisticsAllocation
          subOrder={this.state.selectedOrder}
          suppliers={this.state.suppliers}
          transportCompanies={this.props?.transportCompanies}
          depots={this.state.depots}
          openModal={openAllocateLogistics}
          handleCloseModal={() => this.handleAllocateLogistics()}
          handleAllocationCallback={() => this.handleAllocationCallback()}
        />

        <LogisticsApproval
          subOrder={this.state.selectedOrder}
          suppliers={this.state.suppliers}
          transportCompanies={this.props?.transportCompanies}
          depots={this.state.depots}
          openModal={openApproveLogistics}
          handleCloseModal={() => this.handleApproveLogistics()}
          handleApprovalCallback={() => this.handleApproveLogistics(null, true)}
        />

      </Paper>
    );
  }
}

UnallocatedOrder.propTypes = {
  filterString: PropTypes.string.isRequired,
  setUpdateStats: PropTypes.func.isRequired,
};

export default UnallocatedOrder;
