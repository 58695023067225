import { useNavigate } from 'react-router-dom';
// @mui
import { Container, Link, Typography } from '@mui/material';
// sections
import RegisterForm from '../sections/auth/login/RegisterForm';
import { LogoImage, StyledContent, StyledRoot } from '../components/ui/styled-views';
// hooks
// components

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function RegisterPage() {
  const navigate = useNavigate();

  return (
    <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <LogoImage
                alt="Honye Badger"
                src="/assets/images/hb-logo-only-dark.png"
            />
            <Typography
              component="h1"
              variant="h4"
              sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)', textAlign: 'center', mx: 2  }}
            >
              Welcome to Honey Badger
            </Typography>
            <Typography sx={{ textAlign: 'center', m: 2 }}>
              Have an account?{' '}
              <span>
                <Link
                  sx={{ alignSelf: 'center', cursor: 'default' }}
                  variant="body2"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Log in
                </Link>
              </span>
            </Typography>
            <RegisterForm />
          </StyledContent>
        </Container>
      </StyledRoot>
  );
}
