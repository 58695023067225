
export const MAX_FILE_SIZE_MB = 100; // MB

export const DASHBOARD_ICON_OPACITY_10 = '1A'; // 10%
export const DASHBOARD_ICON_OPACITY_20 = '33'; // 20%
export const DASHBOARD_ICON_OPACITY_30 = '4D'; // 30%
export const DASHBOARD_ICON_OPACITY_40 = '66'; // 40%
export const DASHBOARD_ICON_OPACITY_50 = '80'; // 50%


export const HEX_OPACITY = {
   10: '1A',
   20: '33',
   30: '4D',
   40: '66',
   50: '80',
   60: '99',
   70: 'B3',
   80: 'CC',
   90: 'E6',
   100: 'E6'
}

export const WHITE_SMOKE_COLOR= '#F5F5F5';
export const FUEL_PRODUCT_COLORS = {
    upl95: '#3D5AFE',
    upl93: '#4CAF50',
    ppm50: '#CDDC39',
}

export const PRODUCT_SEGMENTATION_FILTERS = [
    { key: "current_month", value: "Current Month" },
    { key: "last_month", value: "Last Month" },
    { key: "past_60_days", value: "Past 60 Days" },
    { key: "past_90_days", value: "Past 90 Days" },
]

export const MODAL_WIDTH = {
    small: {
        xs: 400,
        sm: 500,
    },
    medium: {
        xs: 500,
        sm: 600,
    },
}

export const filePreviewStyle = {
    minWidth: {
      xs: '50%',
    },
    zIndex: 1,
    minHeight: '650px',
    // overflowX: 'hidden',
    // overflowY: 'scroll'
  }

