import { Alert, Snackbar, } from "@mui/material";


export default function CustomSnackBar({ snackbarOpen, handleSnackbarClose, snackbarMessage }) {

  return (
    <Snackbar
      style={{ marginBottom: '2em' }}
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{ marginTop: '2.5em' }}
    >
      <Alert onClose={handleSnackbarClose} severity={snackbarMessage.type} sx={{ width: '100%' }}>{snackbarMessage.message}</Alert>
    </Snackbar>
  )

}
