import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    IconButton,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    Tooltip,
    Paper,
    Button,
    Box,
    MenuItem,
    FormControl,
} from '@mui/material';
import Grid from '@mui/material/Grid2'
import { Download, Eye, FileArrowUpFill } from 'react-bootstrap-icons';

import API from '../../data/api';
// import GlobalFilter from '../global-filter/GlobalFilter';
import CustomTablePagination from '../custom/CustomTablePagination';
import { isAuthorizedRole } from '../../utils/authUtil';
import palette from '../../theme/palette';
import { isNotNullValue, isNullValue } from '../../utils/formUtil';
import { FolderCopy, FolderOff, WorkHistory } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FormItemlGrid } from '../ui/FormItemlGrid';
import CustomModal from '../custom/CustomModal';
import FileDragzone from '../ui/FileDragzone';
import CustomPdfViewer from '../custom/CustomPdfViewer';
import { filePreviewStyle, MAX_FILE_SIZE_MB } from '../../utils/constants';
import { downloadFile } from '../../utils/fileUtils';
import { CustomSelect } from '../custom/CustomSelect';
import { OrderNominationSummary } from './modals/OrderNominationSummary';
import { OrderAllocationSummary } from './modals/OrderAllocationSummary';
import { fNumber } from '../../utils/formatNumber';

const initDocumentForm = {
  value: '', required: true, error: false, errorMessage: 'Please specify a document type'
}

const documentTypes = [
  { key: 'COMMERICAL INVOICE', value: 'Commercial Invoice'},
  { key: 'NOMINATION', value: 'Nomination'},
  { key: 'RELEASE FORM', value: 'Release Form'},
  { key: 'TRANSIT CLEARANCE', value: 'Transit Clearance'},
  { key: 'SARS CUSTOMS', value: 'SARS Customs'},
]

export default class AllocatedOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allocatedOrders: [],
            page: 1,
            records: 15,
            totalRecords: 1,
            totalPages: null,
            recordsList: [5, 10, 15, 20],
            expandedRow: null,
            selectedOrder: null,
            processedOrder: {
                openModal: false,
                orderItem: null,
                isTaxInvoice: false,
            },
            fileObjects: [],
            filePreviewUrl: null,
            isFilePreview: false,
            modalStyle: {},
            upload: { ...initDocumentForm },
            selectedSubDocument: null,
            openSubDocumentModal: false,
            viewAllocationSummary: false,
            viewNominationSummary: false,
        };
    }

    componentDidMount() {
        this.getAllocatedOrders(this.props.filterString);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.filterString !== this.props.filterString) {
          this.getAllocatedOrders(this.props.filterString);
        }
    }
    
    getAllocatedOrders = async (finalQuery = '') => {
        const { records, page } = this.state;
        let conTemp = `inventory/backoffice/allocated_suborders?records=${records}&page=${page}`;

        if (finalQuery) {
          conTemp += finalQuery;
        }

        try {
            const res = await API.get(conTemp);
            if (res.status === 200) {
                const suborders = res.data.suborders;
                this.setState({
                    allocatedOrders: suborders,
                    totalPages: Math.min(res.data.total_pages, Math.ceil(suborders?.length/this.state.records)),
                    totalRecords: res.data.total_suborders,
                });
            } else {
                console.error("Error fetching allocated orders");
            }
        } catch (error) {
            console.error("Error fetching allocated orders:", error);
        }
    }


  getSubOrderDocuments = async (subOrder_id) => {
    if (!subOrder_id) {
      return;
    }

    try {
      const response = await API.get(`inventorylogistics/get_logistics_forms?suborder_id=${subOrder_id}`);
      if (response.status === 200) {
        this.setState({ subOrderDocuments: response.data?.documents });
      } else {
        console.error('Error fetching trailers: ', response.status);
      }
    } catch (error) {
      console.error('Error fetching trailers: ', error);
    }
  };


  handleDocumentSubmit = async () => {

    const {  fileObjects, selectedOrder, upload } = this.state;
    if (fileObjects.length === 0 || fileObjects[0] === null || isNullValue(selectedOrder?.suborder_id) || isNullValue(upload?.value)) {
      return;
    }

    let endPoint = `inventory/logistics/upload_logistics_form?suborder_id=${selectedOrder?.suborder_id}`
      .concat(`&form_type=${upload?.value}`)

    const formData = new FormData();
    formData.append('file', fileObjects[0]);

    this.setState({ submitted: true, });
    try {
      const res = await API.post(`${endPoint}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (res.status === 200 || res.status === 201) {
        this.handleDocumentUploadModal(null, false);
        this.props.setUpdateStats(true);
        this.getSubOrderDocuments();
      } else {
        console.log("Error:", res.data);
      }
    } catch (error) {
      console.error('Error uploading document:', error);
    }
    this.setState({ submitted: false, });
  }

  handleGenerateReport = async (suborder_id) => {
    try {
      const generateSuborderReport = await API.get(`inventory/logistics/generate_suborder_report/${suborder_id}`);
      if (generateSuborderReport.status === 200) {
        console.log("Report successfully generated");
      } else {
        console.error('Error generating suborder report:', generateSuborderReport.status);
      }
    } catch (error) {
      console.error('Error reendering QR code:', error);
    }
  };

  setValidatedNomination = (_nomination) => {
    this.setState({
      nomination: { ..._nomination },
    });
  }

    nextPage = (action) => {
        const { totalPages } = this.state;
        this.setState((prevState) => {
            let currentPage = prevState.page;

            if (action === 'next') {
                currentPage = Math.min(currentPage + 1, totalPages);
            } else {
                currentPage = Math.max(currentPage - 1, 1);
            }

            return { page: currentPage };
        }, this.getAllocatedOrders);
    }

    setSubOrder = async (subOrder) => {
      if (this.state.selectedOrder?.suborder_id === subOrder?.suborder_id) {
        this.setState({ selectedOrder: null });
      } else {
        this.setState({ selectedOrder: subOrder });
        this.getSubOrderDocuments(subOrder?.suborder_id);
      }
    };

    handleFileObjects = (files) => {
      const file = (files.length > 0) ? files[0] : null;

      this.setState({
        fileObjects: files,
        filePreviewUrl: file ? URL.createObjectURL(file) : null,
      });
    };

    handleDocumentPreview = () => {
      const { fileObjects } = this.state;

      const typeError = isNullValue(this.state.upload?.value)
      this.setState({
        upload: {
          ...this.state.upload,
          error: typeError,
        }
      })
  
      if (fileObjects.length === 0 || fileObjects[0] === null || typeError) {
        return;
      }

      this.setState({
        modalStyle: {...filePreviewStyle},
        isFilePreview: true,
      });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState(() => {
            return { records: parseInt(event.target.value, 10), page: 1 };
        }, () => {
            this.getDeliveredOrders(this.props.filterString);
        });
    }

  handleDocumentUploadModal = (subOrder, isOpen) => {

    this.setState({
      upload: {...initDocumentForm},
      selectedOrder: subOrder,
      openDocumentModal: isOpen,
      fileObjects: [],
      filePreviewUrl: null,
      isFilePreview: false,
      modalStyle: {},
      viewAllocationSummary: false,
      viewNominationSummary: false,
    });
  };

  handleViewSubOrderDocument = async (subDocument) => {
    this.setState({
      selectedSubDocument: subDocument,
      openSubDocumentModal: isNotNullValue(subDocument),
    });
  };
  handleViewAllocationSummary = (subOrder=null) => {
    this.setState({
      viewAllocationSummary: isNotNullValue(subOrder),
      selectedOrder: subOrder,
    });

  };
  handleViewNominationSummary = (subOrder=null) => {
    this.setState({
      viewNominationSummary: isNotNullValue(subOrder),
      selectedOrder: subOrder,
    });

  };

    render() {
        const { allocatedOrders, filePreviewUrl, isFilePreview,
                fileObjects, modalStyle , openDocumentModal, selectedSubDocument} = this.state;

        return (
            <Paper>
            {/* <GlobalFilter sx={{ my: 1 }} onFilterChange={this.handleFilterChange} /> */}
    
            <TableContainer sx={{ mt: 2, width: '100%', overflowX: { xs: 'scroll', sm: 'auto' } }} >
            <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Order Item ID</TableCell>
                            <TableCell align="right">Supplier Name</TableCell>
                            <TableCell align="right">Product</TableCell>
                            <TableCell align="right">Volume</TableCell>
                            <TableCell align="right">Delivery Date</TableCell>
                            <TableCell align="right">Delivery Location</TableCell>
                            <TableCell align="right">Status</TableCell>
                            {!isAuthorizedRole([["CUSTOMS-L1", "DEMO"]]) && (
                                <TableCell align="right">Actions</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allocatedOrders.map((row) => (
                          <>
                            <TableRow
                                key={'table-row-' + row.suborder_id}
                                selected={this.state.selectedOrder?.suborder_id === row.suborder_id}
                                // onClick={() => this.setState({selectedOrder: row})}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: 'rgba(39,226,245,0.27)' } }}
                            >
                                <TableCell component="th" scope="row">{row.suborder_id}</TableCell>
                                <TableCell align="right">{row.supplier_name}</TableCell>
                                <TableCell align="right">{row.fuel_type}</TableCell>
                                <TableCell align="right">{fNumber(row.volume)}</TableCell>
                                <TableCell align="right">{moment(row.delivery_scheduled_datetime).format('YYYY/MM/DD - HH:mm:ss')}</TableCell>
                                <TableCell align="right">{row.delivery_location}</TableCell>
                                <TableCell align="right">{row.status}</TableCell>
                                {!isAuthorizedRole([["CUSTOMS-L1"]]) && (
                                  <TableCell align="right">
                                    {row?.is_nominated && (
                                        <>
                                          <Tooltip title="Nomination Summary">
                                            <IconButton onClick={() => this.handleViewAllocationSummary(row)}>
                                              <WorkHistory color={palette.warning.main} />
                                            </IconButton>
                                          </Tooltip>
                                          {this.state.selectedOrder?.suborder_id === row.suborder_id ? (
                                            <Tooltip title="Hide Documents">
                                              <IconButton onClick={() => this.setSubOrder(row)}>
                                                <FolderOff color={palette.info.main} />
                                              </IconButton>
                                            </Tooltip>
                                          ) : (
                                            <Tooltip title="Vew Documents">
                                              <IconButton onClick={() => this.setSubOrder(row)}>
                                                <FolderCopy color={palette.info.main} />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                          <Tooltip title="Upload Document">
                                            <IconButton onClick={() => this.handleDocumentUploadModal(row, true)}>
                                              <FileArrowUpFill color={palette.success.main} />
                                            </IconButton>
                                          </Tooltip>
                                        </>
                                      )}
                                  </TableCell>
                                )}
                            </TableRow>
                            {(this.state.selectedOrder?.suborder_id === row.suborder_id && row?.is_nominated && isAuthorizedRole(["CUSTOMS-L1"])) && (
                              <TableRow
                                selected={this.state.selectedOrder?.suborder_id === row.suborder_id}
                              >
                                <TableCell
                                  colSpan={9}
                                >
                                  <TableContainer sx={{ mt: 2, width: '100%', overflowX: { xs: 'scroll', sm: 'auto', } }} >
                                    <Table size="small" aria-label="suborders">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Document</TableCell>
                                          <TableCell>Date Time</TableCell>
                                          <TableCell>Type</TableCell>
                                          <TableCell align="right">Actions</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {this.state.subOrderDocuments?.map((docRow) => (
                                          <TableRow
                                            key={'doc-row-' + docRow?.document_id}
                                            sx={{ backgroundColor: '#FFFAFA' }}
                                            selected={this.state.currentSubOrder === docRow.suborder_id}
                                          >
                                            <TableCell>{docRow.document_file_name}</TableCell>
                                            <TableCell>
                                              {moment(docRow?.uploaded_at || null).format('YYYY/MM/DD - HH:mm:ss')}
                                            </TableCell>
                                            <TableCell>{docRow?.document_type}</TableCell>
                                            <TableCell align='right'>
                                              <IconButton onClick={() => this.handleViewSubOrderDocument(docRow)}>
                                                <Eye color={palette.info.main} />
                                              </IconButton>
                                              <IconButton onClick={() => downloadFile(docRow?.document_url, docRow?.document_file_name)}>
                                                <Download color={palette.info.main} />
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        ))}
                    </TableBody>
                </Table>
                </TableContainer>
                <CustomTablePagination
                    rowsPerPage={this.state.records}
                    recordsList={this.state.recordsList}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                    handleNextPage={this.nextPage.bind(this)}
                    currentPage={this.state.page}
                    totalPages={this.state.totalPages}
                />

              <CustomModal
                openModal={openDocumentModal}
                handleCloseModal={()=> this.handleDocumentUploadModal(null, false)}
                modalTitle={`Suborder ${this.state.selectedOrder?.suborder_id || ''} Document`}
                modalIcon='document'
                modalStyle={modalStyle}
              >
                {!isFilePreview && (
                  <>
                    <Box sx={{ my: 2 }}>
                      <FileDragzone
                        acceptedTypes={{
                          'application/pdf': ['.pdf'],
                        }}
                        fileObjects={fileObjects}
                        handleFileObjects={this.handleFileObjects}
                        filesLimit={1}
                        maxFileSizeMB={MAX_FILE_SIZE_MB}
                      />
                    </Box>

                    <FormItemlGrid
                      label={"Document Type"}
                    >
                      <FormControl fullWidth sx={{ backgroundColor: 'whitesmoke' }}>
                        <CustomSelect
                          // label={"Document Type"}
                          labelId="document-select-label"
                          value={this.state.upload.value}
                          onChange={(e) => {
                            this.setState({
                              upload: {
                                ...this.state.upload,
                                value: e.target.value,
                                error: false,
                              }
                            })
                          }}
                          required={this.state.upload.required}
                          error={this.state.upload.error}
                          helperText={this.state.upload.error && this.state.upload.errorMessage}
                        >
                          <MenuItem value="" disabled>
                            <em>Select Document Type</em>
                          </MenuItem>
                          {documentTypes?.map((doc) => (
                            <MenuItem key={`document-${doc.key}`} value={doc.key}>
                              {doc.value}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      </FormControl>
                    </FormItemlGrid>

                    <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
                      <Button
                        variant='contained'
                        color="inherit"
                        onClick={()=> this.handleDocumentUploadModal(null, false)}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        sx={{ ml: 2, }}
                        variant="contained"
                        onClick={this.handleDocumentPreview}
                      >
                        Continue
                      </LoadingButton>
                    </Box>
                  </>
                )}

                {(isFilePreview) && (
                  <>
                    <Box sx={{ mt: 2 }}>
                      <Grid item size={{ xs: 12 }} sx={{ my: 'auto', display: 'flex', justifyContent: 'center' }}>
                      <CustomPdfViewer
                            fileUrl={filePreviewUrl || null}
                          />
                      </Grid>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
                      <Button
                        variant='contained'
                        color="inherit"
                        onClick={()=> this.handleDocumentUploadModal(null, false)}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        sx={{ ml: 2, }}
                        variant="contained"
                        loading={this.state.submitted}
                        onClick={() => this.handleDocumentSubmit()}
                      >
                        Finish
                      </LoadingButton>
                    </Box>
                  </>
                )}
              </CustomModal>

              <CustomModal
                openModal={this.state.openSubDocumentModal}
                handleCloseModal={()=> this.handleViewSubOrderDocument(null)}
                modalTitle={`${selectedSubDocument?.document_type} document: ${selectedSubDocument?.document_file_name}`}
                modalIcon='document'
                modalStyle={{ ...filePreviewStyle }}
              >
                <>
                  <Box sx={{ mt: 2 }}>
                    <Grid item size={{ xs: 12 }} sx={{ my: 'auto', display: 'flex', justifyContent: 'center' }}>
                      <CustomPdfViewer
                        fileUrl={selectedSubDocument?.document_url || null}
                      />
                    </Grid>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
                    <Button
                      variant='contained'
                      color="inherit"
                      onClick={()=> this.handleViewSubOrderDocument(null)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </>
              </CustomModal>

              <OrderAllocationSummary
                orderData={this.state.selectedOrder}
                handleCloseModal={()=> this.handleViewAllocationSummary(null)}
                openModal={this.state.viewAllocationSummary}
              />

              <OrderNominationSummary
                orderData={this.state.selectedOrder}
                handleCloseModal={()=> this.handleViewNominationSummary(null)}
                openModal={this.state.viewNominationSummary}
              />
            </Paper>
        );
    }
}

AllocatedOrders.propTypes = {
    filterString: PropTypes.string
};
