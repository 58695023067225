import React from 'react';
import PropTypes from 'prop-types'

import { Box } from '@mui/material';

import API from '../../data/api';
import { SummaryCards } from './SummaryCards';

export default class AggregatedStatsComponent extends React.Component {
  static formatNumber(number) {
    return new Intl.NumberFormat().format(number);
  }

  constructor(props) {
    super(props);
    this.state = {
      // statValues: {
        // client_count: null,
        // order_count: null,
        // suborder_count: null,
        // total_revenue: null,
        // total_ulp93: null,
        // total_ulp95: null,
        // total_ppm50: null,
      // },
      statValues: [],
    };
  }

  componentDidMount() {
    this.getAggregatedStats();
  }

  componentDidUpdate() {
    if (this.props.updateStats) {
      this.getAggregatedStats();
    }
  }

  getAggregatedStats = async () => {
    API.get('inventory/backoffice/aggregation')
      .then((res) => {
        if (res.status === 200) {
          console.log(`Aggregation endpoint [OK] = ${res.status}`, res.data.cards?.data);
          this.setState({
            // statValues: {
              // client_count: res.data.client_count,
              // order_count: res.data.order_count,
              // suborder_count: res.data.suborder_count,
              // total_revenue: res.data.total_revenue,
              // total_ulp93: res.data.total_ulp93,
              // total_ulp95: res.data.total_ulp95,
              // total_ppm50: res.data.total_ppm50,
            // },
            statValues: res.data.cards?.data,
          });
          this.props.setUpdateStats(false);
        } else {
          console.log('There was an error retrieving aggregated stats');
        }
      })
    .catch((error) => {
        console.error('There was an error retrieving aggregated stats, ', error);
    });
  };

  render() {
    return (
      <Box sx={{ flexGrow: 1, px: 2 }}>
        <SummaryCards cardsData={this.state.statValues} isInvoices={this.props.isInvoices} />
      </Box>
    );
  }
}

AggregatedStatsComponent.propTypes = {
  updateStats: PropTypes.bool.isRequired,
  setUpdateStats: PropTypes.func.isRequired,
  isInvoices: PropTypes.bool.isRequired,
};
