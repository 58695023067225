import React, { Component } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { FormControl, Box, TextField, InputAdornment } from '@mui/material';

import Grid from '@mui/material/Grid2';

import FilterListIcon from '@mui/icons-material/FilterList';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';

import CustomDateTimePicker from '../custom/CustomDateTimePicker';

class GlobalFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      search: '',
      disabledSubmit: true,
      clearFilter: false,
    };
  }

  isNonNullDate = (value) => {
    return value !== 'YYYY/MM/DD HH:mm:ss' && value !== '' && value !== null;
  }

  toggleClearFilters = () => {
    this.setState({
      clearFilter: !this.state.clearFilter,
    });
  }

  handleClearFilters = () => {
    const { startDate, endDate, search } = this.state;
    if (this.isNonNullDate(startDate) || this.isNonNullDate(endDate) || search !== '') {
        this.setState({
          'search': '',
          'startDate': null,
          'endDate': null,
          disabledSubmit: true,
          clearFilter: true,
        });
    
        // Pass the filterString to each parent component
        this.props.onFilterChange(''); 
    } else {
      this.setState({
        disabledSubmit: false,
        clearFilter: false,
      });
    }
  };

  handleFilterChange = (name, value) => {
    this.setState({ [name]: value });
  };

  submitFilter = () => {

    const { startDate, endDate, search } = this.state;
    const queryParam = '&';
    let filterString = queryParam;

    const hasCustomStartDate = this.isNonNullDate(startDate);
    const hasCustomEndDate = this.isNonNullDate(endDate);
    const hasSearchTerm = search !== '';

    let canFilter = false;
    if (!hasCustomStartDate && !hasCustomEndDate && !hasSearchTerm) {
      // Default case when no search values are provided
      // do nothing  
    } else {
      canFilter = true;
      // Binary search values for start, end, and search term
      if (hasCustomStartDate && hasCustomEndDate) {
        filterString += `time_start=${this.state.startDate}&time_end=${this.state.endDate}`;
      }
      if (hasCustomStartDate && !hasCustomEndDate) {
        if (hasCustomEndDate === false) {
          filterString += `time_start=${this.state.startDate}`;
        }
      }
      if (!hasCustomStartDate && hasCustomEndDate) {
        if (hasCustomStartDate === false) {
          filterString += `end_start=${this.state.startDate}`;
        }
      }
      if (hasSearchTerm) {
        if (filterString !== queryParam) {
          // If there are already parameters, add '&' before adding search term
          filterString += '&';
        }
        filterString += `search_term=${this.state.search}`;
      }

      this.setState({disabledSubmit: !canFilter});

      // Pass the filterString to each parent component
      this.props.onFilterChange(filterString);
    }

  };

  render() {
    // const { startDate, endDate, search } = this.state;

    return (
      <Box
        sx={{ display: 'flex', p: 1, borderRadius: 1, marginTop: 1 }}
      >
        <Box sx={{ width: '100%', mr: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={2} >
              <CustomDateTimePicker sx={{ mr: 1 }}
                field={'startDate'}
                handleChange={this.handleFilterChange}
                label={"Start Date"}
                clear={this.state.clearFilter}
                setClear={this.toggleClearFilters}
              />
            </Grid>
            <Grid item xs={2} >
              <CustomDateTimePicker sx={{ mr: 1 }}
                field={'endDate'}
                handleChange={this.handleFilterChange}
                label={"End Date"}
                clear={this.state.clearFilter}
                setClear={this.toggleClearFilters}
                minDate={this.state.startDate}
              />
            </Grid>
            <Grid item xs={4} >
              <FormControl fullWidth>
                <TextField
                  size='small'
                  id="search"
                  label="Search"
                  variant="outlined"
                  sx={{ mt: 1 , minWidth: '250px'}}
                  slotProps={{
                    input: {
                      startAdornment: <InputAdornment position="start"> <SearchIcon /> </InputAdornment>,
                    },
                  }}
                  value={this.state.search}
                  onChange={(e) => { this.handleFilterChange('search', e.target.value) }}
                />
              </FormControl>
              </Grid>
              <Grid item xs={2} sx={{  my: 2, }} >
                  <RefreshIcon sx={{ fontSize: 30, background: "#FFF", borderRadius: 1 }}
                    onClick={this.handleClearFilters}
                  />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ display: 'flex', flexShrink: 0 }}>
          <FilterListIcon sx={{ fontSize: 35, background: "#FFF", borderRadius: 1, my: 2,}}
            onClick={this.submitFilter}
          />
        </Box>
      </Box>
    );
  }
}

GlobalFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
};

export default GlobalFilter;
