/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Button } from '@mui/material'
import Grid from '@mui/material/Grid2'


import API from '../../../data/api';
import FileDragzone from '../../ui/FileDragzone'
import CustomModal from '../../custom/CustomModal'
import { filePreviewStyle, MAX_FILE_SIZE_MB } from '../../../utils/constants';
import CustomPdfViewer from 'src/components/custom/CustomPdfViewer'
import { isNullValue } from 'src/utils/formUtil'

const initDocument = {
  fileObjects: [],
  filePreviewUrl: null,
  isFilePreview: false,
  isPdfFile: false,
  modalStyle: {},
  type: { value: 'PROOF_OF_PAYMENT', required: true, error: false, errorMessage: 'Please specify a document type', },
  details: null,
}

const documentTypes = [
  { key: 'PFI', value: 'PFI'},
  { key: 'PROOF_OF_PAYMENT', value: 'Proof of payment'},
  { key: 'OTHER', value: 'Other'},
]

const SupplierDocumentUpload = ({ openModal, subOrder, handleCloseModal, handleUploadCallback }) => {

  const [subOrderId, setSubOrderId] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [document, setDocument] = useState({...initDocument});

  useEffect(() => {

    setSubOrderId(subOrder?.order_id || subOrder?.suborder_id)
  }, [document.modalStyle, subOrder]);

  const handleDocumentPreview = async () => {

    if (document.fileObjects.length === 0 || document.fileObjects[0] === null) {
      setSubmitted(false);
      return;
    }

    setDocument({
      ...document,
      modalStyle: filePreviewStyle,
      isFilePreview: true,
      filePreviewUrl: URL.createObjectURL(document.fileObjects[0]),
    })
  };

  const handleDocumentSubmit = async () => {

    if (document.fileObjects.length === 0 || document.fileObjects[0] === null
      || isNullValue(subOrder?.order_item_id) || isNullValue(document.type?.value)) {
        console.log("opps, validaion error", subOrder)
      return;
    }

    let endPoint = `inventory/backoffice/upload_proof_of_payment?order_item_id=${subOrder?.order_item_id}`

    const formData = new FormData();
    formData.append('proof_of_payment_file', document.fileObjects[0]);

    setSubmitted(true);
    try {
      const res = await API.post(`${endPoint}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (res.status === 200 || res.status === 201) {
        handleUploadCallback();
      } else {
        console.log("Error:", res.data);
      }
    } catch (error) {
      console.error('Error uploading document:', error);
    }
    setSubmitted(false);
  }

  const triggerCloseModal = () => {
    setDocument({...initDocument});
    handleCloseModal({ isOpen: false, updateStats: null });
  }

  const handleFileObjects = (files) => {
    const file = (files.length > 0) ? files[0] : null;
    setDocument({
      ...document,
      fileObjects: files,
      filePreviewUrl: (file ? URL.createObjectURL(file) : null),
      isPdfFile: (file?.type === 'application/pdf'),
    })
  };

  return (
    <CustomModal
      openModal={openModal}
      handleCloseModal={triggerCloseModal}
      modalTitle={""}
      modalStyle={document.modalStyle}
      modalIcon='document'
    >
      {!document.isFilePreview && (
        <>
          <Box sx={{ my: 2 }}>
            <FileDragzone
              acceptedTypes={{
                'application/pdf': ['.pdf'],
              }}
              fileObjects={document.fileObjects}
              handleFileObjects={handleFileObjects}
              filesLimit={1}
              maxFileSizeMB={MAX_FILE_SIZE_MB}
            />
          </Box>
        {/* 
          <FormControl fullWidth sx={{ backgroundColor: 'whitesmoke' }}>
            <CustomSelect
              label={"Document Type"}
              labelId="document-select-label"
              value={document.type.value}
              onChange={(e) => {
                setDocument({
                  ...document,
                  type: {
                    ...document.type,
                    value: e.target.value,
                    error: false,
                  }
                })
              }}
              required={document.type.required}
              error={document.type.error}
              helperText={document.type.error && document.type.errorMessage}
            >
              <MenuItem value="" disabled>
                <em>Select Document Type</em>
              </MenuItem>
              {documentTypes?.map((doc) => (
                <MenuItem key={`document-${doc.key}`} value={doc.key}>
                  {doc.value}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
        */}

          <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
            <Button
              variant='contained'
              color="inherit"
              onClick={() => triggerCloseModal()}
            >
              Cancel
            </Button>
            <LoadingButton
              sx={{ ml: 2, }}
              variant="contained"
              onClick={() =>handleDocumentPreview()}
            >
              Continue
            </LoadingButton>
          </Box>
        </>
      )}

      {(document.isFilePreview) && (
        <>
          <Box sx={{ mt: 2 }}>
            <Grid item size={{ xs: 12 }} sx={{ my: 'auto', display: 'flex', justifyContent: 'center' }}>
              <CustomPdfViewer
                fileUrl={document.filePreviewUrl || null}
              />
            </Grid>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
            <Button
              variant='contained'
              color="inherit"
              onClick={() => triggerCloseModal()}
            >
              Cancel
            </Button>

            <LoadingButton
              sx={{ ml: 2, }}
              variant="contained"
              loading={submitted}
              onClick={() => handleDocumentSubmit()}
            >
              Finish
            </LoadingButton>
          </Box>
        </>
      )}
    </CustomModal>
  )
}

SupplierDocumentUpload.propTypes = {}

export default SupplierDocumentUpload