import * as React from "react"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid2"

import palette from "../../../theme/palette"
import { scaleNumber } from "../../../utils/formatNumber"

export function TotalProductCard({ data, litres, sx = {} }) {

  return (
    <Card sx={{ height: '100%', ...sx }}>
      <CardContent>
        <Stack
          direction="row"
          sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
          spacing={3}
        >
          <Stack
            spacing={1}
            sx={{
              borderLeft: `5px solid ${data.color}`,
              pl: 2,
              pt: 0,
              height: "50%",
              width: "100%",
            }}
          >
            <Typography color={palette.grey[500]} variant="body1">
              {data.type}
            </Typography>
            <Grid
              container
              spacing={3}
              sx={{
                mt: 2,
                padding: 1,
                backgroundColor: '#FFF',
                borderRadius: "5px"
              }}
            >
              <Grid size={{ xs: 4 }} fontSize="12px" fontWeight="normal">
                <Typography sx={{ color: palette.grey[500] }} variant="inherit">
                  &nbsp;50 PPM
                </Typography>
                {scaleNumber(data.metadata.ppm50)}&nbsp;{litres ? <>Ltrs</> : <>m<sup>3</sup></>}
              </Grid>
              <Grid size={{ xs: 4 }} fontSize="12px" fontWeight="normal">
                <Typography sx={{ color: palette.grey[500] }} variant="inherit">
                  &nbsp;ULP 93
                </Typography>
                {scaleNumber(data.metadata.ulp93)}&nbsp;{litres ? <>Ltrs</> : <>m<sup>3</sup></>}
              </Grid>
              <Grid size={{ xs: 4 }} fontSize="12px" fontWeight="normal">
                <Typography sx={{ color: palette.grey[500] }} variant="inherit">
                  &nbsp;ULP 95
                </Typography>
                {scaleNumber(data.metadata.ulp95)}&nbsp;{litres ? <>Ltrs</> : <>m<sup>3</sup></>}
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
