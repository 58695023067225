import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  MenuItem,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import API from '../../../data/api';
import { CustomSelect } from '../../custom/CustomSelect';
import CustomModal from '../../custom/CustomModal';
import CustomTextField from '../../custom/CustomTextField';
import CustomSnackBar from '../../custom/CustomSnackBar';

import {
  extractFormValues,
  initialiseForm,
  isNullValue,
  validateForm
} from '../../../utils/formUtil';

const initFormState = {
  company: { value: '', required: true, helper: 'transport company' },
  registration: { value: '', required: true, helper: 'trailer reistration' },
  capacity: { value: '', required: true, helper: 'trailer capacity' },
  pto: { value: '', required: false, },
};

const CompanyTrailer = ({
  transportCompanies=[],
  openModal,
  handleCloseModal,
  handleSubmitCallback
}) => {

  const initialFormData = initialiseForm(initFormState);

  const [formData, setFormData] = useState(initialFormData);
  const [submitted, setSubmitted] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: "",
    type: "",
  });

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transportCompanies, openModal]);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);

    if (!validateForm(setFormData, formData)) {
      console.log("oops, failed validation", formData)
      setSubmitted(false);
      return;
    }

    try {
      const { company, registration, capacity, pto } = extractFormValues(formData);
      const companyName = transportCompanies?.find(tc => tc?.id === company)?.company_name || '';

      const response = await API.post(`inventorylogistics/create_trailer?company_id=${company}`, [{
        registration: registration,
        capacity: capacity,
        is_pto: pto === 'Yes',
      }]);

      if (response.status === 200 || response.status === 201) {
        // Reset the form if response is successful
        setFormData(initialFormData);
        setSnackbarMessage({
          message: `Successfully added trailer with registration ${registration} and ${capacity} (Ltr) capacity ${companyName? 'for' + companyName : ''}`,
          type: "success",
        });
        handleSubmitCallback();
      } else {
        setSnackbarMessage({ message: response.message, type: "error" });
      }
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage({
        message: "An unexpected error occurred. Please try again later.",
        type: "error",
      });
    }
    setSubmitted(false);
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    handleCloseModal();
  }

  const handleFormDataChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        value: e.target.value,
        error: formData[e.target.name]?.required && isNullValue(e.target.value),
      },
    });
  };

  return (
    <>
      <CustomModal
        openModal={openModal}
        handleCloseModal={handleCancel}
        modalTitle={`Add Trailer`}
        modalIcon='transport'
      >
        <form onSubmit={handleSubmit} noValidate>
          <Stack spacing={3} sx={{ mt: 2 }}>

            <CustomSelect
              name="company"
              label="Transport Company"
              value={formData.company.value}
              onChange={handleFormDataChange}
              required={formData.company.required}
              error={formData.company.error}
              helperText={formData.company.error && formData.company.errorMessage}
            >
              <MenuItem value=" " disabled>
                <em>Select Company</em>
              </MenuItem>
              {transportCompanies?.map((trans) => (
                <MenuItem key={'transport-' + trans.company_id} value={trans.company_id}>
                  {trans.company_name}
                </MenuItem>
              ))}
            </CustomSelect>

            <CustomTextField
              name="registration"
              label="Trailer Registration"
              value={formData.registration.value}
              onChange={handleFormDataChange}
              required={formData.registration.required}
              error={formData.registration.error}
              helperText={formData.registration.error && formData.registration.errorMessage}
            />

            <CustomTextField
              name="capacity"
              label="Trailer Capacity"
              type={"number"}
              value={formData.capacity.value}
              onChange={handleFormDataChange}
              required={formData.capacity.required}
              error={formData.capacity.error}
              helperText={formData.capacity.error && formData.capacity.errorMessage}
            />

            <CustomSelect
              name="pto"
              label="Trailer PTO"
              value={formData.pto.value}
              onChange={handleFormDataChange}
              required={formData.pto.required}
              error={formData.pto.error}
              helperText={formData.pto.error && formData.pto.errorMessage}
            >
              <MenuItem value=" " disabled>
                <em>Has PTO</em>
              </MenuItem>
              {['Yes', 'No']?.map((option) => (
                <MenuItem key={'pto-' + option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </CustomSelect>

            <Box sx={{ alignItems: 'center', mt: 3, }}>
              <Button color="inherit"
                variant='contained'
                sx={{ width: '47%' }}
                onClick={() => { handleCancel() }}
                disabled={submitted}
              >
                Cancel
              </Button>
              <LoadingButton
                variant='contained'
                sx={{ ml: 2, width: '47%' }}
                type="submit"
                loading={submitted}
              >
                Submit
              </LoadingButton>
            </Box>
          </Stack>
        </form>
      </CustomModal>
      <CustomSnackBar
        snackbarMessage={snackbarMessage}
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={() => setSnackbarOpen(false)}
      />
    </>
  );
}

CompanyTrailer.propTypes = {

  /** List of transport companies */
  transportCompanies: PropTypes.array.isRequired,

  /** Flag to toggling openning of modal */
  openModal: PropTypes.bool.isRequired,

  /** Callback function when closing modal */
  handleCloseModal: PropTypes.func.isRequired,

  /** Callback function after successful submission */
  handleSubmitCallback: PropTypes.func.isRequired,

}

export default CompanyTrailer
