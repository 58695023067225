import React from 'react'
import PropTypes from 'prop-types'

import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Select,
} from '@mui/material';
import { isNotNullValue } from 'src/utils/formUtil';
export const CustomSelect = ({ sx = {}, ...props }) => {
  const textLabel = `${props?.label || ''} ${(props?.label && props?.required )? '*' : ''}`

  const shrinkLabel = isNotNullValue(props?.label) ? { shrink: true } : {}
  return (
    <FormControl fullWidth sx={{ ...sx }} error={props?.error}>
      <InputLabel shrink={shrinkLabel}>{textLabel}</InputLabel>
      <Select
        displayEmpty
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 450,
              overflowY: 'auto',
            },
          },
        }}
        input={<OutlinedInput
          label={props?.label}
          slotProps={{
            inputLabel: { ...shrinkLabel }
          }}
        />}
        {...props}
      />
      <FormHelperText sx={{color: 'red'}}>
        {props?.helperText}
      </FormHelperText>
    </FormControl>

  )
}

CustomSelect.propTypes = {
  sx: PropTypes.object,
  props: PropTypes.any,
}
