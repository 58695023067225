import React, { Component } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import moment from 'moment';
import { FormControl, Box } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FilterListIcon from '@mui/icons-material/FilterList';
import RefreshIcon from '@mui/icons-material/Refresh';

import CustomTextField from '../custom/CustomTextField';

class GlobalFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: 'YYYY/MM/DD HH:mm:ss',
      endDate: 'YYYY/MM/DD HH:mm:ss',
      search: '',
      disabledSubmit: true,
      valueChange: false,
    };
  }

  handleClearFilters = () => {
    const { startDate, endDate, search } = this.state;
    if ((startDate !== 'YYYY/MM/DD HH:mm:ss' && startDate !== '')  || ( endDate !== 'YYYY/MM/DD HH:mm:ss'
      && endDate !== '') || search !== '') {
        this.setState({
          'search': '',
          'startDate': '',
          'endDate': '',
          disabledSubmit: true
        });
    
        // Pass the filterString to each parent component
        this.props.onFilterChange(''); 
    }
  };

  handleFilterChange = (name, value) => {
    this.setState({ [name]: value });
  };

  submitFilter = () => {

    const { startDate, endDate, search } = this.state;
    const queryParam = '&';
    let filterString = queryParam;

    const hasCustomStartDate = startDate !== 'YYYY/MM/DD HH:mm:ss' && startDate !== '';
    const hasCustomEndDate = endDate !== 'YYYY/MM/DD HH:mm:ss' && endDate !== '';
    const hasSearchTerm = search !== '';

    let canFilter = false;
    if (!hasCustomStartDate && !hasCustomEndDate && !hasSearchTerm) {
      // Default case when no search values are provided
      // do nothing  
    } else {
      canFilter = true;
      // Binary search values for start, end, and search term
      if (hasCustomStartDate && hasCustomEndDate) {
        filterString += `time_start=${this.state.startDate}&time_end=${this.state.endDate}`;
      }
      if (hasCustomStartDate && !hasCustomEndDate) {
        if (hasCustomEndDate === false) {
          filterString += `time_start=${this.state.startDate}`;
        }
      }
      if (!hasCustomStartDate && hasCustomEndDate) {
        if (hasCustomStartDate === false) {
          filterString += `end_start=${this.state.startDate}`;
        }
      }
      if (hasSearchTerm) {
        if (filterString !== queryParam) {
          // If there are already parameters, add '&' before adding search term
          filterString += '&';
        }
        filterString += `search_term=${this.state.search}`;
      }

      this.setState({disabledSubmit: !canFilter});

      // Pass the filterString to each parent component
      this.props.onFilterChange(filterString);
    }

  };

  render() {
    // const { startDate, endDate, search } = this.state;

    return (
      <Box
        sx={{ display: 'flex', p: 1, borderRadius: 1, marginTop: 1 }}
      >
        <Box sx={{ width: '100%', display: 'flex', mr: 2 }}>
          <Box sx={{ mr: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimePicker']}>
                <DateTimePicker
                  label="Start Date"
                  onChange={(e) => { this.handleFilterChange('startDate', moment(e.$d).format('YYYY/MM/DD HH:mm:ss')) }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>

          <Box sx={{ mr: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimePicker']}>
                <DateTimePicker
                  label="End Date"
                  // value={this.state.endDate}
                  onChange={(e) => { this.handleFilterChange('endDate', moment(e.$d).format('YYYY/MM/DD HH:mm:ss')) }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>

          <Box sx={{ mr: 1 }}>
            <FormControl fullWidth>
              <CustomTextField
                id="search"
                label="Search Term"
                variant="outlined"
                sx={{ mt: 1 }}
                value={this.state.search}
                onChange={(e) => { this.handleFilterChange('search', e.target.value) }}
              />
            </FormControl>
          </Box>

          <Box sx={{ mr: 1 , my: 'auto', }}>
            <RefreshIcon sx={{ fontSize: 30, background: "#FFF", borderRadius: 1 }}
              onClick={this.handleClearFilters}
            />
          </Box>
          
        </Box>

        <Box sx={{ display: 'flex', flexShrink: 0 }}>
          <FilterListIcon sx={{ fontSize: 50, background: "#FFF", borderRadius: 1 }}
            onClick={this.submitFilter}
          />
        </Box>
      </Box>
    );
  }
}

GlobalFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
};

export default GlobalFilter;
