import * as React from 'react';
import { useState } from 'react';

import Grid from '@mui/material/Grid2';
import { TallyCard } from '../components/dashboard/cards/TallyCard';
import { ProductCard } from '../components/dashboard/cards/ProductCard';
import { ProgressCard } from '../components/dashboard/cards/ProgressCard';
import { LabeledProgressCard } from '../components/dashboard/cards/LabeledProgressCard';
import { ProductSegmentCard } from '../components/dashboard/cards/ProductSegmentCard';
import { FUEL_PRODUCT_COLORS, WHITE_SMOKE_COLOR } from '../utils/constants';
// import GroupedStackedColumn from '../components/dashboard/charts/GroupedStackedColumn';
import { AreaChart } from '../components/dashboard/charts/AreaChart';
import { transformBulkOrderBreakdownData } from 'src/components/dashboard/dash-util';

const data = {
  type: 'Revenue Total',
  value: 'R32 m',
  color: "#d32f2f",
  metadata: {
    ulp93: 50,
    ulp95: 20,
    ppm50: 100,
  }
}

const excoData = {
  type: 'Trucks Enroute',
  value: 'R32 m',
  color: '#4caf50',
  metadata: {
    ulp93: 13,
    ulp95: 5,
    ppm50: 24,
  }
}

const another = {
  type: 'Revenue Total',
  value: 'R56 m',
  color: '#ed6c02',
  metadata: {
    ulp93: 50,
    ulp95: 20,
    ppm50: 100,
  }
}

const something = {
  type: 'Another Total',
  value: 'R105 m',
  color: '#1976d2',
  metadata: {
    ulp93: 50,
    ulp95: 20,
    ppm50: 100,
  }
}

const without = {
  type: 'Total Opex',
  value: 'R105 m',
  color: '#4caf50',
}

const uprogress = {
  type: 'Number1 Progress',
  value: 75.5,
  metadata: {
    trend: 'up',
    indicator: '10 something last',
    progress: 45,
    diff: 22,
  }
}

const dprogress = {
  type: 'Another Progress',
  value: 62.8,
  metadata: {
    trend: 'down',
    indicator: '6 wows',
    progress: 78,
    diff: -15,
  }
}

const iprogress = {
  type: 'Test Progress',
  value: 62.8,
  metadata: {
    indicator: '10 something last',
    progress: 78,
    diff: 8,
  }
}

const nsuborders = {
  type: 'Total Suborders',
  value: 15,
  color: '#4caf50',
}

const osuborders = {
  type: 'Total Suborders',
  value: 10,
  color: '#ed6c02',
}

const dsuborders = {
  type: 'Total Suborders',
  value: 15,
  color: '#1976d2',
}

const bulkBreakdown = {
  type: 'Bulk and Bulk Breaks',
  value: 15,
  leftBar:{
    label: "59%",
    backgroundColor: "#000000",
    textColor: "#FFF",
    legend: 'Bulk Orders'
  },
  rightBar: {
    label: "41%",
    backgroundColor: "#1565c0",
    textColor: "#FFF",
    legend: 'Bulk Break Orders'
  }
}

const productSeg = {
  ppm50: {
    value: 35,
    leftBar: {
      backgroundColor: FUEL_PRODUCT_COLORS.ppm50,
    },
    rightBar: {
      label: "35%",
      backgroundColor: WHITE_SMOKE_COLOR,
    }
  },
  ulp93: {
    value: 59,
    leftBar: {
      backgroundColor: FUEL_PRODUCT_COLORS.upl93,
    },
    rightBar: {
      label: "59%",
      backgroundColor: WHITE_SMOKE_COLOR,
    }
  },
  ulp95: {
    value: 78,
    leftBar: {
      backgroundColor: FUEL_PRODUCT_COLORS.upl95,
    },
    rightBar: {
      label: "78%",
      backgroundColor: WHITE_SMOKE_COLOR,
    }
  },
}

const cards = [
  {
    "type": "Revenue Expected",
    "value": 16297509.0,
    "metadata": [
      {
        "type": "50 PPM",
        "value": 2100000.0,
        "metadata": {}
      },
      {
        "type": "ULP 95",
        "value": 14197509.0,
        "metadata": {}
      }
    ]
  },
  {
    "type": "Revenue Generated",
    "value": 0,
    "metadata": []
  },
  {
    "type": "Revenue Loss",
    "value": 0,
    "metadata": []
  },
  {
    "type": "Bulk and Bulk Breaks",
    "value": 0.0,
    "metadata": [
      {
        "type": "bulk_orders",
        "value": 0,
        "metadata": [
          {
            "type": "count",
            "value": 0,
            "metadata": [
              {
                "type": "percentage",
                "value": 0,
                "metadata": []
              }
            ]
          }
        ]
      },
      {
        "type": "bulk_break_orders",
        "value": 0,
        "metadata": [
          {
            "type": "count",
            "value": 0,
            "metadata": [
              {
                "type": "percentage",
                "value": 0,
                "metadata": []
              }
            ]
          }
        ]
      }
    ]
  }
]

const findCardType = (type) => {
  return cards.find(c => type.localeCompare(c?.type));
}

console.log(findCardType('Revenue expected'));

const exData = [data, something, another]
const opData = [without, data, something, another]
const progData = [uprogress, dprogress, uprogress, iprogress]
const suborders = [nsuborders, osuborders, dsuborders]
const depots = [data, something]

export default function DashboardCard() {


  const [isExecutive, setIsExecutive] = useState(true);
  return (
    <Grid container spacing={2}>
      {/* <Grid lg={3} sm={6} xs={12}>
        <Budget diff={12} trend="up" sx={{ height: '100%' }} value="$24k" />
      </Grid> */}

      {isExecutive && (
        <>
          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key={excoData.type}>
            <TallyCard
              isExecutive
              data={excoData}
            />
          </Grid>
          {exData.map(d => (
            <Grid size={{ lg: 3, sm: 6, xs: 12 }} key={d.type}>
              <TallyCard
                isExecutive
                data={d}
              />
            </Grid>
          ))}

          <Grid size={{ xs: 8 }} key="stakked-product-segment">
            <AreaChart />
          </Grid>

          <Grid size={{ xs: 4 }} key="product segment">
            <ProductSegmentCard
              products={productSeg}
              sx={{ height: '100%' }}
            />
          </Grid>
        </>
      )}


      {opData.map(d => (
        <Grid size={{lg: 3, sm:6, xs: 12}} key={d.type}>
          <ProductCard
            data={d}
          />
        </Grid>
      ))}

      {progData.map(d => (
        <Grid size={{lg: 2.4, sm:6, xs: 12}} key={d.type}>
          <ProgressCard
            sx={{ height: '100%' }}
            data={d}
          />
        </Grid>
      ))}

      <Grid size={{ lg: 2.4, sm: 6, xs: 12 }} key={"Bulk-Breakdown"}>
        <LabeledProgressCard
          isOpenTooltip
          data={transformBulkOrderBreakdownData(cards)}
          sx={{ height: '100%' }}
        />
      </Grid>

      {suborders.map(d => (
        <Grid size={{lg: 2.4, sm:6, xs: 12}} key={d.type}>
          <TallyCard
            data={d}
            sx={{ height: '100%' }}
          />
        </Grid>
      ))}

      {depots.map(d => (
        <Grid size={{lg: 2.4, sm:6, xs: 12}} key={d.type}>
          <ProductCard
            isTally
            data={d}
            sx={{ height: '100%' }}
          />
        </Grid>
      ))}


        
    </Grid>
  );
}
