import { Category } from '@mui/icons-material';
import { FUEL_PRODUCT_COLORS, HEX_OPACITY, WHITE_SMOKE_COLOR } from '../../utils/constants';
import { isNotNullValue } from '../../utils/formUtil';

/*
const METADATA_MAP = {
  '50 PPM': {
      type: 'ppm50',
      label: '50 PPM',
      color: '#CDDC39',
  },
  'ULP 93': {
      type: 'ulp93',
      label: 'ULP 93',
      color: '#4CAF50',
  },
  'ULP 95': {
      type: 'ulp95',
      label: 'ULP 95',
      color: '#3D5AFE',
  },

};
*/

//                         Green,      red,       blue,      yellowish   black     cynn
export const colorCodes = ['#27ae60', '#d32f2f', '#1976d2', '#ff9800', '#000000', '#2edaff'];
export const cardColors = {
  'Buy Orders': colorCodes[0],
  'Sell Orders': colorCodes[1],
  'Paid Invoices': colorCodes[0],
  'Unpaid Invoices': colorCodes[0],
  'Total Invoices': colorCodes[2],
  'Total on Credit': colorCodes[3],
  'In-Bond Suborders': colorCodes[2],
  'Direct Suborders': colorCodes[0],
  'Sent Nominations': colorCodes[4],
  'Outstanding Nominations': colorCodes[2],
  'Requested Releases': colorCodes[3],
  'Sent Commercial Invoices': colorCodes[3],
  'Outstanding Commercial Invoices': colorCodes[5],
  'Sent Transit Clearance': colorCodes[0],
  'Outstanding Transit Clearance': colorCodes[1],
  'Sent SARS Customs': colorCodes[0],
  'Outstanding SARS Customs': colorCodes[1],
}

export const formatPercentageValue = (value) => {

  if (isNotNullValue(value)) {
    return value + '%';
  }

  return '';
}

export const findCardType = (cards, type) => {
  if (cards?.length) {
    return cards?.find(c => type.toLowerCase() === c?.type.toLowerCase());
  }

  return null;
}

export const findCardMetaType = (metadata, type) => {

  if (metadata?.length) {
    return metadata?.find(m => type.toLowerCase() === m?.type.toLowerCase());
  }

  return null;
}

export const findCardTypeValue = (cards, type) => {
  const details = findCardType (cards, type);
  if (details){
    return { type: details?.type, value: details?.value}
  }

  return null;
}

export const getProgressBreakDown = (type, value, left,  right, messageTip ) => {
  return {
    type: type,
    value: value || 0,
    tip: messageTip || "",
    leftBar: {
      label: left?.label || "",
      backgroundColor: left?.background || colorCodes[4],
      textColor: "#FFF",
      legend: left?.legend || 'Bulk Orders',
    },
    rightBar: {
      label: right?.label || "",
      backgroundColor: right?.background || colorCodes[2],
      textColor: "#FFF",
      legend:  right?.legend || 'Bulk Orders',
    }
  }
}

export const transformBulkOrderBreakdownData = (data) => {
  const details = findCardType (data, 'Bulk and Bulk Breaks');
  const bulkMeta = findCardMetaType (details?.metadata, 'bulk orders');
  const breakMeta = findCardMetaType (details?.metadata, 'bulk break orders')

  const bulkMetaL2 = findCardMetaType (bulkMeta?.metadata, 'count');
  const breakMetaL2 = findCardMetaType (breakMeta?.metadata, 'count')

  const bulkMetaL3 = findCardMetaType (bulkMetaL2?.metadata, 'percentage');
  const breakMetaL3 = findCardMetaType (breakMetaL2?.metadata, 'percentage')
  const messageTip = `Total of ${bulkMetaL2?.value || 0} bulk, ${breakMetaL2?.value || 0} break orders`;
  const left = {
    label: formatPercentageValue(bulkMetaL3?.value),
    background: "#000000",
    legend: 'Bulk Orders'
  }

  const right = {
    label: formatPercentageValue(breakMetaL3?.value),
    background: "#1565c0",
    legend: 'Bulk Break Orders'
  }

  return getProgressBreakDown('Bulk and Bulk Breaks', bulkMetaL3?.value || 0, left,  right, messageTip );
  /*
  return {
    type: 'Bulk and Bulk Breaks',
    value: bulkMetaL3?.value || 0,
    tip: `Total of ${bulkMetaL2?.value || 0} bulk, ${breakMetaL2?.value || 0} break orders`,
    leftBar: {
      label: formatPercentageValue(bulkMetaL3),
      backgroundColor: "#000000",
      textColor: "#FFF",
      legend: 'Bulk Orders'
    },
    rightBar: {
      label: formatPercentageValue(breakMetaL3),
      backgroundColor: "#1565c0",
      textColor: "#FFF",
      legend: 'Bulk Break Orders'
    }
  }
  */
}

export const transformProgressData = (data, type) => {
  const details = findCardType (data, type);
  const trend = findCardMetaType (details?.metadata, 'Trend');
  const indicator = findCardMetaType (details?.metadata, 'Indicator');
  const progress = findCardMetaType (details?.metadata, 'Progress');
  const diff = findCardMetaType (details?.metadata, 'Diff');

  return {
    type: type,
    value: details?.value,
    metadata: {
      trend: trend?.value,
      progress: progress?.value || 0,
      indicator: indicator?.value,
      diff: diff?.value,
    }
  }
}

export const transformTallyData = (data, type, color) => {
  const details = findCardType (data, type);
  const ppm50 = findCardMetaType (details?.metadata, '50 PPM');
  const ulp93 = findCardMetaType (details?.metadata, 'ULP 93');
  const ulp95 = findCardMetaType (details?.metadata, 'ULP 95');

  const typeColor = cardColors[type] || "#0000ff";

  return {
    type: type,
    value: details?.value,
    color: color || typeColor,
    metadata: {
      ppm50: ppm50?.value,
      ulp95: ulp95?.value,
      ulp93: ulp93?.value,
    }
  }
}

export const transformProductSegmentData = (details) => {
  // const details = data['Product Segmentation'];

  const ppm50 = findCardMetaType (details?.metadata, '50 PPM');
  const ulp93 = findCardMetaType (details?.metadata, 'ULP 93');
  const ulp95 = findCardMetaType (details?.metadata, 'ULP 95');

  const ppm50Percentage = findCardMetaType(ppm50?.metadata, 'percentage')?.value || 0;
  const ulp93Percentage = findCardMetaType(ulp93?.metadata, 'percentage')?.value || 0;
  const ulp95Percentage = findCardMetaType(ulp95?.metadata, 'percentage')?.value || 0;

  return {
    ppm50: {
      value: ppm50Percentage,
      leftBar: {
        backgroundColor: FUEL_PRODUCT_COLORS.ppm50,
      },
      rightBar: {
        label: formatPercentageValue(ppm50Percentage),
        backgroundColor: WHITE_SMOKE_COLOR,
      }
    },
    ulp93: {
      value: ulp93Percentage,
      leftBar: {
        backgroundColor: FUEL_PRODUCT_COLORS.upl93,
      },
      rightBar: {
        label: formatPercentageValue(ulp93Percentage),
        backgroundColor: WHITE_SMOKE_COLOR,
      }
    },
    ulp95: {
      value: ulp95Percentage,
      leftBar: {
        backgroundColor: FUEL_PRODUCT_COLORS.upl95,
      },
      rightBar: {
        label: formatPercentageValue(ulp95Percentage),
        backgroundColor: WHITE_SMOKE_COLOR,
      }
    },
  }
}

export const removeCardType = (cardsData, type) => {
  if (cardsData?.length) {
    return cardsData?.filter(c => type.toLowerCase() !== c?.type.toLowerCase());
  }

  return [];

}

export const transformExcusionTally = (cardsData, type) => {
  return removeCardType(cardsData, type)
    ?.map(card => transformTallyData(cardsData, card?.type));
}

export const transformCustomsData = (data, type, leftLegend, rightLegend, isPercentage = true) => {
  const details = findCardType(data, type);
  const leftMeta = findCardMetaType(details?.metadata, leftLegend);
  const rightMeta = findCardMetaType(details?.metadata, rightLegend);

  const leftMetaL2 = findCardMetaType(leftMeta?.metadata, 'count');
  const rightMetaL2 = findCardMetaType(rightMeta?.metadata, 'count')

  const leftMetaL3 = findCardMetaType(leftMetaL2?.metadata, 'percentage');
  const rightMetaL3 = findCardMetaType(rightMetaL2?.metadata, 'percentage')

  const left = {
    label: formatPercentageValue(isPercentage ? leftMetaL3 : leftMetaL2),
    legend: leftLegend
  }

  const right = {
    label: formatPercentageValue(isPercentage ? rightMetaL3 : rightMetaL2),
    background: colorCodes[2],
    legend: rightLegend
  }

  return getProgressBreakDown(type, leftMetaL3?.value || 0, left, right, "");
}

export const transformBiProgressData = (data, type, leftLegend, rightLegend) => {
  
  const details = findCardType(data, type);

  const leftMeta = findCardMetaType(details?.metadata, leftLegend);
  const rightMeta = findCardMetaType(details?.metadata, rightLegend);

  const leftMetaValue =leftMeta?.value;
  const rightMetaValue = rightMeta?.value;

  const left = {
    label: leftMeta?.value,
    legend: leftLegend,
    background: cardColors[`${leftLegend} ${type}`],
  }

  const right = {
    label: rightMetaValue,
    legend: rightLegend,
    background: cardColors[`${rightLegend} ${type}`],
  }

  const progress = leftMetaValue / (leftMetaValue + rightMetaValue) * 100;

  return getProgressBreakDown(type, progress, left, right, "");
}

export const transformProductGroupedStack = (data = []) => {

  const graphSeries = [
    {
      kpi: 'moved_volume',
      name: '50 PPM Moved Volume',
      group: '50 PPM',
      color: `${FUEL_PRODUCT_COLORS.ppm50}`,
      data: []
    },
    {
      kpi: 'remaining_volume',
      name: '50 PPM Remaining Volume',
      group: '50 PPM',
      color: `${FUEL_PRODUCT_COLORS.ppm50}${HEX_OPACITY[50]}`,
      data: []
    },
    {
      kpi: 'moved_volume',
      name: 'ULP 93 Moved Volume',
      group: 'ULP 93',
      color: `${FUEL_PRODUCT_COLORS.upl93}`,
      data: []
    },
    {
      kpi: 'remaining_volume',
      name: 'ULP 93 Remaining Volume',
      group: 'ULP 93',
      color: `${FUEL_PRODUCT_COLORS.upl93}${HEX_OPACITY[50]}`,
      data: [],
      // hidden: false
    },
    {
      kpi: 'moved_volume',
      name: 'ULP 95 Moved Volume',
      group: 'ULP 95',
      color: `${FUEL_PRODUCT_COLORS.upl95}`,
      data: [],
      // hidden: false
    },
    {
      kpi: 'remaining_volume',
      name: 'ULP 95 Remaining Volume',
      group: 'ULP 95',
      color: `${FUEL_PRODUCT_COLORS.upl95}${HEX_OPACITY[50]}`,
      data: [],
      // hidden: false
    },
  ];
  // console.log(data);
  const suppliers = Object.keys(data || {})?.sort((a, b) => a.localeCompare(b));

  graphSeries.forEach((series) => {
    // console.log("Processing ", series.name);
    suppliers.forEach((s) => {
      const product = data[s][series.group];
      const value = product? product[series.kpi] : 0;
      // console.log(s, series.group, series.kpi, value);
      series.data.push(value);
    });

    // series.hidden = series.data.every((item) => item ===0);

  })

  // console.log(graphSeries)

  return {
    series: graphSeries,
    categories: suppliers
  };

}
