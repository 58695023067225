/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState, } from 'react';

import { FilePdfFill } from 'react-bootstrap-icons';
import { Alert, Box, IconButton, Snackbar } from '@mui/material';
import Grid from '@mui/material/Grid2'
import { useDropzone } from 'react-dropzone';
import {
  AttachFile,
  Delete,
  Description,
  CloudUpload,
} from "@mui/icons-material"

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#6495ED',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  backgroundColor: '#00e6761a',
  borderColor: '#00e676'
};

// const rejectStyle = {
//   borderColor: '#ff1744'
// };

const listStyle = {
  justifyContent: 'flex-start',
  backgroundColor: '#8FBC8F40',
  margin: '5px auto',
  borderRadius: '5px',
  padding: '2px 10px',
}

const defaultFileTypes = {
  'image/png': ['.png',],
  'image/jpeg': ['.jpg', '.jpeg'],
}

/**
 * Component to allow users to drag and drop files
 */
const FileDragzone = ({
  acceptedTypes = defaultFileTypes,
  filesLimit = 2, // file count
  maxFileSizeMB = 5, // MB, thus 1e6 x Bytes per file
  fileObjects, // list of files uploaded
  handleFileObjects, // a function that handles accepted files during drop
}) => {

  const fileExtensions = Object.values(acceptedTypes || defaultFileTypes).flat(Infinity);
  const fileMemeTypes = Object.keys(acceptedTypes || defaultFileTypes).flat(Infinity);
  const toReplace = 'To replace, remove one from the uploaded list, first.'
  const exceededLimit = `Limit exceeded, only ${filesLimit} ${filesLimit > 1 ? 'files are' : 'file is'} allowed. ${toReplace}`;

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState('error');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [totalFiles, setTotalFiles] = useState(0);
  const [dropzoneHint, setDropzoneHint] = useState("")

  useEffect(() => {
    // console.log('number of files: ' + fileObjects.length)
    setTotalFiles(fileObjects.length);

    const remaining = filesLimit - fileObjects.length;
    const fileOrFiles = Math.min(filesLimit, remaining) > 1 ? `${filesLimit} files` : "a file";
    const hint = `Drag 'n' drop ${fileOrFiles} here, or click to select ${fileOrFiles}`;
    setDropzoneHint(hint);
  }, [fileObjects]);

  const getDropRejectMessage = (rejectedFile) => {
    const { file } = rejectedFile;
    let message = `File ${file.name} was rejected. `;

    if (!fileMemeTypes.includes(file.type)) {
      message += 'File type not supported. ';
    }
    if (file.size > maxFileSizeMB*1e6) {
      message += `File is too big. Size limit is ${maxFileSizeMB} MB. `;
    }
    return message;
  }

  const notifyAlert = (message, variant = 'error') => {
    setOpenSnackBar(true);
    setSnackbarMessage(message)
    setSnackbarVariant(variant);
  }

  const handlePreviewIcon = (fileObject) => {
    const { type } = fileObject
    const iconProps = {
    }

    switch (type) {
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <Description {...iconProps} />
      case "application/pdf":
        return <FilePdfFill color='purple' />
      default:
        return <AttachFile {...iconProps} />
    }
  }

  const formatFileError = (fileError) => {

    if (fileError) {
      // console.error(fileError.errors)
      const errorMsg = getDropRejectMessage(fileError)
      // (fileError.errors?.map(e => e.message) || []).join("\n ");
      return "Encountered error(s) when uploading: " + errorMsg;
    }

    return "Experience some error when uploading the file";
  }

  const handleOnDrop = (acceptedFiles, fileRejections) => {
    // console.log(acceptedFiles, fileRejections)
    // console.log(">>" + fileObjects + "Existing files: " + fileObjects.length + " new files: " + acceptedFiles.length + " max files: " + filesLimit);

    if (fileRejections.length > 0) {
      const countRejections = fileRejections.length;
      if (fileRejections.filter(f => f.errors?.map(e => e.message).includes(exceededLimit)).length > 0) {
        const verb = countRejections > 1 ? ' were' : ' was';
        const errors = fileRejections.map(f => f.file.name).join(", ") + verb + " rejected. " + exceededLimit;
        notifyAlert(errors, 'error')
        return
      }

      const errors = fileRejections.map(f => formatFileError(f))
      notifyAlert(errors, 'error')
      return
    }

    if (filesLimit === 1 || acceptedFiles.length === filesLimit) {
      handleFileObjects(acceptedFiles);
    }
    else if ((acceptedFiles.length + fileObjects.length) <= filesLimit) {
      const _files = [...fileObjects, ...acceptedFiles];
      handleFileObjects(_files);
    }
  };

  const handleOnDropRejected = (rejectedFiles) => {
    const _files = [...fileObjects];
    handleFileObjects(_files);
  }

  const handleRemovedFile = (file) => {
    if (fileObjects.length === 0) {
      return;
    }
    const index = fileObjects.indexOf(file);
    // console.log(file, " on index " + index + " i> 0: " + (index > 0) + " L==1 " + (fileObjects.length === 1));
    fileObjects.splice(index, 1)
    const _files = (index > 0 && fileObjects.length === 1) ? [...fileObjects] : [];
    // console.log("new file list has length = ", _files.length);
    handleFileObjects(_files);
  };

  const hanldeFileValidation = (file) => {

    if (fileObjects.length === filesLimit) {
      return {
        code: "too-many-files",
        message: exceededLimit,
      };
    }
    return null;
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
  } = useDropzone({
    maxFiles: filesLimit,
    accept: acceptedTypes || defaultFileTypes,
    maxSize: maxFileSizeMB * 1e6,
    onDrop: handleOnDrop,
    onDropRejected: handleOnDropRejected,
    validator: hanldeFileValidation,
    // disabled: filesLimit === fileObjects?.length,
  });

  const containerStyle = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
  }), [
    isDragActive,
    isDragAccept,
  ]);

  return (
    <>
      <div {...getRootProps({ style: containerStyle })}>
        <CloudUpload color='primary' fontSize='large' sx={{}} />
        <input {...getInputProps()} />
        {totalFiles< filesLimit ?(
          <h4>{dropzoneHint}</h4>
        ):(
            <h4>You have uploaded {filesLimit} {filesLimit > 1 ? 'files' : 'file'}. {toReplace}</h4>
        )}
        <em>Supported files: {fileExtensions.join(", ")}</em>
        <b>Maximum size per file: {maxFileSizeMB} MB</b>
      </div>
      {fileObjects?.length > 0 && (
        <Box >
          {fileObjects.map(file => (
            <Grid container spacing={3} sx={{ ...listStyle }}>
              <Grid size={{ xs: 11 }} key={file.name} >
                <span style={{ justifyContent: 'flex-start', }}>
                  {handlePreviewIcon(file)} {file.name}
                </span>
                <br />
                <b> {file.size > 1e6 ? (file.size / 1e6)?.toFixed(1) + ' MB' : (file.size / 1e3)?.toFixed(1) + ' kB'} </b>
              </Grid>
              <Grid size={{ xs: 1 }} sx={{ display: 'flex', justifyContent: 'right' }}>
                <IconButton onClick={() => handleRemovedFile(file)}>
                  <Delete color='error' />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6500}
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert onClose={() => setOpenSnackBar(false)}
          severity={snackbarVariant}
          sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>

      </Snackbar>
    </>
  )
}

FileDragzone.defaultProps = {
  acceptedTypes: defaultFileTypes,
  fileObjects: [],
  maxFileSize: 2,
  maxFileSizeMB: 2,
}

FileDragzone.propTypes = {
  /** A list of file types to accept.
   * @see See [here](https://react-dropzone.js.org/#section-accepting-specific-file-types) for more details.
  */
  acceptedTypes: PropTypes.arrayOf(PropTypes.string),

  /** Maximum number of files that can be loaded into the dropzone.
   *  Default value is 1
   */
  filesLimit: PropTypes.number,

  handleFileObjects: PropTypes.func.isRequired,
  /** Maximum file size (in mega bytes) that the dropzone will accept.
   * Default value is 2
   */
  maxFileSizeMB: PropTypes.number,

}

export default FileDragzone;
