import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid2'

import { ProgressCard } from '../cards/ProgressCard';
import { LabeledProgressCard } from '../cards/LabeledProgressCard';
import { ProductSegmentCard } from '../cards/ProductSegmentCard';
import { AreaChart } from '../charts/AreaChart';
import {
  transformTallyData,
  transformBulkOrderBreakdownData,
  transformProductSegmentData,
  removeCardType,
} from '../dash-util';
import { TallyCard } from '../cards/TallyCard';

const cardColors = ['#4caf50', '#ed6c02', '#1976d2']

export function ExecutiveView({ cardsData }) {

  const orderedCards = removeCardType(cardsData, 'Trucks Enroute')
  ?.sort((a, b) => a.value - b.value);

  return (
    <Grid container spacing={3}>
    <Grid size={{ lg: 3, sm: 6, xs: 12 }} key='Trucks Enroute'>
      <TallyCard
        isExecutive
        sx={{ height: '100%' }}
        data={transformTallyData(cardsData, 'Trucks Enroute', '#7b1fa2')}
      />
    </Grid>

      {orderedCards?.map((d, index) => (
          <Grid size={{ lg: 3, sm: 6, xs: 12 }} key={d.type}>
            <TallyCard
              isExecutive
              data={transformTallyData(cardsData, d?.type, cardColors[index])}
            />
          </Grid>
      ))}

    <Grid size={{ xs: 8 }} key="stakked-product-segment">
      <AreaChart />
    </Grid>

    <Grid size={{ xs: 4 }} key="product segment">
      <ProductSegmentCard
        products={transformProductSegmentData(cardsData)}
        sx={{ height: '100%' }}
      />
    </Grid>
  </Grid>
  )
}

ExecutiveView.propTypes = {
  cardData: PropTypes.arrayOf(PropTypes.object).isRequired,
}
